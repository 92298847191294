import RegisterColumnView from '@pages/common/Register/RegisterColumnView';
import ResetPasswordForm from '@pages/common/Login/ResetPasswordForm';

const ResetPasswordPage = () => (
  <RegisterColumnView>
    <ResetPasswordForm />
  </RegisterColumnView>
);

export default ResetPasswordPage;
