import {
  CircularProgress,
  Typography,
  Box,
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  Controller,
  type Control,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import { useGetPipeline } from '@/api/pipeline/pipeline';
import { Pipeline, PipelineList, PipelineProps } from '@/api/pipeline';
import { type BatchSchema } from '@/zod_schemas';

interface IPipelineComponentProps {
  register: UseFormRegister<BatchSchema>;
  errors: FieldErrors<BatchSchema>;
  control: Control<BatchSchema>;
  index: number;
}
type ComponentProps = PipelineProps & IPipelineComponentProps;

const PipelineComponent = ({
  pipelineId,
  errors,
  control,
  index,
}: ComponentProps) => {
  const { data, isLoading, isError, error } = useGetPipeline(pipelineId);
  const pipelines = data as PipelineList;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Box>
        <Typography variant="h6" color="error">
          Error fetching data: {error?.message}
        </Typography>
      </Box>
    );
  }

  return (
    <FormControl fullWidth size="small" variant="standard">
      <Controller
        name={`experiments.${index}.pipeline_configs`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            options={pipelines}
            getOptionLabel={(option: Pipeline) => option.name}
            value={
              Array.isArray(value)
                ? pipelines.filter((pipeline) => value.includes(pipeline.id))
                : []
            }
            onChange={(_event, newValue) => {
              onChange(newValue ? newValue.map((pipeline) => pipeline.id) : []);
            }}
            isOptionEqualToValue={(option, valueOption) =>
              option.name === valueOption.name
            }
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Pipeline"
                error={!!errors.experiments?.[index]?.pipeline_configs}
                helperText={
                  errors.experiments?.[index]?.pipeline_configs
                    ? errors.experiments?.[index]?.pipeline_configs?.message
                    : null
                }
              />
            )}
          />
        )}
      />
    </FormControl>
  );
};

export default PipelineComponent;
