import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useGetProject } from '@api/project/project';
import { batchSchema, type BatchSchema } from '@/zod_schemas';

type ProjectBatchPathParams = {
  projectId: string;
};

interface UseProjectBatchFormHandlerReturn
  extends ReturnType<typeof useForm<BatchSchema>> {
  mode: 'create' | 'update';
  projectId?: string;
}

type UseProjectBatchFormHandler = () => UseProjectBatchFormHandlerReturn;

export const useProjectBatchFormHandler: UseProjectBatchFormHandler = () => {
  const { projectId } = useParams<ProjectBatchPathParams>();
  const { data } = useGetProject(projectId);

  const formMethods = useForm<BatchSchema>({
    resolver: zodResolver(batchSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: projectId ? 'update' : 'create',
    projectId,
  };
};

export const useCreateOrUpdateProjectBatch = (
  mode: 'create' | 'update',
  projectId?: string,
) => {
  const onSubmit: SubmitHandler<BatchSchema> = () => {
    const test = mode;
    const test2 = projectId;
    return { test, test2 };
  };
  // const navigate = useNavigate();
  // const createProject = useCreateProject();
  // const updateProject = useUpdateProject();

  // const onSubmit: SubmitHandler<BatchSchema> = useCallback(
  //   (data) =>
  //     new Promise<void>((resolve, reject) => {
  //       if (mode === 'create') {
  //         createProject
  //           .mutateAsync(data)
  //           .then(({ data: { id } }) => {
  //             resolve();

  //             enqueueSnackbar('Project created successfully', {
  //               variant: 'success',
  //             });

  //             navigate(`/projects/${id}`);
  //           })
  //           .catch((ex) => {
  //             reject(ex);

  //             enqueueSnackbar('Failed to create project', {
  //               variant: 'error',
  //             });
  //           });
  //       }

  //       if (!projectId) {
  //         reject(new Error('Project ID is required'));

  //         enqueueSnackbar('Unknown error occurred', {
  //           variant: 'error',
  //         });

  //         return;
  //       }

  //       updateProject
  //         .mutateAsync({ payload: data, id: projectId })
  //         .then(() => {
  //           resolve();

  //           enqueueSnackbar('Project updated successfully', {
  //             variant: 'success',
  //           });

  //           navigate(`/projects/${data.id}`);
  //         })
  //         .catch((ex) => {
  //           reject(ex);

  //           enqueueSnackbar('Failed to update project', {
  //             variant: 'error',
  //           });
  //         });
  //     }),
  //   [createProject, mode, navigate, projectId, updateProject],
  // );

  return onSubmit;
};
