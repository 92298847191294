import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@mui/material';
import { ReactNode } from 'react';

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  title: ReactNode;
  children: ReactNode;
}

const CustomDialog = ({
  open,
  onClose,
  onSubmit,
  title,
  children,
}: CustomDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={{
      '& .MuiDialog-paper': {
        minWidth: 300, // Minimum width
        minHeight: 200, // Minimum height
        maxWidth: '90vw', // Max width of the dialog (optional)
        maxHeight: '90vh', // Max height of the dialog (optional)
        width: 'auto', // Automatically adjust width based on content
        height: 'auto', // Automatically adjust height based on content
      },
    }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Stack direction="column" spacing={2}>
        {children}
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>Confirm</Button>
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
