import { useCallback, useState } from 'react';

import { useFileBrowserLocation } from '@components/FileBrowser/hooks';
import { useDownloadUserFile } from '@api/fileHandling/fileHandling';

type UseHandleRowClickArgs = {
  onSelectFiles?: (files: string[]) => void;
  selectMultiple?: boolean;
};

const getNewFileList = (
  selectMultiple: boolean,
  currentFiles: Set<string>,
  newFile: string,
) => {
  if (!selectMultiple) {
    if (currentFiles.has(newFile)) {
      return [];
    }

    return [newFile];
  }

  if (currentFiles.has(newFile)) {
    currentFiles.delete(newFile);
    return [...currentFiles];
  }

  return [...currentFiles, newFile];
};

export const useHandleRowClick = ({
  onSelectFiles,
  selectMultiple,
}: UseHandleRowClickArgs) => {
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const { setLocation, location } = useFileBrowserLocation();

  const handleClickRow = useCallback(
    (isDirectory: boolean, userFileUrl: string) => {
      if (isDirectory) {
        setLocation((loc) => `${loc}${userFileUrl}/`);
        return;
      }

      const filePath = `${location}${userFileUrl}`;
      const newFiles = getNewFileList(
        selectMultiple || false,
        selectedFiles,
        filePath,
      );

      setSelectedFiles(new Set(newFiles));

      if (onSelectFiles) {
        onSelectFiles(newFiles);
      }
    },
    [location, selectMultiple, selectedFiles, onSelectFiles, setLocation],
  );

  return { handleClickRow, selectedFiles };
};

export const useHandleDownloadUserFile = () => {
  const getDownloadUrl = useDownloadUserFile();

  return useCallback(
    (id: string | null) => {
      if (!id) {
        return;
      }

      getDownloadUrl.mutateAsync({ pk: id }).then(({ data }) => {
        window.open(data.url, '_blank');
      });
    },
    [getDownloadUrl],
  );
};
