import { Avatar, Paper, styled } from '@mui/material';

export const ProjectListHeaderContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'lightgray',
}));

export const ProjectCreatedByAvatar = styled(Avatar)(() => ({
  width: '1.5rem',
  height: '1.5rem',
  fontSize: '0.5rem',
}));

export const UserAvatar = styled(Avatar)(() => ({
  width: '1.5rem',
  height: '1.5rem',
  fontSize: '0.5rem',
}));
