import { Stack } from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import Section from '@/pages/common/Patient/Forms/components/Section';
import { PipelineSchema } from '@/zod_schemas';
import PipelineDetailsForm from './PipelineDetailsForm';
import PipelineConfigForm from './PipelineConfigForm';

interface PipelineFormProps {
  register: UseFormRegister<PipelineSchema>;
  control: Control<PipelineSchema>;
  errors: FieldErrors<PipelineSchema>;
  mode: string;
  mainPipelineID: string | undefined;
}

const PipelineForm = ({
  register,
  control,
  mode,
  errors,
  mainPipelineID,
}: PipelineFormProps) => (
  <Section title="Pipeline Information">
    <Stack direction="column" spacing={3}>
      <PipelineDetailsForm
        register={register}
        control={control}
        errors={errors}
        mode={mode}
      />
      <PipelineConfigForm
        mainPipelineID={mainPipelineID}
        control={control}
        register={register}
        errors={errors}
      />
    </Stack>
  </Section>
);

export default PipelineForm;
