// UserList.tsx
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomNoRowsOverlay from '@/components/CustomNoRowsOverlay';
import { UserListsSchema } from '@/zod_schemas';
import useGetUserColumns from '../Permissions/Grid/useGetUserColumns';

const customMessage = 'No users available'; // Example custom message

// Define a functional component to use as the overlay
const NoRowsOverlay = () => <CustomNoRowsOverlay message={customMessage} />;

interface UserListProps {
  page?: string;
  data: UserListsSchema[];
  assignUserGroup?: (id: string) => void;
  removeUserGroup?: (id: string) => void;
  setAssignSelectedRowIds?: React.Dispatch<
    React.SetStateAction<(string | number)[]>
  >;
}
const UserList = ({
  page,
  data,
  assignUserGroup,
  removeUserGroup,
  setAssignSelectedRowIds,
}: UserListProps) => {
  const columns = useGetUserColumns({ page, assignUserGroup, removeUserGroup });

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        onRowSelectionModelChange={(ids) => {
          if (setAssignSelectedRowIds) {
            setAssignSelectedRowIds(ids);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        sx={{
          '& .MuiDataGrid-main': {
            minHeight: '300px', // Ensures minimum height when no rows
          },
        }}
      />
    </Box>
  );
};

export default UserList;
