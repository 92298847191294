import { Stack } from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type FieldArrayWithId,
} from 'react-hook-form';

import { type GroupExperimentSchema } from '@/zod_schemas';
import SampleForm2 from '../SampleForm2';

interface ISamplesContainer2Props {
  control: Control<GroupExperimentSchema>;
  experimentIndex: number;
  register: UseFormRegister<GroupExperimentSchema>;
  errors: FieldErrors<GroupExperimentSchema>;
  fields: FieldArrayWithId<GroupExperimentSchema, 'samples'>[];
  removeSample: (index: number) => void;
  isSubmitting: boolean;
}

const SamplesContainer2 = ({
  control,
  errors,
  experimentIndex,
  register,
  fields,
  removeSample,
  isSubmitting,
}: ISamplesContainer2Props) =>
  fields.length > 0 && (
    <Stack spacing={1}>
      {fields.map(({ id }, index) => (
        <SampleForm2
          register={register}
          remove={() => removeSample(index)}
          index={index}
          experimentIndex={experimentIndex}
          key={id}
          errors={errors}
          control={control}
          isSubmitting={isSubmitting}
        />
      ))}
    </Stack>
  );

export default SamplesContainer2;
