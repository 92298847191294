import { AxiosResponse } from 'axios';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { get, post, del } from '@api/httpClient';
import { APIError } from '@api/types';
import { HPOSchema } from '@/zod_schemas';

type Category = 'term' | 'gene' | 'disease';

interface HPOResponse {
  terms: HPOSchema[];
  totalCount: number;
}

interface ByHPOPatient {
  hpo_data: HPOSchema;
}

// Define the base URLs for each category
const BASE_URLS = {
  term: 'https://ontology.jax.org/api/hp/search',
  gene: 'https://ontology.jax.org/api/network/search/gene',
  disease: 'https://ontology.jax.org/api/network/search/disease',
};

const QUERY_KEYS = {
  HPOs: ['hpo'],
  HPO: (paramSearch: string, category: Category, limit: number) => [
    'q',
    { id: paramSearch, category, limit },
  ],
};

// Function to fetch data from a given URL
const fetchData = async (url: string, paramSearch: string, limit: number) => {
  const response = await get(`${url}?q=${paramSearch}&limit=${limit}`);
  return response.data;
};

// Update ID from API to hpo_id. Our backend using hpo_id instead of id
const transformIDs = (data: HPOResponse) => {
  const updatedTerms = data.terms.map((item) => ({
    ...item,
    hpo_id: item.id,
  }));

  return {
    ...data,
    terms: updatedTerms,
  };
};

// Custom hook to fetch HPO data based on the search query and category
export const useGetHpo = (
  paramSearch?: string,
  category: Category = 'term',
  limit = 1000,
) =>
  useSuspenseQuery({
    queryKey: QUERY_KEYS.HPO(paramSearch || '', category, limit),
    queryFn: async () => {
      if (!paramSearch) return {};

      // Fetch data from all three URLs in parallel
      const [termData, geneData, diseaseData] = await Promise.all([
        fetchData(BASE_URLS.term, paramSearch, limit),
        fetchData(BASE_URLS.gene, paramSearch, limit),
        fetchData(BASE_URLS.disease, paramSearch, limit),
      ]);

      const termDataUpdated = termData ? transformIDs(termData) : null;

      return {
        termData: termDataUpdated,
        geneData,
        diseaseData,
      };
    },
  });

export const useListHPO = () =>
  useSuspenseQuery<HPOSchema[]>({
    queryKey: QUERY_KEYS.HPOs,
    queryFn: async () => {
      const resp = await get<HPOSchema[]>('/hpo/');
      return resp.data;
    },
  });

export const useCreateHPO = () =>
  useMutation<AxiosResponse<HPOSchema[]>, APIError, HPOSchema[]>({
    mutationFn: (hpos) => post<HPOSchema[]>('/hpo/', hpos),
  });

const HPO_QUERY_KEYS = {
  PATIENTS_HPO: ['hpo'],
  PATIENT_HPO: (patientId: string) => ['hpo', { id: patientId }],
};

export const useGetPatientHPO = (patientId?: string) =>
  useSuspenseQuery<HPOSchema>({
    queryKey: HPO_QUERY_KEYS.PATIENT_HPO(patientId || ''),
    queryFn: async () => {
      if (!patientId) return {};
      const resp = await get(`/hpo_patient/${patientId}/by_patient/`);

      // Process HPO-> This because of API got updated.(Not following previous JSON stucture)
      // This to ensure current API support current code structure.
      return resp.data.map((item: ByHPOPatient) => ({
        ...item,
        hpo_id: item.hpo_data.hpo_id,
        name: item.hpo_data.name,
        definition: item.hpo_data.definition,
        comment: item.hpo_data.comment,
        descendant_count: item.hpo_data.descendant_count,
        synonyms: item.hpo_data.synonyms,
        xrefs: item.hpo_data.xrefs,
        translations: item.hpo_data.translations,
        created_at: item.hpo_data.created_at,
        updated_at: item.hpo_data.updated_at,
        created_by: item.hpo_data.created_by,
      }));

      // return resp.data;
    },
  });

// export const useUnAssignHPOPatient = (hpoId?: string) => {
//   if (!hpoId) {
//     throw new Error('hpoId is required to delete an HPO');
//   }

//   return useSuspenseQuery<HPOSchema>({
//     queryKey: UNASSIGNED_HPO_QUERY_KEYS.PATIENT_HPO(hpoId),
//     queryFn: async () => {
//       try {
//         const resp = await del<HPOSchema>(`/hpo_patient/${hpoId}/`);
//         return resp.data;
//       } catch (error) {
//         throw new Error('Failed to delete HPO patient record');
//       }
//     },
//   });
// };

export const useUnAssignHPOPatient = () =>
  useMutation<AxiosResponse<string>, APIError, string>({
    mutationFn: (normalizationID: string) =>
      del<string>(`/hpo_patient/${normalizationID}/`),
  });
