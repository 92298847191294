import {
  useCreateOrUpdatePipeline,
  usePipelineFormHandler,
} from '@pages/common/Administrator/Pipeline/hooks';
import {
  PipelineForm,
  PipelineHeader,
} from '@pages/common/Administrator/Pipeline/components';
import { Suspense } from 'react';
import { usePipelineFormContext } from '@pages/common/Administrator/Pipeline/usePipelineFormContext';
import { HeaderElement } from '@/pages/common/Patient/StyledComponents';

const ViewPipeline = () => {
  const { dataPipeline } = usePipelineFormContext();
  const mainPipelineID = dataPipeline ? dataPipeline.mainPipelineID : '';

  const {
    handleSubmit,
    register,
    control,
    mode,
    pipelineId,
    formState: { errors, isSubmitting },
  } = usePipelineFormHandler({ mainPipelineID });

  const onSubmit = useCreateOrUpdatePipeline(mode, pipelineId);

  return (
    <HeaderElement onSubmit={handleSubmit(onSubmit)}>
      <PipelineHeader control={control} isSubmitting={isSubmitting} />
      <input
        type="text"
        hidden
        defaultValue={mainPipelineID || 'main'}
        {...register('main_pipelineID')}
      />
      <PipelineForm
        mode={mode}
        register={register}
        control={control}
        errors={errors}
        mainPipelineID={mainPipelineID}
      />
    </HeaderElement>
  );
};

const PipelineWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ViewPipeline />
  </Suspense>
);

export default PipelineWithSuspense;
