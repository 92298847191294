import { type AxiosResponse } from 'axios';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { APIError, ResourceWithId } from '@api/types';
import { post, get, put, patch } from '@api/httpClient';
import queryClient from '@api/queryClient';
import { type SampleResultSchema, type SampleSchema } from '@/zod_schemas';
import { SearchFieldsType } from './types';

const QUERY_KEYS = {
  SAMPLES: ['samples'],
  SAMPLE: (sampleId: string) => ['samples', { id: sampleId }],
};

export const useCreateSample = () =>
  useMutation<AxiosResponse<SampleSchema[]>, APIError, SampleSchema[]>({
    mutationFn: (samples) => post<SampleSchema[]>('/samples/', samples),
  });

export const useUpdateSample = () =>
  useMutation<
    AxiosResponse<SampleSchema>,
    APIError,
    ResourceWithId<SampleSchema>
  >({
    mutationFn: ({ id, payload }) =>
      put<SampleSchema>(`/samples/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SAMPLES,
      });
    },
  });

export const useListSamples = () =>
  useSuspenseQuery<SampleSchema[]>({
    queryKey: QUERY_KEYS.SAMPLES,
    queryFn: async () => {
      const resp = await get<SampleSchema[]>('/samples/');
      return resp.data;
    },
  });

export const useGetSample = (sampleId?: string) =>
  useSuspenseQuery<SampleSchema>({
    queryKey: QUERY_KEYS.SAMPLE(sampleId || ''),
    queryFn: async () => {
      if (!sampleId) return {};

      const resp = await get(`/samples/${sampleId}/`);
      return resp.data;
    },
  });

export const useSearchSampleID = (sampleId?: string) =>
  useSuspenseQuery<SampleSchema[]>({
    queryKey: QUERY_KEYS.SAMPLE(sampleId || ''),
    queryFn: async () => {
      if (!sampleId) return [];

      const resp = await get<SampleSchema[]>(
        `/samples/?active=true&search=${sampleId}`,
      );

      return resp.data;
    },
  });

export const useGetPatientSample = (sampleId?: string) =>
  useSuspenseQuery<SampleSchema>({
    queryKey: QUERY_KEYS.SAMPLE(sampleId || ''),
    queryFn: async () => {
      if (!sampleId) return {};

      const resp = await get(`/samples/${sampleId}/by_patient/`);
      return resp.data;
    },
  });

export const usePatchSample = () =>
  useMutation<
    AxiosResponse<SampleSchema>,
    APIError,
    ResourceWithId<SampleSchema>
  >({
    mutationFn: ({ id, payload }) =>
      patch<SampleSchema>(`/samples/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SAMPLES,
      });
    },
  });
export const usePutSample = () =>
  useMutation<
    AxiosResponse<SampleSchema>,
    APIError,
    ResourceWithId<SampleSchema>
  >({
    mutationFn: ({ id, payload }) =>
      put<SampleSchema>(`/samples/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SAMPLES,
      });
    },
  });

const QUERY_KEYS_EXPERIMENT_SAMPLE = {
  experiment_sample_results: ['experiment_sample_results'],
  SAMPLEID: (sampleId: string) => [
    'experiment_sample_results',
    { id: sampleId },
  ],
};

// export const useGetExperimentsBySample = (searchFields: SearchFieldsType) =>
//   useSuspenseQuery<SampleResultSchema>({
//     queryKey: QUERY_KEYS_EXPERIMENT_SAMPLE.SAMPLEID(searchFields.id || ''),
//     queryFn: async () => {
//       if (!searchFields.id) return {};

//       const resp = await get(`/experiments/sample/?search=${searchFields.id}`);
//       return resp.data;
//     },
//   });

export const useGetExperimentsBySample = (
  searchFields: SearchFieldsType,
  enabled: boolean,
) =>
  useQuery<SampleResultSchema[]>({
    queryKey: QUERY_KEYS_EXPERIMENT_SAMPLE.SAMPLEID(searchFields.id || ''),
    queryFn: async () => {
      if (!searchFields.id) return [];
      const resp = await get(`/experiments/sample/?search=${searchFields.id}`);
      return resp.data;
    },
    retry: 1,
    enabled,
  });
