import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormGetValues,
  type UseFormTrigger,
} from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import PatientSampleLists from './PatientSampleLists';
import { PatientSchema, SampleSchema } from '@/zod_schemas';
import { patientBoxSx } from '@/styles/globalStyles';

interface PatientSamplesProps {
  control: Control<PatientSchema>;
  register: UseFormRegister<PatientSchema>;
  errors: FieldErrors<PatientSchema>;
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
  getValues: UseFormGetValues<PatientSchema>;
  trigger: UseFormTrigger<PatientSchema>;
  sampleGridData: SampleSchema[];
}
const PatientSamples = ({
  control,
  register,
  errors,
  setSampleGridData,
  getValues,
  trigger,
  sampleGridData,
}: PatientSamplesProps) => (
  <Box sx={patientBoxSx}>
    <Stack direction="column" spacing={3}>
      <Typography variant="h6" gutterBottom>
        SAMPLES
      </Typography>
      <PatientSampleLists
        control={control}
        register={register}
        errors={errors}
        trigger={trigger}
        getValues={getValues}
        setSampleGridData={setSampleGridData}
        sampleGridData={sampleGridData}
      />
    </Stack>
  </Box>
);

export default PatientSamples;
