import { ChangeEvent, useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { validateDirectoryName } from '@components/FileBrowser/utils/utils';

interface ICreateDirectoryDialogProps {
  handleCreate: (d: string) => void;
  handleClose: () => void;
  location: string;
}

const CreateDirectoryDialog = ({
  handleCreate,
  handleClose,
  location,
}: ICreateDirectoryDialogProps) => {
  const [directoryName, setDirectoryName] = useState('');
  const [error, setError] = useState('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDirectoryName(e.target.value);
    },
    [setDirectoryName],
  );

  const handleClickCreate = useCallback(() => {
    if (!directoryName) {
      setError('Directory name cannot be empty');
      return;
    }

    if (!validateDirectoryName(directoryName)) {
      setError('Invalid directory name');
      return;
    }

    handleCreate(directoryName);
    handleClose();
  }, [handleCreate, directoryName, handleClose]);

  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>Create New Directory</DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <Typography variant="body1">{location}</Typography>
          <TextField
            size="small"
            onChange={handleChange}
            variant="standard"
            autoFocus
          />
        </Stack>
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClickCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDirectoryDialog;
