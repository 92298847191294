import { startTransition, useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { useCreateHPO, useGetHpo, useUnAssignHPOPatient } from '@/api/hpo/hpo';
import { getHPOColumns } from './hpo/getHPOColumns';
import BtnMenuHPO from './BtnMenuHPO';
import { HPOSchema } from '@/zod_schemas';
import HPOActionButtons from './HPOActionButtons';
import HPOFormControls from './HPOFormControls';
import { usePatientContext } from '../PatientContext';

interface PatientHPOListsProps {
  setHpoGridData: React.Dispatch<React.SetStateAction<HPOSchema[]>>;
  hpoGridData: HPOSchema[];
}

const PatientHPOLists = ({
  setHpoGridData,
  hpoGridData,
}: PatientHPOListsProps) => {
  const [isAddHPOVisible, setIsAddHPOVisible] = useState(false);
  // const [searchCategory, setSearchCategory] = useState('term');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [hpoRows, setHpoRows] = useState<HPOSchema[]>(hpoGridData);
  const [selectedRows, setSelectedRows] = useState<HPOSchema[]>([]);

  const searchFieldRef = useRef<HTMLInputElement>(null);

  const focusSearchField = () => {
    if (searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  };

  const { data: fetchedData } = useGetHpo(
    debouncedSearchQuery.length >= 3 ? debouncedSearchQuery : undefined,
  );

  const handleAddHPOClick = () => setIsAddHPOVisible(true);
  const handleCancelClick = () => setIsAddHPOVisible(false);

  const resetFields = () => {
    setSearchQuery('');
    setIsAddHPOVisible(false);
  };

  const createHPO = useCreateHPO();

  const { mode, patientId } = usePatientContext(); // Access context values

  const handleConfirmClick = async (
    newData?: HPOSchema,
    type = 'multi-hpo-api',
  ) => {
    let data = [];
    switch (type) {
      case 'multi-hpo-api':
        data = selectedRows;
        break;
      default:
        data = [newData];
    }

    let validData = data.filter(
      (item): item is HPOSchema => item !== undefined,
    );

    if (patientId) {
      // Add the patient_id field to each object(assign sample to patient)
      validData = validData.map((hpo) => ({
        ...hpo,
        patient_id: patientId,
      }));
    }

    if (type === 'multi-hpo-api' || type === 'single-hpo-api') {
      try {
        const result = await createHPO.mutateAsync(validData);

        if (result?.data) {
          const mergedHpo = [
            ...hpoRows,
            ...(Array.isArray(result.data) ? result.data : [result.data]),
          ];
          const uniqueHpo = Array.from(
            new Map(mergedHpo.map((item) => [item?.id, item])).values(),
          );
          setHpoRows(uniqueHpo);
          enqueueSnackbar('Samples created successfully', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(
            'Failed to retrieve valid result after creating samples',
            { variant: 'error' },
          );
        }
      } catch (ex) {
        enqueueSnackbar('Failed to create samples', { variant: 'error' });
        console.error('Error during sample creation:', ex);
      }
    } else {
      const mergedHpo = [...hpoRows, ...validData];
      const uniqueHpo = Array.from(
        new Map(mergedHpo.map((item) => [item?.id, item])).values(),
      );
      setHpoRows(uniqueHpo);
    }

    resetFields();
  };

  const { mutateAsync } = useUnAssignHPOPatient();
  const unAssignPatientHPO = async (normalizationID: string) => {
    try {
      await mutateAsync(normalizationID);
      enqueueSnackbar('Successfully removed HPO', { variant: 'success' });
      setHpoRows((prevRows) =>
        prevRows.filter((row) => row.id !== normalizationID),
      );
    } catch (error) {
      enqueueSnackbar('Failed to removed HPO', { variant: 'error' });
      console.error('Error removing HPO:', error);
    }
  };

  const columns = getHPOColumns({
    selectAction: false,
    deleteAction: true,
    handleConfirmClick,
    onClickDelete: unAssignPatientHPO,
  });
  const columnsResponse = getHPOColumns({
    selectAction: true,
    deleteAction: false,
    handleConfirmClick,
    type: 'single-hpo-api',
    formMode: mode,
    formPatientId: patientId,
  });

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    if (fetchedData?.termData?.terms) {
      setHpoRows(fetchedData.termData.terms);
    }
  };

  useEffect(() => {
    setHpoGridData(hpoRows);
  }, [hpoRows, setHpoGridData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      startTransition(() => setDebouncedSearchQuery(searchQuery));
    }, 300);

    return () => clearTimeout(handler);
  }, [searchQuery]);

  // const handleCategoryChange = (event: SelectChangeEvent<string>) => {
  //   setSearchCategory(event.target.value);
  // };

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', padding: '50px 0 10px 0' }}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}
      >
        {!isAddHPOVisible && (
          <BtnMenuHPO
            handleAddHPOClick={handleAddHPOClick}
            handleConfirmClick={handleConfirmClick}
          />
        )}
      </Grid>
      <DataGrid
        rows={hpoRows}
        columns={columns}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        sx={{ height: 400 }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      {isAddHPOVisible && (
        <>
          <HPOActionButtons
            handleCancelClick={handleCancelClick}
            handleConfirmClick={() => handleConfirmClick()}
          />
          <HPOFormControls
            // searchCategory={searchCategory}
            // handleCategoryChange={handleCategoryChange}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleSearchClick={handleSearchClick}
            inputRef={searchFieldRef}
            focusSearchField={focusSearchField}
          />
          <DataGrid
            rows={fetchedData?.termData?.terms || []}
            columns={columnsResponse}
            initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
            sx={{ height: 400 }}
            pageSizeOptions={[3]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowsData = (
                fetchedData?.termData?.terms || []
              ).filter((row) => row.id && selectedIDs.has(row.id));
              setSelectedRows(selectedRowsData);
            }}
          />
        </>
      )}
    </Box>
  );
};

export default PatientHPOLists;
