import {
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { SampleResultSchema } from '@/zod_schemas';

interface ProgressDetailsProps {
  result: SampleResultSchema;
}

const ProgressDetails = ({ result }: ProgressDetailsProps) => {
  const steps = ['Pending', 'Waiting', 'In Progress', 'Running', 'Completed'];
  let activeStep = 0;

  switch (result.status) {
    case 'completed':
      activeStep = 5;
      break;
    case 'Running':
      activeStep = 3;
      break;
    case 'in_progress':
      activeStep = 2;
      break;
    case 'waiting':
      activeStep = 1;
      break;
    default:
      activeStep = 0;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        padding: '1rem',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" align="center">
          Experiment Status
        </Typography>
      </Grid>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default ProgressDetails;
