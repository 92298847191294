import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import {
  useAssignMultipleUserPermissionGroup,
  useAssignUserPermissionGroup,
  useGetPermissionGroupLists,
  useGetUserLists,
  useRemoveMultipleUserPermissionGroup,
  useRemoveUserPermissionGroup,
} from '@/api/users/users';
import UserLists from '../Users/UserList';
import UserPermissionGroup from '../Users/UserPermissionGroup';

interface AssingGroupPermissionProps {
  permissionId: string;
}

const AssingGroupPermission = ({
  permissionId,
}: AssingGroupPermissionProps) => {
  // eslint-disable-next-line no-spaced-func
  const [selectedRemoveRowIds, setRemoveSelectedRowIds] = useState<
    (string | number)[]
  >([]);

  // eslint-disable-next-line no-spaced-func
  const [selectedAssignRowIds, setAssignSelectedRowIds] = useState<
    (string | number)[]
  >([]);

  const { data: userLists, refetch: refetchUserLists } = useGetUserLists();
  const { data: permissionGroupLists, refetch: refetchPermissionGroupLists } =
    useGetPermissionGroupLists(permissionId);

  const [filteredUserLists, setFilteredUserLists] = useState(userLists);

  useEffect(() => {
    const filterUser = userLists.filter(
      (user) => !permissionGroupLists.some((group) => group.id === user.id),
    );
    setFilteredUserLists(filterUser);
  }, [userLists, permissionGroupLists]);

  const assignUserGroupHooks = useAssignUserPermissionGroup(permissionId);
  const assignUserGroup = async (id: string) => {
    assignUserGroupHooks
      .mutateAsync(id)
      .then(() => {
        enqueueSnackbar('Successfully assign user to group', {
          variant: 'success',
        });
        refetchUserLists();
        refetchPermissionGroupLists();
      })
      .catch(() => {
        enqueueSnackbar('Failed to assign user to group', {
          variant: 'error',
        });
      });
  };

  const removeUserGroupHooks = useRemoveUserPermissionGroup(permissionId);
  const removeUserGroup = async (id: string) => {
    removeUserGroupHooks
      .mutateAsync(id)
      .then(() => {
        enqueueSnackbar('Successfully remove user from group', {
          variant: 'success',
        });
        refetchUserLists();
        refetchPermissionGroupLists();
      })
      .catch(() => {
        enqueueSnackbar('Failed to remove user from group', {
          variant: 'error',
        });
      });
  };

  const removeMultipleUserGroupHooks =
    useRemoveMultipleUserPermissionGroup(permissionId);

  const removeMultipleUserGroup = async () => {
    removeMultipleUserGroupHooks
      .mutateAsync(selectedRemoveRowIds)
      .then(() => {
        enqueueSnackbar('Successfully remove user from group', {
          variant: 'success',
        });
        refetchUserLists();
        refetchPermissionGroupLists();
      })
      .catch(() => {
        enqueueSnackbar('Failed to remove user from group', {
          variant: 'error',
        });
      });
  };

  const assignMultipleUserGroupHooks =
    useAssignMultipleUserPermissionGroup(permissionId);

  const assignMultipleUserGroup = async () => {
    assignMultipleUserGroupHooks
      .mutateAsync(selectedAssignRowIds)
      .then(() => {
        enqueueSnackbar('Successfully add user to group', {
          variant: 'success',
        });
        refetchUserLists();
        refetchPermissionGroupLists();
      })
      .catch(() => {
        enqueueSnackbar('Failed to add user from group', {
          variant: 'error',
        });
      });
  };

  return (
    <Grid container spacing={2}>
      {/* All Users Grid */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h6" align="center">
            All Users
          </Typography>
          <Button
            variant="contained"
            onClick={() => assignMultipleUserGroup()}
            size="small"
            disabled={selectedAssignRowIds.length === 0}
            sx={{ marginBottom: '10px' }}
          >
            Assign
          </Button>
          <Stack direction="column" spacing={2}>
            <UserLists
              page="assign_permission"
              data={filteredUserLists}
              assignUserGroup={assignUserGroup}
              setAssignSelectedRowIds={setAssignSelectedRowIds}
            />
          </Stack>
        </Paper>
      </Grid>

      {/* Group Users Grid */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h6" align="center">
            Group Users
          </Typography>
          <Button
            variant="contained"
            onClick={() => removeMultipleUserGroup()}
            size="small"
            disabled={selectedRemoveRowIds.length === 0}
            sx={{ marginBottom: '10px' }}
          >
            Remove
          </Button>
          <UserPermissionGroup
            page="remove_permission"
            data={permissionGroupLists}
            removeUserGroup={removeUserGroup}
            setRemoveSelectedRowIds={setRemoveSelectedRowIds}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AssingGroupPermission;
