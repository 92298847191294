import { type PatientSchema } from '@/zod_schemas';

export const defaultPatientData: PatientSchema = {
  name: '',
  identification: '',
  ethnicity: [],
  date_of_birth: '',
  gender: 'male',
};

// export const genderLists = [
//   {
//     id: 1,
//     value: 'Male',
//   },
//   {
//     id: 2,
//     value: 'Female',
//   },
//   {
//     id: 3,
//     value: 'Unknown',
//   },
// ];

export const genderLists = ['Male', 'Female', 'Unknown'];
