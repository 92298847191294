import { useState } from 'react';

import {
  IconButton,
  Input,
  styled,
  Tooltip,
  type InputProps,
} from '@mui/material';
import {
  useController,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { FileBrowserDialog } from '@components/FileBrowser';

interface IFileBrowserTextFieldProps<T extends FieldValues> {
  controllerProps: Omit<ControllerProps<T>, 'render'>;
  inputProps?: Omit<InputProps, 'endAdornment'>;
}

interface IOpenFileBrowserButtonProps {
  onClick: () => void;
}

const StyledInput = styled(Input)(({ theme }) => ({
  '&.MuiInputBase-input': {
    cursor: 'pointer',
  },
  '&.MuiInputBase-root': {
    marginTop: theme.spacing(1.48),
  },
}));

const OpenFileBrowserButton = ({ onClick }: IOpenFileBrowserButtonProps) => (
  <Tooltip title="Open file browser">
    <IconButton onClick={onClick} size="small">
      <FolderOpenIcon />
    </IconButton>
  </Tooltip>
);

const FileBrowserTextField = <T extends FieldValues>({
  controllerProps,
  inputProps,
}: IFileBrowserTextFieldProps<T>) => {
  const {
    field: { onChange, ...others },
  } = useController({ ...controllerProps });
  const [openBrowser, setOpenBrowser] = useState(false);

  return (
    <>
      {openBrowser && (
        <FileBrowserDialog
          onClose={() => setOpenBrowser(false)}
          onFilesSelected={(files) => onChange(files[0])}
          selectMultiple={false}
        />
      )}
      <StyledInput
        contentEditable={false}
        readOnly
        endAdornment={
          <OpenFileBrowserButton onClick={() => setOpenBrowser(true)} />
        }
        {...inputProps}
        {...others}
      />
    </>
  );
};

export default FileBrowserTextField;
