import { Input, InputLabel, Stack } from '@mui/material';
import React from 'react';

interface SampleDataCountProps {
  setSampleCount: React.Dispatch<React.SetStateAction<number>>;
  sampleCount: number;
  handleKeyDown: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}

const SampleDataCount = ({
  setSampleCount,
  sampleCount,
  handleKeyDown,
}: SampleDataCountProps) => (
  <Stack direction="column" spacing={2}>
    <InputLabel id="sample-count" htmlFor="sample-count">
      Number of sample
    </InputLabel>
    <Input
      id="sample-count"
      type="number"
      value={sampleCount}
      onChange={(e) => setSampleCount(Number(e.target.value))}
      inputProps={{ min: 1 }}
      onKeyDown={handleKeyDown}
    />
  </Stack>
);

export default SampleDataCount;
