import PubSub from 'pubsub-js';

import { sentryCaptureException } from '@utils/sentry';
import uploadBasicFile from './uploadBasicFile';
import { FileUploadPubSubPayload } from './types';
import { finishUpload } from './utils';
import uploadMultipartFile from './uploadMultipartFile';
import { type UploadList } from '@/atoms/uploadsAtom';

const BASIC_UPLOAD_LIMIT = 10000000; // 10MB

const reportError = (id: string, error: Error) => {
  PubSub.publish(`upload-${id}`, {
    type: 'error',
    body: String(error),
  } as FileUploadPubSubPayload);

  sentryCaptureException(error);
};

const uploadFile = async ({
  file,
  location,
  id,
}: Omit<UploadList[0], 'directory'>) => {
  if (file.size > BASIC_UPLOAD_LIMIT) {
    await uploadMultipartFile(id, file, location);
  } else {
    await uploadBasicFile(id, file, location);
  }
};
export const uploadFiles = async (list: UploadList) => {
  list.forEach(({ location, file, id, directory }) => {
    uploadFile({ file, location, id })
      .then(() => finishUpload(id, directory))
      .catch((e) => reportError(id, e));
  });
};
