import { useEffect, useReducer, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExperimentEditSchema, GroupExperimentSchema } from '@/zod_schemas'; // Adjust your schema imports
import { ProjectFormElement } from '../StyledComponents';
import ProjectEditHeader from '../Components/ProjectEditHeader';
import Section from './Components/Section';
import HelperTextContainer from '@/components/HelperTextContainer';
import {
  useEditProject,
  useEditProjectFormHandler,
  useExperimentFormHandler,
  useUpdateExperiment,
} from '../hooks';
import SamplesContainer2 from './Components/SamplesContainer2';
import { useGetExperimentsGroupSamples } from '@/api/project/project';
import { useExperimentStatus } from '@/api/experiment/experiment';

interface ExperimentGroupFormProps {
  experimentGroup?: ExperimentEditSchema;
  isPolling?: boolean;
  setIsPolling?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ExperimentFormProps {
  experiment?: GroupExperimentSchema;
  experimentIndex: number;
  isPolling?: boolean;
  setIsPolling?: React.Dispatch<React.SetStateAction<boolean>>;
}

// The state will be an array of ExperimentGroup
type ExperimentGroupState = ExperimentEditSchema[];

// Action types for the reducer
type ExperimentGroupAction =
  | { type: 'ADD_GROUP'; payload: ExperimentEditSchema }
  | { type: 'SET_GROUPS'; payload: ExperimentEditSchema[] };

// Reducer to manage experiment group state
/* The useReducer hook is used to manage the state
   of the experiment groups in a structured and scalable way.
  ** ADD_GROUP: Adds a new experiment group to the current state.
  ** SET_GROUPS: Allows for completely replacing the current state with new experiment groups.
*/
const experimentGroupsReducer = (
  state: ExperimentGroupState,
  action: ExperimentGroupAction,
) => {
  switch (action.type) {
    case 'ADD_GROUP':
      return [...state, action.payload];
    case 'SET_GROUPS':
      return action.payload;
    default:
      return state;
  }
};

const ExperimentForm = ({
  experiment,
  experimentIndex,
  isPolling,
}: ExperimentFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    experimentId = experiment?.id,
    formState: { errors, isSubmitting },
  } = useExperimentFormHandler(experiment!);

  const { fields: sampleFields, remove: sampleRemove } = useFieldArray({
    control,
    name: 'samples',
    keyName: 'ids',
  });

  // Ensure every field has a valid `id` or fallback to `_id` -> to prevent useFieldArray bug
  const normalizedFields = sampleFields.map((field) => ({
    ...field,
    id: field.id || field.ids, // Ensure `id` is defined
  }));

  const { data, refetch } = useExperimentStatus({ id: experimentId });
  const responseExpStatus = data?.status;

  const onSubmit = useUpdateExperiment(experimentId);

  const [experimentStatus, setExperimentStatus] = useState(responseExpStatus);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (
      isPolling &&
      responseExpStatus !== 'completed' &&
      responseExpStatus !== 'failed'
    ) {
      intervalId = setInterval(async () => {
        try {
          // Call API to fetch updated status
          const { data: updatedData } = await refetch(); // Use refetch from the hook
          const updatedStatus = updatedData?.status;
          setExperimentStatus(updatedStatus);
          // Update form field with new status
          setValue('status', updatedStatus);
          if (updatedStatus === 'completed' || updatedStatus === 'failed') {
            if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
            }
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }
      }, 60000); // Poll every 1 hour
    }

    return () => {
      // Cleanup on component unmount or when polling stops
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    isPolling,
    responseExpStatus,
    experimentStatus,
    experimentId,
    setValue,
    experiment?.id,
    refetch,
  ]);

  if (!experiment) {
    return <div>No experiments details. Please contact admin</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography>
          {`(Experiment ${experimentIndex})`} Pipeline:{' '}
          {experiment.pipeline_config.name}
        </Typography>
        <Stack direction="row">
          <TextField
            label="Status"
            {...register('status')}
            disabled
            value={experimentStatus}
            fullWidth
          />
          <IconButton
            onClick={async () => {
              // Call API to fetch updated status
              const { data: updatedData } = await refetch(); // Use refetch from the hook
              const updatedStatus = updatedData?.status;
              setExperimentStatus(updatedStatus);
              // Update form field with new status
              setValue('status', updatedStatus);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Stack>
        {normalizedFields.length > 0 && (
          <Grid item xs={12}>
            <SamplesContainer2
              control={control}
              experimentIndex={experimentIndex}
              errors={errors}
              register={register}
              fields={normalizedFields}
              removeSample={(index: number) => sampleRemove(index)}
              isSubmitting={isSubmitting}
            />
          </Grid>
        )}
      </Stack>
    </form>
  );
};

const ExperimentGroupForm = ({
  experimentGroup,
  isPolling,
  setIsPolling,
}: ExperimentGroupFormProps) => (
  <Stack spacing={2}>
    {experimentGroup ? (
      experimentGroup?.experiments.map((experiment, experimentIndex) => (
        <Stack sx={{ margin: '100' }} spacing={2} key={experiment.id}>
          <ExperimentForm
            experiment={experiment}
            experimentIndex={experimentIndex}
            isPolling={isPolling}
            setIsPolling={setIsPolling}
          />
        </Stack>
      ))
    ) : (
      <div>No experiments details. Please contact admin</div>
    )}
  </Stack>
);

const ProjectEditForm = () => {
  const {
    handleSubmit,
    register,
    control,
    projectId,
    formState: { errors, isSubmitting },
  } = useEditProjectFormHandler();
  const onSubmit = useEditProject(projectId);
  const [isPolling, setIsPolling] = useState(false);

  const { fields: projectFields } = useFieldArray({
    control,
    name: 'experiment_groups',
    keyName: '_id',
  });
  const [state, dispatch] = useReducer(experimentGroupsReducer, []);
  const [experimentGroupsID, setExperimentGroupsID] = useState<string>('');
  const {
    data: experiment,
    isLoading,
    error,
  } = useGetExperimentsGroupSamples(experimentGroupsID);

  useEffect(() => {
    if (experiment && experiment.id) {
      dispatch({ type: 'ADD_GROUP', payload: experiment });
    }
  }, [experiment]);

  if (!projectFields) return <div>Loading...</div>;

  return (
    <>
      <ProjectFormElement onSubmit={handleSubmit(onSubmit)}>
        <ProjectEditHeader control={control} />
        <Section title="Name and description">
          <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="project-name">Name</InputLabel>
              <Input
                id="project-name"
                type="text"
                aria-describedby="project name field"
                size="medium"
                fullWidth
                {...register('name')}
              />
              <HelperTextContainer>
                <span>{errors.name?.message}</span>
              </HelperTextContainer>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="project-description">Description</InputLabel>
              <Input
                id="project-description"
                type="text"
                aria-describedby="project description field"
                size="medium"
                fullWidth
                {...register('description')}
              />
              <HelperTextContainer>
                {errors?.description && (
                  <span>{errors.description.message}</span>
                )}
              </HelperTextContainer>
            </FormControl>
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                size="small"
              >
                {isSubmitting ? <CircularProgress size={25} /> : 'Save'}
              </Button>
            </Stack>
          </Box>
        </Section>
      </ProjectFormElement>

      {projectFields.map((experimentGroup) => (
        <Accordion
          key={experimentGroup.id}
          onChange={(_, expanded) => {
            setExperimentGroupsID(experimentGroup.id);
            setIsPolling(expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Experiment Group: {experimentGroup.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography>Error loading experiment group</Typography>}
            {!isLoading &&
              !error &&
              (state.find((group) => group.id === experimentGroup.id) ? (
                <ExperimentGroupForm
                  experimentGroup={
                    state.find((group) => group.id === experimentGroup.id)!
                  }
                  isPolling={isPolling}
                  setIsPolling={setIsPolling}
                />
              ) : (
                <Typography>No experiment group found</Typography>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ProjectEditForm;
