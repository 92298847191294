import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateProjectSample,
  useGetProject,
  useGetProjectExperimentGroups,
  useUpdateEditProject,
  useUpdateProject,
} from '@api/project/project';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  projectSchema,
  projectEditSchema,
  SampleSchema,
  type ProjectSchema,
  ProjectSchemaID,
  type ProjectEditSchema,
  type GroupExperimentSchema,
  groupExperimentSchema,
} from '@/zod_schemas';
import { useCreateSample } from '@/api/sample/sample';
import { APIError } from '@/api/types';
import { useRunExperiment } from '@/api/experiment/experiment';

type ProjectPathParams = {
  projectId: string;
};

interface UseProjectFormHandlerReturn
  extends ReturnType<typeof useForm<ProjectSchema>> {
  mode: 'create' | 'update';
  projectId?: string;
}

type UseProjectFormHandler = () => UseProjectFormHandlerReturn;

export const useProjectFormHandler: UseProjectFormHandler = () => {
  const { projectId } = useParams<ProjectPathParams>();
  const { data } = useGetProject(projectId);
  const formMethods = useForm<ProjectSchema>({
    resolver: zodResolver(projectSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: projectId ? 'update' : 'create',
    projectId,
  };
};
// Typing for the createSample function
type CreateSampleType = UseMutationResult<
  AxiosResponse<SampleSchema[]>,
  APIError,
  SampleSchema[]
>;
// Updated processSamples function

async function processSamples(
  data: ProjectSchema,
  createSample: CreateSampleType,
) {
  try {
    const updatedExperiments = await Promise.all(
      data.experiments.map(async (experiment) => {
        const updatedSamples = await Promise.all(
          experiment.samples.map(async (sample) => {
            if (sample.id === '') {
              try {
                const response = await createSample.mutateAsync([sample]);
                const sampleResponse = response.data;
                return sampleResponse[0].id; // return created sample ID
              } catch (error) {
                throw new Error('Failed to create sample');
              }
            }
            return sample.id; // return existing sample ID
          }),
        );
        return { ...experiment, samples: updatedSamples };
      }),
    );

    // If all promises resolve without errors, show success message
    enqueueSnackbar('Samples created successfully', {
      variant: 'success',
    });

    // Return the updated data structure
    return { ...data, experiments: updatedExperiments };
  } catch (error) {
    // If any API call fails, show error message
    enqueueSnackbar('Failed to create sample', {
      variant: 'error',
    });

    // Return the original data or handle it as needed
    throw error; // propagate the error to terminate further processing
  }
}
async function processProject(data: ProjectEditSchema) {
  return {
    name: data.name,
    description: data.description,
  };
}
export const useCreateOrUpdateProject = (
  mode: 'create' | 'update',
  projectId?: string,
) => {
  const navigate = useNavigate();
  const createProject = useCreateProjectSample();
  const updateProject = useUpdateProject();
  const createSample = useCreateSample();

  const onSubmit: SubmitHandler<ProjectSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        processSamples(data, createSample)
          .then((updatedData) => {
            const finalProject = updatedData as ProjectSchemaID;
            if (mode === 'create') {
              createProject
                .mutateAsync(finalProject)
                .then(({ data: projectData }) => {
                  enqueueSnackbar('Project created successfully', {
                    variant: 'success',
                  });
                  navigate(`/projects/${projectData.id}`);
                  resolve();
                })
                .catch((error) => {
                  enqueueSnackbar('Failed to create project', {
                    variant: 'error',
                  });
                  reject(error);
                });
            } else {
              if (!projectId) {
                reject(
                  new Error('Project ID is required. Failed to update project'),
                );
                enqueueSnackbar('Unknown error occurred', { variant: 'error' });
                return;
              }

              updateProject
                .mutateAsync({ payload: finalProject, id: projectId })
                .then(() => {
                  enqueueSnackbar('Project updated successfully', {
                    variant: 'success',
                  });
                  navigate(`/projects/${data.id}`);
                  resolve();
                })
                .catch((error) => {
                  console.error(error);
                  enqueueSnackbar('Failed to update project', {
                    variant: 'error',
                  });
                  reject(error);
                });
            }
          })
          .catch((error) => {
            enqueueSnackbar(
              'Create project failed. Unable to process sample.',
              { variant: 'error' },
            );
            reject(error);
          });
      }),
    [createProject, updateProject, createSample, navigate, projectId, mode],
  );
  // const onSubmit: SubmitHandler<ProjectSchema> = (data) => {
  //   console.log(data);
  // };

  return onSubmit;
};

type ProjectEditPathParams = {
  projectId: string;
};

interface UseProjectEditFormHandlerReturn
  extends ReturnType<typeof useForm<ProjectEditSchema>> {
  mode: 'update';
  projectId?: string;
}

type UseProjectEditFormHandler = () => UseProjectEditFormHandlerReturn;

export const useEditProjectFormHandler: UseProjectEditFormHandler = () => {
  const { projectId } = useParams<ProjectEditPathParams>();
  const { data } = useGetProjectExperimentGroups(projectId);
  const formMethods = useForm<ProjectEditSchema>({
    resolver: zodResolver(projectEditSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: 'update',
    projectId,
  };
};

export const useEditProject = (projectId?: string) => {
  const navigate = useNavigate();
  const updateProject = useUpdateEditProject();

  const onSubmit: SubmitHandler<ProjectEditSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        processProject(data)
          .then((updatedData) => {
            if (!projectId) {
              reject(
                new Error('Project ID is required. Failed to update project'),
              );
              enqueueSnackbar('Unknown error occurred', { variant: 'error' });
              return;
            }
            updateProject
              .mutateAsync({ payload: updatedData, id: projectId })
              .then(() => {
                enqueueSnackbar('Project updated successfully', {
                  variant: 'success',
                });
                navigate(`/projects/${data.id}`);
                resolve();
              })
              .catch((error) => {
                enqueueSnackbar('Failed to update project', {
                  variant: 'error',
                });
                reject(error);
              });
          })
          .catch((error) => {
            enqueueSnackbar(
              'Create project failed. Unable to process sample.',
              { variant: 'error' },
            );
            reject(error);
          });
      }),
    [updateProject, navigate, projectId],
  );

  return onSubmit;
};

type ExperimentPathParams = {
  experimentId: string;
};

interface UseExperimentFormHandlerReturn
  extends ReturnType<typeof useForm<GroupExperimentSchema>> {
  mode: 'create' | 'update';
  experimentId?: string;
}

type UseExperimentFormHandler = (
  experiment: GroupExperimentSchema,
) => UseExperimentFormHandlerReturn;

export const useExperimentFormHandler: UseExperimentFormHandler = (
  experiment,
) => {
  const { experimentId } = useParams<ExperimentPathParams>();
  const formMethods = useForm<GroupExperimentSchema>({
    resolver: zodResolver(groupExperimentSchema),
    defaultValues: experiment || {},
  });

  return {
    ...formMethods,
    mode: 'update',
    experimentId,
  };
};

export const useUpdateExperiment = (experimentId?: string) => {
  const startRun = useRunExperiment();
  const onSubmit: SubmitHandler<GroupExperimentSchema> = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (!experimentId) {
          reject(
            new Error('Experiment ID is required. Failed to update experiment'),
          );
          enqueueSnackbar('Unknown error occurred', { variant: 'error' });
          return;
        }
        startRun
          .mutateAsync({ id: experimentId })
          .then(() => {
            enqueueSnackbar('Run experiment', {
              variant: 'success',
            });
            resolve();
          })
          .catch((error) => {
            enqueueSnackbar('Failed to run experiment', {
              variant: 'error',
            });
            reject(error);
          });
      }),
    [startRun, experimentId],
  );

  return onSubmit;
};
