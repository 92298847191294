import {
  CreateUploadPartRequest,
  CreateUploadPartResponse,
  FinishBasicUploadRequest,
  FinishMultipartUploadRequest,
  FinishUploadPartRequest,
  StartBasicUploadRequest,
  StartBasicUploadResponse,
  StartMultipartUploadRequest,
  StartMultipartUploadResponse,
} from '@api/fileHandling/types';
import { post } from '@api/httpClient';

export const startMultipartUploadMutationFn = ({
  numParts,
  fileUrl,
  fileSize,
}: StartMultipartUploadRequest) =>
  post<StartMultipartUploadResponse>('/file_handling/multipart/start_upload/', {
    num_parts: numParts,
    file_url: fileUrl,
    file_size: fileSize,
  });

export const createUploadPartMutationFn = ({
  uploadId,
  partNumber,
}: CreateUploadPartRequest) =>
  post<CreateUploadPartResponse>('/file_handling/multipart/create_part/', {
    upload_id: uploadId,
    part_number: partNumber,
  });

export const finishUploadPartMutationFn = ({
  uploadId,
  partNumber,
  etag,
}: FinishUploadPartRequest) =>
  post('/file_handling/multipart/finish_part_upload/', {
    upload_id: uploadId,
    num_part: partNumber,
    etag,
  });

export const finishMultipartUploadMutationFn = ({
  uploadId,
}: FinishMultipartUploadRequest) =>
  post('/file_handling/multipart/finish_upload/', {
    upload_id: uploadId,
  });

export const startBasicUploadMutationFn = ({
  fileUrl,
  fileSize,
}: StartBasicUploadRequest) =>
  post<StartBasicUploadResponse>('/file_handling/basic/start_upload/', {
    file_url: fileUrl,
    file_size: fileSize,
  });

export const finishBasicUploadMutationFn = ({
  fileId,
}: FinishBasicUploadRequest) =>
  post<StartBasicUploadResponse>('/file_handling/basic/finish_upload/', {
    file_id: fileId,
  });
