// import { PatientSchema } from '@/zod_schemas';
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import {
  type UseFormHandleSubmit,
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormGetValues,
  type UseFormTrigger,
} from 'react-hook-form';
import { CustomTabPanel, tabProps } from '../../Account/AccountPage';
import PatientHPO from './PatientHPO';
import PatientSamples from './PatientSamples';
import { HPOSchema, PatientSchema, SampleSchema } from '@/zod_schemas';

interface PatientDataFormProps {
  control: Control<PatientSchema>;
  register: UseFormRegister<PatientSchema>;
  errors: FieldErrors<PatientSchema>;
  setValue: UseFormSetValue<PatientSchema>;
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
  setHpoGridData: React.Dispatch<React.SetStateAction<HPOSchema[]>>;
  handleSubmit: UseFormHandleSubmit<PatientSchema>;
  getValues: UseFormGetValues<PatientSchema>;
  trigger: UseFormTrigger<PatientSchema>;
  hpoGridData: HPOSchema[];
  sampleGridData: SampleSchema[];
}
const PatientDataForm = ({
  control,
  register,
  errors,
  setSampleGridData,
  sampleGridData,
  setHpoGridData,
  hpoGridData,
  trigger,
  getValues,
}: PatientDataFormProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="HPO" {...tabProps(0)} />
          <Tab label="SAMPLES" {...tabProps(1)} />
        </Tabs>
      </Box>
      {/* Always render both panels but show only the active one */}
      <CustomTabPanel value={currentTab} index={0}>
        <PatientHPO setHpoGridData={setHpoGridData} hpoGridData={hpoGridData} />
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={1}>
        <PatientSamples
          control={control}
          register={register}
          errors={errors}
          getValues={getValues}
          trigger={trigger}
          setSampleGridData={setSampleGridData}
          sampleGridData={sampleGridData}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default PatientDataForm;
