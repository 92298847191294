import ProjectHeader from '@pages/common/Project/Components/ProjectHeader';
import {
  useCreateOrUpdateProjectBatch,
  useProjectBatchFormHandler,
} from '@pages/common/Project/hooksBatch';
import { Suspense } from 'react';
import { BatchConfigForm } from '.';
import { ProjectFormElement } from './StyledComponents';

const BatchConfig = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    projectId,
    mode,
    formState: { errors, isSubmitting },
  } = useProjectBatchFormHandler();

  // const onSubmit: SubmitHandler<BatchSchema> = (data) => {
  //   console.log(data);
  // };

  const onSubmit = useCreateOrUpdateProjectBatch(mode, projectId);

  return (
    <ProjectFormElement onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" value="batch" {...register('project_type')} />
      <ProjectHeader control={control} isSubmitting={isSubmitting} />
      <BatchConfigForm
        watch={watch}
        setValue={setValue}
        control={control}
        register={register}
        errors={errors}
        mode={mode}
      />
    </ProjectFormElement>
  );
};

const BatchWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <BatchConfig />
  </Suspense>
);

export default BatchWithSuspense;
