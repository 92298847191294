import { Box, Stack, Typography } from '@mui/material';
import PatientHPOLists from './PatientHPOLists';
import { HPOSchema } from '@/zod_schemas';
import { patientBoxSx } from '@/styles/globalStyles';

interface PatientHPOProps {
  setHpoGridData: React.Dispatch<React.SetStateAction<HPOSchema[]>>;
  hpoGridData: HPOSchema[];
}

const PatientHPO = ({ setHpoGridData, hpoGridData }: PatientHPOProps) => (
  <Box sx={patientBoxSx}>
    <Stack direction="column" spacing={3}>
      <Typography variant="h6" gutterBottom>
        HPO TERMS
      </Typography>
      <PatientHPOLists
        setHpoGridData={setHpoGridData}
        hpoGridData={hpoGridData}
      />
    </Stack>
  </Box>
);

export default PatientHPO;
