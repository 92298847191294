import { type AxiosResponse } from 'axios';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { get, post } from '@api/httpClient';
import type { APIError } from '@api/types';
import queryClient from '@api/queryClient';

import type {
  CreateUploadPartRequest,
  StartMultipartUploadRequest,
} from '@api/fileHandling/types';
import {
  CreateDirectoryRequest,
  CreateUploadPartResponse,
  DownloadUserFileRequest,
  DownloadUserFileResponse,
  ListUserFilesRequest,
  ListUserFilesResponse,
  StartMultipartUploadResponse,
} from '@api/fileHandling/types';
import {
  createUploadPartMutationFn,
  startMultipartUploadMutationFn,
} from '@api/fileHandling/queryFunctions';
import { getFileBrowserParentPath } from '@components/FileBrowser/utils';

export const QUERY_KEYS = {
  USER_FILES: ['file_handling', 'list'],
};

export const useStartUpload = () =>
  useMutation<
    AxiosResponse<StartMultipartUploadResponse>,
    APIError,
    StartMultipartUploadRequest
  >({
    mutationFn: startMultipartUploadMutationFn,
  });

export const useCreateMultipartUploadPart = () =>
  useMutation<
    AxiosResponse<CreateUploadPartResponse>,
    APIError,
    CreateUploadPartRequest
  >({
    mutationFn: createUploadPartMutationFn,
  });

export const useGetUserFiles = ({ location }: ListUserFilesRequest) =>
  useSuspenseQuery<ListUserFilesResponse>({
    queryKey: [...QUERY_KEYS.USER_FILES, location],
    queryFn: async () => {
      const resp = await get('/file_handling/list', { params: { location } });
      return resp.data;
    },
  });

export const useCreateDirectory = () =>
  useMutation<AxiosResponse<null>, APIError, CreateDirectoryRequest>({
    mutationFn: ({ dirPath }) =>
      post<null>('/file_handling/create_directory/', { dir_path: dirPath }),
    onSuccess: (_resp, { dirPath }) => {
      const parentPath = getFileBrowserParentPath(dirPath);

      queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.USER_FILES, parentPath],
      });
    },
  });

export const useDownloadUserFile = () =>
  useMutation<
    AxiosResponse<DownloadUserFileResponse>,
    APIError,
    DownloadUserFileRequest
  >({
    mutationFn: ({ pk }) => post(`/files/${pk}/generate_download_url/`),
  });
