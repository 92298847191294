import { Stack } from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type FieldArrayWithId,
  type UseFormSetValue,
} from 'react-hook-form';

import SampleForm from '@pages/common/Project/Forms/SampleForm';
import { type ExperimentSchema, type ProjectSchema } from '@/zod_schemas';

interface ISamplesContainerProps {
  control: Control<ProjectSchema>;
  experimentIndex: number;
  register: UseFormRegister<ProjectSchema>;
  errors: FieldErrors<ProjectSchema>;
  fields: FieldArrayWithId<ExperimentSchema, 'samples'>[];
  removeSample: (index: number) => void;
  setValue: UseFormSetValue<ProjectSchema>;
}

const SamplesContainer = ({
  control,
  setValue,
  errors,
  experimentIndex,
  register,
  fields,
  removeSample,
}: ISamplesContainerProps) =>
  fields.length > 0 && (
    <Stack spacing={1}>
      {fields.map(({ id }, index) => (
        <SampleForm
          register={register}
          remove={() => removeSample(index)}
          index={index}
          experimentIndex={experimentIndex}
          key={id}
          errors={errors}
          control={control}
          setValue={setValue}
        />
      ))}
    </Stack>
  );

export default SamplesContainer;
