import { useAtom } from 'jotai';

import { uploadFiles } from '@components/FileBrowser/utils/fileUpload/uploadFiles';
import { locationAtom } from '@components/FileBrowser/atoms';
import { uploadsAtom } from '@/atoms/uploadsAtom';

export const useFileBrowserLocation = () => {
  const [location, setLocation] = useAtom(locationAtom);

  return {
    location,
    setLocation,
  };
};

export const useAddFilesToUpload = () => {
  const [, setUploads] = useAtom(uploadsAtom);

  return (newFiles: File[] | FileList, location: string) => {
    const fileObjs = Array.from(newFiles).map((file) => ({
      id: crypto.randomUUID(),
      file,
      location: `${location}${file.name}`,
      directory: location,
    }));

    setUploads((prev) => [...prev, ...fileObjs]);
    uploadFiles(fileObjs);
  };
};
