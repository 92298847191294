import {
  type Control,
  type FieldArrayWithId,
  type FieldErrors,
  type Path,
} from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';

import HelperTextContainer from '@components/HelperTextContainer';
import FileBrowserTextField from '@components/FormFields/FileBrowserTextField';
import FormSelect from '@components/FormFields/FormSelect';
import { type GroupExperimentSchema } from '@/zod_schemas';

interface IFilesContainer2Props {
  control: Control<GroupExperimentSchema>;
  errors: FieldErrors<GroupExperimentSchema>;
  sampleIndex: number;
  fields: FieldArrayWithId<GroupExperimentSchema>[];
  isSubmitting: boolean;
}

const FilesContainer2 = ({
  sampleIndex,
  control,
  errors,
  isSubmitting,
  fields,
}: IFilesContainer2Props) => {
  const filesPath: Path<GroupExperimentSchema> = `samples.${sampleIndex}.files`;
  const fileErrors = errors.samples?.[sampleIndex]?.files;

  return (
    <Stack spacing={3}>
      {fields.map(({ id }, fileIndex) => (
        <Stack direction="row" spacing={3} key={id}>
          <FormControl
            size="small"
            variant="standard"
            sx={{ minWidth: '12rem' }}
          >
            <InputLabel
              id={`file-type-${fileIndex}-label`}
              htmlFor={`file-type-${fileIndex}`}
            >
              File Type
            </InputLabel>
            <FormSelect
              controllerProps={{
                control,
                name: `${filesPath}.${fileIndex}.file_type`,
              }}
              selectProps={{
                labelId: `file-type-${fileIndex}-label`,
                id: `file-type-${fileIndex}`,
                'aria-describedby': `file-type-${fileIndex}-label`,
              }}
            >
              <MenuItem value="fastq_forward">FASTQ Forward</MenuItem>
              <MenuItem value="fastq_reverse">FASTQ Reverse</MenuItem>
              <MenuItem value="bam">BAM</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              <span>{fileErrors?.[fileIndex]?.file_type?.message}</span>
            </HelperTextContainer>
          </FormControl>
          <FormControl fullWidth key={id}>
            <InputLabel htmlFor={`file-${fileIndex}`}>
              File {fileIndex + 1}
            </InputLabel>
            <FileBrowserTextField
              inputProps={{
                id: `file-${fileIndex}`,
                type: 'text',
                size: 'small',
                fullWidth: true,
                'aria-describedby': `file-${fileIndex}`,
              }}
              controllerProps={{
                control,
                name: `${filesPath}.${fileIndex}.file_path`,
              }}
            />
            <HelperTextContainer>
              <span>{fileErrors?.[fileIndex]?.file_path?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Stack direction="row" spacing={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            size="small"
          >
            {isSubmitting ? <CircularProgress size={25} /> : 'Run'}
          </Button>
          <Button
            // eslint-disable-next-line no-console
            onClick={() => console.log('delete experiment')}
            variant="contained"
            color="error"
          >
            Remove
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FilesContainer2;
