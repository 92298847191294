import { useSuspenseQuery } from '@tanstack/react-query';
import { get } from '@api/httpClient';
import { EthnicitySchema } from '@/zod_schemas';

const QUERY_KEYS = {
  ETHNICITYS: ['ethnicity'],
  ETHNICITY: (ethnicityId: string) => ['ethnicity', { id: ethnicityId }],
};

export const useGetEthnicity = (ethnicityId?: string) =>
  useSuspenseQuery<EthnicitySchema>({
    queryKey: QUERY_KEYS.ETHNICITY(ethnicityId || ''),
    queryFn: async () => {
      if (!ethnicityId) {
        const resp = await get('/ethnicity/');
        return resp.data;
      }

      const resp = await get(`/ethnicity/${ethnicityId}/`);
      return resp.data;
    },
  });
