import { type Control, useWatch } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { type PipelineSchema } from '@/zod_schemas';

interface PipelineHeaderProps {
  control: Control<PipelineSchema>; // Use union type here
  isSubmitting: boolean;
}

const PipelineHeader = ({ control, isSubmitting }: PipelineHeaderProps) => {
  const name = useWatch({
    control: control as Control<PipelineSchema>, // Use union type here
    name: 'name',
  });

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        sx={{ display: 'flex' }}
      >
        <Typography variant="h5">{name || 'New Pipeline'}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}
        sx={{ display: 'flex' }}
      >
        <Stack spacing={1} direction="row">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            size="small"
          >
            {isSubmitting ? <CircularProgress size={25} /> : 'Save'}
          </Button>
          {/* <Button variant="contained">New Config</Button> */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PipelineHeader;
