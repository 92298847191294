import { Button, Grid, Stack } from '@mui/material';

interface HPOActionButtonsProps {
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
}

const HPOActionButtons = ({
  handleCancelClick,
  handleConfirmClick,
}: HPOActionButtonsProps) => (
  <Grid
    item
    xs={12}
    sm={6}
    sx={{ display: 'flex', padding: '50px 0 10px 0' }}
    justifyContent={{ xs: 'center', sm: 'flex-end' }}
  >
    <Stack spacing={1} direction="row">
      <Button
        type="button"
        variant="contained"
        color="error"
        size="small"
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={handleConfirmClick}
      >
        Confirm
      </Button>
    </Stack>
  </Grid>
);

export default HPOActionButtons;
