import { Chip, Stack, Typography } from '@mui/material';

import { useRunExperiment } from '@api/experiment/experiment';
import { FileBrowserDialog } from '@components/FileBrowser';
import { useFileBrowserLocation } from '@components/FileBrowser/hooks';
import { useMemo, useState } from 'react';
import { type ExperimentStatusSchema } from '@/zod_schemas';
import { getChipColor, getChipIcon, getChipLabel } from './utils';

interface IHeaderStatusChipProps {
  status: ExperimentStatusSchema;
  experimentId?: string;
}

const HeaderStatusChip = ({ status, experimentId }: IHeaderStatusChipProps) => {
  const [showBrowser, setShowBrowser] = useState(false);

  const chipColor = useMemo(() => getChipColor(status), [status]);
  const chipLabelText = useMemo(() => getChipLabel(status), [status]);
  const ChipIcon = useMemo(() => getChipIcon(status), [status]);

  const startRun = useRunExperiment();
  const { setLocation } = useFileBrowserLocation();

  const handleOnClick = () => {
    if (!experimentId) {
      return;
    }

    if (status === 'pending') {
      startRun.mutateAsync({ id: experimentId });
    }

    if (status === 'completed') {
      setLocation(`/results/experiment-${experimentId}/`);
      setShowBrowser(true);
    }
  };

  return (
    <>
      {showBrowser && (
        <FileBrowserDialog onClose={() => setShowBrowser(false)} />
      )}
      <Chip
        onClick={handleOnClick}
        label={
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{ color: 'white' }}
          >
            <ChipIcon fontSize="small" />
            <Typography variant="caption">{chipLabelText}</Typography>
          </Stack>
        }
        color={chipColor}
        size="small"
      />
    </>
  );
};

export default HeaderStatusChip;
