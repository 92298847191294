import { useCallback, useEffect } from 'react';

import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';

import HelperTextContainer from '@components/HelperTextContainer';
import {
  defaultCustomSample,
  defaultSingleSample,
  defaultTrioSample,
} from '@pages/common/Project/Forms/defaults';
import FormSelect from '@components/FormFields/FormSelect';
import SamplesContainer from '@pages/common/Project/Forms/Components/SamplesContainer';
import { type ExperimentTypeSchema, type ProjectSchema } from '@/zod_schemas';
import PipelineComponent from '@/components/Pipeline/PipelineSelect';

interface IExperimentFormProps {
  register: UseFormRegister<ProjectSchema>;
  setValue: UseFormSetValue<ProjectSchema>;
  index: number;
  errors: FieldErrors<ProjectSchema>;
  control: Control<ProjectSchema>;
  changeExperimentType: (type: ExperimentTypeSchema) => void;
}

const ExperimentForm = ({
  index: experimentIndex,
  register,
  errors,
  setValue,
  control,
  changeExperimentType,
}: IExperimentFormProps) => {
  const { append, replace, fields, remove } = useFieldArray({
    control,
    name: `experiments.${experimentIndex}.samples`,
    shouldUnregister: true,
  });

  const experimentType = useWatch({
    control,
    name: `experiments.${experimentIndex}.experiment_type`,
  });

  const handleAddSample = useCallback(() => {
    if (experimentType !== 'custom') {
      changeExperimentType('custom');
    }

    append(defaultCustomSample);
  }, [append, changeExperimentType, experimentType]);

  const experimentErrors = errors.experiments?.[experimentIndex];

  useEffect(() => {
    if (experimentType === 'single') {
      replace([...fields, defaultSingleSample].slice(0, 1));
      return;
    }

    if (experimentType === 'trio' && fields.length < 3) {
      replace([...fields, ...defaultTrioSample].slice(0, 3));
    }
    // Doing this because adding "fields" to the dependency array causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experimentType, replace]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel htmlFor="experiment_name">Name</InputLabel>
          <Input
            id="experiment_name"
            type="text"
            aria-describedby="experiment name field"
            size="medium"
            fullWidth
            {...register(`experiments.${experimentIndex}.name`)}
          />
          <HelperTextContainer>
            {experimentErrors?.name?.message}
          </HelperTextContainer>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth size="small" variant="standard">
          <InputLabel id="experiment-type-label" htmlFor="experiment-type">
            Type
          </InputLabel>
          <FormSelect
            selectProps={{
              labelId: 'experiment-type-label',
              id: 'experiment-type',
              'aria-describedby': 'experiment type field',
            }}
            controllerProps={{
              control,
              name: `experiments.${experimentIndex}.experiment_type`,
            }}
          >
            <MenuItem value="single">Single</MenuItem>
            <MenuItem value="trio">Trio</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </FormSelect>
          <HelperTextContainer>
            {experimentErrors?.experiment_type?.message}
          </HelperTextContainer>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel htmlFor="experiment-description">Description</InputLabel>
          <Input
            id="experiment-description"
            type="text"
            aria-describedby="experiment description field"
            size="medium"
            fullWidth
            {...register(`experiments.${experimentIndex}.description`)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <PipelineComponent
            register={register}
            control={control}
            index={experimentIndex}
            errors={errors}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Stack spacing={1} direction="row" alignItems="center">
          <Button variant="contained" onClick={handleAddSample} size="small">
            Add Sample
          </Button>
          <HelperTextContainer>
            <span>
              {experimentErrors?.samples?.root &&
                experimentErrors.samples.root.message}
              {experimentErrors?.samples && experimentErrors.samples.message}
            </span>
          </HelperTextContainer>
        </Stack>
      </Grid>
      {fields.length > 0 && (
        <Grid item xs={12}>
          <SamplesContainer
            setValue={setValue}
            control={control}
            experimentIndex={experimentIndex}
            errors={errors}
            register={register}
            fields={fields}
            removeSample={(index: number) => remove(index)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ExperimentForm;
