import {
  cloneElement,
  isValidElement,
  Children,
  useMemo,
  useCallback,
  type MouseEvent,
  type HTMLAttributes,
  type ReactElement,
  type MouseEventHandler,
} from 'react';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { Download } from '@mui/icons-material';

interface IRowWithContextMenuProps {
  children: ReactElement<{
    onContextMenu: MouseEventHandler<HTMLDivElement>;
  }>;
  handleDownload: () => void;
  isDirectory: boolean;
}

const preventNativeContextMenu = (e: MouseEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

const RowButtonWithContextMenu = ({
  children,
  handleDownload,
  isDirectory,
}: IRowWithContextMenuProps) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const { onClick } = bindTrigger(popupState);

  const handleRightClickRow = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      preventNativeContextMenu(e);
      e.target.dispatchEvent(new MouseEvent('click', { bubbles: true }));

      onClick(e);
    },
    [onClick],
  );

  const modifiedChildren = useMemo(
    () =>
      Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return child;
        }

        return cloneElement<HTMLAttributes<HTMLDivElement>>(child, {
          onContextMenu: !isDirectory
            ? handleRightClickRow
            : preventNativeContextMenu,
        });
      }),
    [children, handleRightClickRow, isDirectory],
  );

  return (
    <>
      {modifiedChildren?.map((modifiedChild) => modifiedChild)}
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          dense
          divider
          onClick={() => {
            handleDownload();
            popupState.close();
          }}
        >
          <ListItemIcon>
            <Download fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default RowButtonWithContextMenu;
