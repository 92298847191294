import { Chip, emphasize, Grid, List as MUIList, styled } from '@mui/material';
import MUIDescriptionIcon from '@mui/icons-material/Description';
import MUIFolderIcon from '@mui/icons-material/Folder';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

export const FileBrowserHeaderContainer = styled(Grid)(() => ({
  padding: '5px 0 5px 0',
  backgroundColor: 'lightgray',
  '& span': {
    padding: '0 5px 0 5px',
  },
}));

export const FileNameAndIconContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
}));

export const FileIcon = styled(MUIDescriptionIcon)(() => ({
  fontSize: '1rem',
  color: 'mediumseagreen',
}));

export const FolderIcon = styled(MUIFolderIcon)(() => ({
  fontSize: '1rem',
  color: 'cornflowerblue',
}));

export const FileBrowserContainer = styled('div')(({ theme }) => ({
  rowGap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const FileBrowserBackButton = styled(AssignmentReturnIcon)(() => ({
  fontSize: '1rem',
  color: 'orangered',
}));

export const List = styled(MUIList)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];

  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
