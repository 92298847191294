import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  type Control,
  Controller,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import {
  type Pipeline,
  useGetPipeline,
  type PipelineConfig,
  PipelineOptions,
} from '@/api/pipeline';
import HelperTextContainer from '@/components/HelperTextContainer';
import { type PipelineSchema } from '@/zod_schemas';
import { boxSx } from '@/styles/globalStyles';

interface PipelineConfigFormProps {
  mainPipelineID?: string;
  register: UseFormRegister<PipelineSchema>;
  control: Control<PipelineSchema>;
  errors: FieldErrors<PipelineSchema>;
}

const generateConfigFields = (
  configs: PipelineConfig[],
  { register, control, errors }: PipelineConfigFormProps,
) => {
  if (!configs || configs.length === 0) {
    return null;
  }

  return (
    <Stack>
      {configs?.map((config, index: number) => {
        const configType = config.type;
        const isNumberInput = config.input_type === 'number';
        const configInputType = isNumberInput ? 'number' : 'string';
        const optionsType = config.options as PipelineOptions[];

        if (configType === 'textfield') {
          return (
            <FormControl fullWidth margin="normal" key={config.id}>
              <Controller
                name={`configs.${index}.value`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`${config.name} ${configInputType}`}
                    variant="outlined"
                    fullWidth
                    type={configInputType}
                    {...register(`configs.${index}.value`, {
                      valueAsNumber: isNumberInput,
                    })}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                  />
                )}
              />
              <HelperTextContainer>
                <span>{errors?.configs?.[index]?.value?.message}</span>
              </HelperTextContainer>
            </FormControl>
          );
        }
        if (configType === 'displayfield') {
          return (
            <FormControl fullWidth margin="normal" key={config.id}>
              <Controller
                name={`configs.${index}.options`}
                control={control}
                render={({ field }) => (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      color: '#000',
                      '& .MuiInputBase-input': {
                        cursor: 'not-allowed',
                      },
                    }}
                    {...field}
                    variant="outlined"
                    label={config.name}
                    value={config.value}
                    fullWidth
                    {...register(`configs.${index}.value`)}
                  />
                )}
              />
              <HelperTextContainer>
                <span>{errors?.configs?.[index]?.value?.message}</span>
              </HelperTextContainer>
            </FormControl>
          );
        }
        if (configType === 'dropdown') {
          return (
            <FormControl
              fullWidth
              size="small"
              variant="standard"
              key={config.id}
            >
              <Controller
                name={`configs.${index}.options`}
                control={control}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    multiple
                    options={optionsType}
                    getOptionLabel={(option: PipelineOptions) =>
                      String(option.value)
                    }
                    onChange={(_event, newValue) => {
                      onChange(newValue || []);
                    }}
                    isOptionEqualToValue={(option, valueOption) =>
                      option.id === valueOption.id
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          backgroundColor: '#fff',
                        }}
                        variant="outlined"
                        {...params}
                        label="Select Options"
                        error={!!errors.configs?.[index]?.value?.message}
                        helperText={
                          errors.configs?.[index]?.value?.message ?? null
                        }
                      />
                    )}
                  />
                )}
              />
              <HelperTextContainer>
                <span>{errors?.configs?.[index]?.value?.message}</span>
              </HelperTextContainer>
            </FormControl>
          );
        }

        // Prevent eslint error. Return null value if no matching
        return null;
      })}
    </Stack>
  );
};

const PipelineConfigForm = ({
  mainPipelineID,
  register,
  control,
  errors,
}: PipelineConfigFormProps) => {
  const { data } = useGetPipeline(mainPipelineID);
  const dataPipeline = data as Pipeline;

  // If there is no configs in pipeline declare as empty to prevent typescript errors
  const configs = dataPipeline.configs ? dataPipeline.configs : [];
  return (
    <Box sx={boxSx}>
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={3}>
          <Typography variant="h6" gutterBottom>
            Config
          </Typography>
          {generateConfigFields(configs, { register, control, errors })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PipelineConfigForm;
