import { ReactNode } from 'react';

import { Select, type SelectProps } from '@mui/material';
import {
  Controller,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form';

interface IFormSelectProps<T extends FieldValues> {
  controllerProps: Omit<ControllerProps<T>, 'render'>;
  children: ReactNode[];
  selectProps?: SelectProps;
}

// const FormSelect = <T extends FieldValues>({
//   controllerProps,
//   children,
//   selectProps,
// }: IFormSelectProps<T>) => (
//   <Controller
//     {...controllerProps}
//     render={({ field }) => (
//       <Select defaultValue="" {...selectProps} {...field}>
//         {children.map((child) => child)}
//       </Select>
//     )}
//   />
// );

const FormSelect = <T extends FieldValues>({
  controllerProps,
  children,
  selectProps,
}: IFormSelectProps<T>) => (
  <Controller
    {...controllerProps}
    render={({ field }) => (
      <Select {...selectProps} {...field}>
        {children}
      </Select>
    )}
  />
);

export default FormSelect;
