import axios from 'axios';
import PubSub from 'pubsub-js';

import { FileUploadPubSubPayload } from '@components/FileBrowser/utils/fileUpload/types';
import { finishBasicUpload, startBasicUpload } from './mutations';

const handleReportProgress = (id: string, progress: number) => {
  PubSub.publish(`upload-${id}`, {
    type: 'progress',
    body: progress,
  } as FileUploadPubSubPayload);
};

const uploadBasicFile = async (id: string, file: File, fileUrl: string) => {
  const {
    data: { upload_url: uploadUrl, file_id: serverFileId },
  } = await startBasicUpload({ fileUrl, fileSize: file.size });

  const { status: uploadStatus } = await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    withCredentials: true,
    onUploadProgress: (progressEvent) =>
      handleReportProgress(id, (progressEvent.loaded / file.size) * 100),
  });

  if (uploadStatus !== 200) {
    throw new Error('Error uploading file');
  }

  const { status } = await finishBasicUpload({ fileId: serverFileId });

  if (status !== 200) {
    throw new Error('Error finishing upload');
  }
};

export default uploadBasicFile;
