import { FormControl, Input, InputLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { type FormField } from '@pages/common/Login/types';
import HelperTextContainer from '@components/HelperTextContainer';
import { useState } from 'react';
import ShowHidePassword from '@/components/Password/ShowHidePassword';
import { type LoginSchema } from '@/zod_schemas';
import { getInputType } from '@/utils/getInputType';

const loginFormFields: FormField[] = [
  {
    id: 'email',
    label: 'E-Mail',
    type: 'email',
    required: true,
    describedby: 'email field',
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    required: true,
    describedby: 'password field',
  },
];

const LoginOtherFields = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    formState: { errors },
  } = useFormContext<LoginSchema>();

  return (
    <>
      {loginFormFields.map((field) => (
        <FormControl key={field.id}>
          <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
          <Input
            id={field.id}
            type={getInputType(field.id, showPassword)}
            autoComplete={field.id === 'email' ? 'email' : 'off'}
            {...register(field.id, { required: field.required })}
            aria-describedby={field.describedby}
            size="small"
            endAdornment={
              field.id === 'password' && (
                <ShowHidePassword
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              )
            }
          />
          <HelperTextContainer id={`${field.id}-helper-text`}>
            {errors[field.id] && <span>{errors[field.id]?.message}</span>}
          </HelperTextContainer>
        </FormControl>
      ))}
    </>
  );
};

export default LoginOtherFields;
