// userColumns.ts
import { GridColDef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserListsSchema } from '@/zod_schemas';

interface ColumnProps {
  page?: string;
  assignUserGroup?: (id: string) => void;
  removeUserGroup?: (id: string) => void;
}

const useGetUserColumns = ({
  page,
  assignUserGroup,
  removeUserGroup,
}: ColumnProps): GridColDef<UserListsSchema>[] => {
  const navigate = useNavigate();

  return [
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => {
        if (page === 'assign_permission') {
          return (
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  const { id } = params.row;
                  if (assignUserGroup) {
                    assignUserGroup(id);
                  }
                }}
              >
                Assign
              </Button>
            </Box>
          );
        }
        if (page === 'remove_permission') {
          return (
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  const { id } = params.row;
                  if (removeUserGroup) {
                    removeUserGroup(id);
                  }
                }}
              >
                Remove
              </Button>
            </Box>
          );
        }
        return (
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                const { id } = params.row;
                navigate(`user/edit/${id}`);
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: 8 }}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];
};

export default useGetUserColumns;
