import { Stack } from '@mui/material';

import { useExperimentStatus } from '@api/experiment/experiment';
import RemoveExperimentButton from '@pages/common/Project/Forms/Components/RemoveExperimentButton';
import HeaderStatusChip from '@pages/common/Project/Forms/Components/ExperimentContainer/HeaderStatusChip';

interface IHeaderActionProps {
  removeExperiment: () => void;
  mode: 'create' | 'update';
  experimentId?: string;
}

const HeaderAction = ({
  removeExperiment,
  mode,
  experimentId,
}: IHeaderActionProps) => {
  const { data } = useExperimentStatus({ id: experimentId });
  const showChip = mode === 'update' && !!data;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {showChip && (
        <HeaderStatusChip status={data?.status} experimentId={experimentId} />
      )}
      <RemoveExperimentButton removeExperiment={removeExperiment} />
    </Stack>
  );
};

export default HeaderAction;
