import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import {
  type PatientSchema,
  patientSchema,
  SampleSchema,
  HPOSchema,
} from '@/zod_schemas';
import {
  useCreatePatient,
  useGetPatient,
  usePatchPatient,
} from '@/api/patient/patient';
import { useGetPatientHPO } from '@/api/hpo';
import { useGetPatientSample } from '@/api/sample/sample';

type PatientPathParams = {
  patientId: string;
};

interface UsePatientFormHandlerReturn
  extends ReturnType<typeof useForm<PatientSchema>> {
  mode: 'create' | 'update';
  patientId?: string;
  defaultHPOs?: HPOSchema[];
  defaultSamples?: SampleSchema[];
}

type UsePatientFormHandler = () => UsePatientFormHandlerReturn;

export const usePatientFormHandler: UsePatientFormHandler = () => {
  const { patientId } = useParams<PatientPathParams>();
  const { data: patientData } = useGetPatient(patientId);
  const { data: hpoDataWrapper } = useGetPatientHPO(patientId);
  const { data: sampleDataWrapper } = useGetPatientSample(patientId);

  const hpoData = Array.isArray(hpoDataWrapper) ? hpoDataWrapper : [];
  const sampleData = Array.isArray(sampleDataWrapper) ? sampleDataWrapper : [];

  let data = {};
  if (patientId) {
    data = {
      ...patientData,
      hpo_lists: hpoData,
      sample_lists: sampleData,
    };
  }

  const formMethods = useForm<PatientSchema>({
    resolver: zodResolver(patientSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: patientId ? 'update' : 'create',
    patientId,
    defaultHPOs: hpoData,
    defaultSamples: sampleData,
  };
};

export type ProcessedPatientData = Omit<
  PatientSchema,
  'sampleGridData' | 'hpoGridData'
> & {
  samples: SampleSchema[];
  hpos: HPOSchema[];
};
// const ethnicityData = data.map((item) => item.name);

// processPatientData.js or similar
export const processPatientData = (
  data: PatientSchema,
  sampleGridData: SampleSchema[],
  hpoGridData: HPOSchema[],
) => {
  // const sampleIDs = sampleGridData.map((item) => item.id);
  const sampleIDs = sampleGridData
    .map((item) => item.id)
    .filter((id): id is string => id !== undefined);

  // const hpoIDs = hpoGridData.map((item) => item.id);
  const hpoIDs = hpoGridData
    .map((item) => item.id)
    .filter((id): id is string => id !== undefined);

  const processedData = {
    ...data,
    sample_ids: sampleIDs,
    hpo_ids: hpoIDs,
  };

  return processedData;
};

export const useCreateOrUpdatePatient = (
  sampleGridData: SampleSchema[],
  hpoGridData: HPOSchema[],
  mode: 'create' | 'update',
  patientId?: string,
) => {
  const navigate = useNavigate();
  const createPatient = useCreatePatient();
  const updatePatient = usePatchPatient();

  const onSubmit: SubmitHandler<PatientSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        // Process the patient data before submitting
        const processedData = processPatientData(
          data,
          sampleGridData,
          hpoGridData,
        );
        if (mode === 'create') {
          console.log('mode', mode);
          createPatient
            .mutateAsync(processedData)
            .then(({ data: { id } }) => {
              resolve();

              enqueueSnackbar('Patient created successfully', {
                variant: 'success',
              });

              navigate(`/patients/${id}`);
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to create patient', {
                variant: 'error',
              });
            });
        } else {
          if (!patientId) {
            reject(new Error('Patient ID is required'));

            enqueueSnackbar('Unknown error occurred', {
              variant: 'error',
            });

            return;
          }

          updatePatient
            .mutateAsync({ payload: data, id: patientId })
            .then(() => {
              resolve();

              enqueueSnackbar('Patient updated successfully', {
                variant: 'success',
              });

              navigate(`/patients/${processedData.id}`);
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to update patient', {
                variant: 'error',
              });
            });
        }
      }),
    [
      createPatient,
      mode,
      navigate,
      patientId,
      updatePatient,
      sampleGridData,
      hpoGridData,
    ],
  );

  // const onSubmit: SubmitHandler<PatientSchema> = (data) => {
  //   console.log(data);
  //   const processedData = processPatientData(data, sampleGridData, hpoGridData);
  //   console.log(processedData);
  // };
  return onSubmit;
};
