import { type Control, useWatch } from 'react-hook-form';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { type BatchSchema, type ProjectSchema } from '@/zod_schemas';

interface ProjectHeaderProps {
  control: Control<BatchSchema | ProjectSchema>; // Use union type here
  isSubmitting: boolean;
  project_type?: string;
  btn_name?: string;
}

const ProjectHeader = ({
  control,
  isSubmitting,
  project_type,
  btn_name,
}: ProjectHeaderProps) => {
  const name = useWatch({
    control: control as Control<BatchSchema | ProjectSchema>, // Use union type here
    name: 'name',
  });

  const projectType = project_type ? 'New Batch' : 'New Project';
  const btnName = btn_name || 'Save';

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        sx={{ display: 'flex' }}
      >
        <Typography variant="h5">{name || projectType}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}
        sx={{ display: 'flex' }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="small"
        >
          {isSubmitting ? <CircularProgress size={25} /> : btnName}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectHeader;
