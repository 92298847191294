import { useCallback } from 'react';

import { ListItemButton, Typography } from '@mui/material';

import {
  useHandleRowClick,
  useHandleDownloadUserFile,
} from '@components/FileBrowser/Components/hooks';
import { useGetUserFiles } from '@api/fileHandling/fileHandling';
import { useFileBrowserLocation } from '@components/FileBrowser/hooks';
import {
  FileBrowserBackButton,
  FileNameAndIconContainer,
  List,
} from '@components/FileBrowser/StyledComponents';
import NoFiles from '@components/FileBrowser/Components/NoFiles';
import { FileBrowserRow } from '@components/FileBrowser/Components/FileBrowserRow';

const DUMMY_FILE_NAME = '.directory';

interface IFileBrowserFileListProps {
  onSelectFiles?: (files: string[]) => void;
  selectMultiple?: boolean;
}

export const FileBrowserFileList = ({
  onSelectFiles,
  selectMultiple,
}: IFileBrowserFileListProps) => {
  const { setLocation, location } = useFileBrowserLocation();
  const { data } = useGetUserFiles({ location });
  const handleDownload = useHandleDownloadUserFile();
  const { selectedFiles, handleClickRow } = useHandleRowClick({
    onSelectFiles,
    selectMultiple,
  });

  const handleClickBack = useCallback(() => {
    const locationLength = location.length;
    const parentLevel = location
      .substring(0, locationLength - 1)
      .lastIndexOf('/');

    setLocation(location.slice(0, parentLevel + 1));
  }, [setLocation, location]);

  return (
    <>
      {data.length === 0 && <NoFiles />}
      {data.length > 0 && (
        <List dense disablePadding>
          {location !== '/' && (
            <ListItemButton
              sx={{ paddingLeft: 1 }}
              key="back"
              disableGutters
              onClick={() => handleClickBack()}
            >
              <FileNameAndIconContainer>
                <FileBrowserBackButton />
                <Typography variant="caption">...</Typography>
              </FileNameAndIconContainer>
            </ListItemButton>
          )}
          {data
            .filter(
              ({ user_file_url }) => !user_file_url.includes(DUMMY_FILE_NAME),
            )
            .map(
              ({ user_file_url, created_at, is_directory, file_size, id }) => (
                <FileBrowserRow
                  key={id || user_file_url}
                  userFileUrl={user_file_url}
                  createdAt={created_at}
                  fileSize={file_size}
                  isDirectory={is_directory}
                  handleClick={handleClickRow}
                  handleDownload={() => !is_directory && handleDownload(id)}
                  selected={
                    !is_directory &&
                    selectedFiles.has(`${location}${user_file_url}`)
                  }
                />
              ),
            )}
        </List>
      )}
    </>
  );
};
