import { useState } from 'react';
import { Button, Fade, Menu, MenuItem } from '@mui/material';
import CustomDialog from '@/components/Dialog/CustomDialog';
import SampleDataList from './SampleDataList';
import { SampleSchema } from '@/zod_schemas';
import SampleDataCount from './SampleDataCount';

interface BtnSelectSampleProps {
  handleAddSampleClick: (sampleCount: number) => void;
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
}

const BtnSelectSample = ({
  handleAddSampleClick,
  setSampleGridData,
}: BtnSelectSampleProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<'count' | 'select'>('count');
  const [sampleCount, setSampleCount] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = () => {
    setDialogType('select');
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleCreate = () => {
    setDialogType('count');
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = () => {
    if (dialogType === 'count') {
      handleAddSampleClick(sampleCount);
    } else if (dialogType === 'select') {
      // Logic for when dialogSelect is true
      console.log('Submit for Select Sample');
    }
    setDialogOpen(false);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default form submission
      handleDialogSubmit();
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        id="fade-button"
        aria-controls={menuOpen ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        Add Sample
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSelect}>Select Sample</MenuItem>
        <MenuItem onClick={handleCreate}>Create Sample</MenuItem>
      </Menu>
      <CustomDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        title="Samples"
      >
        {dialogType === 'count' && (
          <SampleDataCount
            setSampleCount={setSampleCount}
            sampleCount={sampleCount}
            handleKeyDown={handleKeyDown}
          />
        )}
        {dialogType === 'select' && (
          <SampleDataList
            setSampleGridData={setSampleGridData}
            setDialogOpen={setDialogOpen}
          />
        )}
      </CustomDialog>
    </div>
  );
};

export default BtnSelectSample;
