import { IconButton, lighten, Paper, styled } from '@mui/material';

export const SectionHeader = styled('div')(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.main, 0.2),
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SectionContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const RemoveExperimentIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export const SectionContainer = styled(Paper)(() => ({}));
