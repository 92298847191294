import {
  type Control,
  type FieldArrayWithId,
  type FieldErrors,
  type Path,
} from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Stack } from '@mui/material';

import HelperTextContainer from '@components/HelperTextContainer';
import FileBrowserTextField from '@components/FormFields/FileBrowserTextField';
import FormSelect from '@components/FormFields/FormSelect';
import { type PatientSchema } from '@/zod_schemas';

interface IFilesContainerProps {
  control: Control<PatientSchema>;
  errors: FieldErrors<PatientSchema>;
  sampleIndex: number;
  fields: FieldArrayWithId<PatientSchema>[];
}

const FilesContainer = ({
  sampleIndex,
  control,
  errors,
  fields,
}: IFilesContainerProps) => {
  const filesPath: Path<PatientSchema> = `samples.${sampleIndex}.files`;
  const fileErrors = errors.samples?.[sampleIndex]?.files;

  return (
    <Stack spacing={3}>
      {fields.map(({ id }, fileIndex) => (
        <Stack direction="row" spacing={3} key={id}>
          <FormControl
            size="small"
            variant="standard"
            sx={{ minWidth: '12rem' }}
          >
            <InputLabel
              id={`file-type-${fileIndex}-label`}
              htmlFor={`file-type-${fileIndex}`}
            >
              File Type
            </InputLabel>
            <FormSelect
              controllerProps={{
                control,
                name: `${filesPath}.${fileIndex}.file_type`,
              }}
              selectProps={{
                labelId: `file-type-${fileIndex}-label`,
                id: `file-type-${fileIndex}`,
                'aria-describedby': `file-type-${fileIndex}-label`,
              }}
            >
              <MenuItem value="fastq_forward">FASTQ Forward</MenuItem>
              <MenuItem value="fastq_reverse">FASTQ Reverse</MenuItem>
              <MenuItem value="bam">BAM</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              <span>{fileErrors?.[fileIndex]?.file_type?.message}</span>
            </HelperTextContainer>
          </FormControl>
          <FormControl fullWidth key={id}>
            <InputLabel htmlFor={`file-${fileIndex}`}>
              File {fileIndex + 1}
            </InputLabel>
            <FileBrowserTextField
              inputProps={{
                id: `file-${fileIndex}`,
                type: 'text',
                size: 'small',
                fullWidth: true,
                'aria-describedby': `file-${fileIndex}`,
              }}
              controllerProps={{
                control,
                name: `${filesPath}.${fileIndex}.file_path`,
              }}
            />
            <HelperTextContainer>
              <span>{fileErrors?.[fileIndex]?.file_path?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
      ))}
    </Stack>
  );
};

export default FilesContainer;
