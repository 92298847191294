export const boxSx = {
  p: '5px 30px 30px',
  mx: 'auto',
  border: '1px solid #ccc',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
};

export const patientBoxSx = {
  p: '5px 30px 30px',
  mx: 'auto',
  border: '1px solid #ccc',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
};
