import { Paper, styled } from '@mui/material';

export const SampleContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  display: 'flex',
  padding: theme.spacing(2),
  alignItems: 'center',
  marginTop: theme.spacing(3),
  columnGap: theme.spacing(1),
}));
