import { useCallback, useEffect, useState } from 'react';

import PubSub from 'pubsub-js';
import { Card, CardContent, LinearProgress, Typography } from '@mui/material';

import { FileUploadPubSubPayload } from '@components/FileBrowser/utils/fileUpload/types';

interface IUploadCardProps {
  id: string;
  location: string;
}

const getCardVariant = (error: boolean, complete: boolean) => {
  if (error) {
    return 'error';
  }

  if (complete) {
    return 'success';
  }

  return 'default';
};

const UploadCard = ({ id, location }: IUploadCardProps) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<undefined | string>();
  const [complete, setComplete] = useState(false);

  const cardVariant = getCardVariant(!!error, complete);

  const handleReceiveUpdate = useCallback(
    (_id: string, data: FileUploadPubSubPayload) => {
      if (data.type === 'progress') {
        setProgress(data.body);
        return;
      }

      if (data.type === 'error') {
        setError(data.body);
        return;
      }

      if (data.type === 'complete') {
        setComplete(true);
      }
    },
    [],
  );

  useEffect(() => {
    const subscriptionToken = PubSub.subscribe(
      `upload-${id}`,
      handleReceiveUpdate,
    );

    return () => {
      PubSub.unsubscribe(subscriptionToken);
    };
  }, [handleReceiveUpdate, id]);

  return (
    <Card variant={cardVariant}>
      <CardContent>
        <Typography variant="subtitle2" gutterBottom>
          {location}
        </Typography>
        <div>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="caption">{progress.toFixed()}%</Typography>
          {error && (
            <Typography variant="caption" sx={{ float: 'right' }}>
              {error}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default UploadCard;
