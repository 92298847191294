import axios from 'axios';

if (!import.meta.env.VITE_API_BASE_URL) throw Error('API_BASE_URL is required');

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
});

export const { get, post, put, patch, delete: del } = httpClient;
