import { Box, Grid, styled } from '@mui/material';

export const RegisterContainer = styled(Grid)(() => ({
  height: '100vh',
  display: 'flex',
}));

export const RegisterBanner = styled(Box)(() => ({
  height: '100%',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundColor: 'cornflowerblue',
  backgroundBlendMode: 'multiply',
  position: 'relative',
  '& .logo': {
    width: '10rem',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    mixBlendMode: 'color-dodge',
  },
}));

export const RegisterFormHeader = styled(Box)(() => ({
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  '& .MuiTypography-overline': {
    color: 'gray',
  },
  '& img': {
    width: '10rem',
  },
}));

export const StyledRegisterForm = styled('form')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
