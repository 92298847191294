import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { get, patch, post } from '@api/httpClient';
import {
  PermissionContentTypeSchema,
  PermissionSchema,
  PermissionType,
} from '@/zod_schemas';
import { APIError, ResourceWithId } from '../types';
import queryClient from '../queryClient';

export const QUERY_KEYS_ALL = {
  PERMISSIONS: ['permissions'],
};

export const useGetPermissions = () =>
  useSuspenseQuery<PermissionSchema[]>({
    queryKey: QUERY_KEYS_ALL.PERMISSIONS,
    queryFn: async () => {
      const resp = await get('/permission_group/');
      return resp.data;
    },
  });

export const QUERY_KEYS_BY_ID = {
  PERMISSION: (permissionId: string) => ['permissions', { id: permissionId }],
};

export const useGetPermissionByID = (permissionId?: string) =>
  useSuspenseQuery<PermissionSchema>({
    queryKey: QUERY_KEYS_BY_ID.PERMISSION(permissionId || ''),
    queryFn: async () => {
      if (!permissionId) return {};
      const resp = await get(`/permission_group/${permissionId}/`);
      return resp.data;
    },
  });

export const QUERY_KEYS_CONTENT_TYPE = {
  PERMISSIONS_CONTENT_TYPE: ['permissions_content_type'],
};

export const useGetPermissionContent = () =>
  useSuspenseQuery<PermissionContentTypeSchema[]>({
    queryKey: QUERY_KEYS_CONTENT_TYPE.PERMISSIONS_CONTENT_TYPE,
    queryFn: async () => {
      const resp = await get('/permission/by-content-type/');
      return resp.data;
    },
  });

export const QUERY_KEYS_PERMISSION_TYPE = {
  PERMISSIONS_TYPE: ['permissions_type'],
};

export const useGetPermissionsType = () =>
  useSuspenseQuery<PermissionType[]>({
    queryKey: QUERY_KEYS_PERMISSION_TYPE.PERMISSIONS_TYPE,
    queryFn: async () => {
      const resp = await get('/permission/');
      return resp.data;
    },
  });

export const useCreateGroupPermission = () =>
  useMutation<AxiosResponse<PermissionSchema>, APIError, PermissionSchema>({
    mutationFn: (permission_group) =>
      post<PermissionSchema>('/permission_group/', { ...permission_group }),
  });

const QUERY_KEYS_PATCH = {
  PATCH_PERMISSION: ['patch_permission'],
};

export const usePatchPermission = () =>
  useMutation<
    AxiosResponse<PermissionSchema>,
    APIError,
    ResourceWithId<PermissionSchema>
  >({
    mutationFn: ({ id, payload }) =>
      patch<PermissionSchema>(`/permission_group/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS_PATCH.PATCH_PERMISSION,
      });
    },
  });
