import { useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  type Control,
  Controller,
  type UseFormRegister,
  type FieldErrors,
  type UseFormSetValue,
} from 'react-hook-form';
import HelperTextContainer from '@/components/HelperTextContainer';
import { type PatientSchema } from '@/zod_schemas';
import { genderLists } from '../Forms/defaults';
import { boxSx } from '@/styles/globalStyles';
import { useGetEthnicity } from '@/api/ethnicity';

interface PatientInformationFormProps {
  control: Control<PatientSchema>;
  register: UseFormRegister<PatientSchema>;
  errors: FieldErrors<PatientSchema>;
  setValue: UseFormSetValue<PatientSchema>;
}

const PatientInformationForm = ({
  control,
  register,
  errors,
}: PatientInformationFormProps) => {
  const [, setSelectedEthnicities] = useState<string[]>([]);

  const { data } = useGetEthnicity();

  const ethnicityData = data.map((item) => item.name);

  return (
    <Box sx={boxSx}>
      <Stack direction="column" spacing={3}>
        <Typography variant="h6" gutterBottom>
          PATIENT INFORMATION
        </Typography>
        <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
          <FormControl fullWidth margin="normal">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="NAME"
                  variant="outlined"
                  {...register('name')}
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
              )}
            />
            <HelperTextContainer>
              <span>{errors.name?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
        <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
          <FormControl fullWidth margin="normal">
            <Controller
              name="identification"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IDENTIFICATION (IC/PASSPORT)"
                  variant="outlined"
                  {...register('identification')}
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
              )}
            />
            <HelperTextContainer>
              <span>{errors.identification?.message}</span>
            </HelperTextContainer>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Controller
              name="date_of_birth"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  label="DATE OF BIRTH"
                  {...register('date_of_birth')}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
              )}
            />
            <HelperTextContainer>
              <span>{errors.date_of_birth?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
        <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
          <FormControl fullWidth>
            <Controller
              name="ethnicity"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="ethnicity"
                  options={ethnicityData}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={
                    ethnicityData.filter(
                      (option) => field.value?.includes(option),
                    ) || []
                  }
                  onChange={(_, dataC) => {
                    setSelectedEthnicities(dataC);
                    field.onChange(dataC); // Pass the array of selected objects to field.onChange
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: '#fff',
                      }}
                      label="ETHNICITY"
                      placeholder="Ethnicity"
                    />
                  )}
                />
              )}
            />

            <HelperTextContainer>
              <span>
                {errors?.ethnicity?.length !== undefined &&
                errors?.ethnicity?.length > 1
                  ? 'Error'
                  : errors.ethnicity?.message}
              </span>
            </HelperTextContainer>
          </FormControl>
          <FormControl fullWidth size="small" variant="standard">
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={genderLists}
                  getOptionLabel={(option) => option}
                  value={
                    genderLists.find(
                      (option) => option.toLowerCase() === value,
                    ) || null
                  }
                  onChange={(_event, newValue) => {
                    onChange(newValue ? newValue.toLowerCase() : undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        backgroundColor: '#fff',
                      }}
                      variant="outlined"
                      {...params}
                      label="GENDER"
                    />
                  )}
                />
              )}
            />
            <HelperTextContainer>
              <span>{errors.gender?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PatientInformationForm;
