import { styled, Typography } from '@mui/material';

import AddFiles from '@assets/add-files.svg';

const NoFilesContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '& img': {
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

const NoFiles = () => (
  <NoFilesContainer>
    <img src={AddFiles} alt="No files" width="200px" height="200px" />
    <Typography variant="h5" align="center">
      No files
    </Typography>
    <Typography variant="subtitle2" align="center">
      Drag and Drop files or add files by clicking the button above
    </Typography>
  </NoFilesContainer>
);

export default NoFiles;
