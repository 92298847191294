import { ExperimentSchema, type SampleSchema } from '@/zod_schemas';

export const defaultCustomSample: SampleSchema = {
  sample_id: '',
  files: [
    {
      file_path: '/local-test',
      file_type: 'bam',
    },
  ],
  sample_type: 'bam',
  gender: 'unknown',
};

export const defaultSample: SampleSchema = {
  sample_id: '',
  sample_type: 'bam',
  gender: 'male',
  files: [
    {
      file_path: '/local-file1',
      file_type: 'bam',
    },
  ],
};

export const defaultDuoSample: SampleSchema[] = [
  {
    sample_id: '',
    sample_type: 'bam',
    gender: 'male',
    files: [
      {
        file_path: '/local-file1',
        file_type: 'bam',
      },
      {
        file_path: '/local-file2',
        file_type: 'bam',
      },
    ],
  },
];

export const defaultSingleSample: SampleSchema = {
  sample_id: '',
  sample_type: 'bam',
  gender: 'male',
  files: [
    {
      file_path: '',
      file_type: 'bam',
    },
  ],
};

export const defaultTrioSample: SampleSchema[] = [
  {
    sample_id: '',
    sample_type: 'bam',
    gender: 'male',
    files: [
      {
        file_path: '',
        file_type: 'bam',
      },
    ],
  },
  {
    sample_id: '',
    sample_type: 'bam',
    gender: 'male',
    files: [
      {
        file_path: '',
        file_type: 'bam',
      },
    ],
  },
  {
    sample_id: '',
    sample_type: 'bam',
    gender: 'male',
    files: [
      {
        file_path: '',
        file_type: 'bam',
      },
    ],
  },
];

export const defaultExperiment: ExperimentSchema = {
  experiment_type: 'custom',
  pipeline_configs: [],
  description: '',
  name: '',
  samples: [
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
  ],
};

export const singleExperiment: ExperimentSchema = {
  experiment_type: 'single',
  pipeline_configs: [],
  description: '',
  name: '',
  samples: [
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
  ],
};

export const duoExperiment: ExperimentSchema = {
  experiment_type: 'custom',
  pipeline_configs: [],
  description: '',
  name: '',
  samples: [
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
  ],
};

export const trioExperiment: ExperimentSchema = {
  experiment_type: 'custom',
  pipeline_configs: [],
  description: '',
  name: '',
  samples: [
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
    {
      sample_id: '',
      files: [
        {
          file_path: '',
          file_type: 'bam',
        },
      ],
      sample_type: 'bam',
      gender: 'unknown',
    },
  ],
};
