import { Search } from 'lucide-react';
import { ChangeEvent, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
// import { SelectChangeEvent } from '@mui/material/Select';
import { DefaultSearchField } from '@/components/DashboardShell/StyledComponents';

interface HPOFormControlsProps {
  // searchCategory: string;
  searchQuery: string;
  inputRef: React.RefObject<HTMLInputElement>;
  focusSearchField: () => void;
  // handleCategoryChange: (event: SelectChangeEvent<string>) => void;
  handleSearchChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleSearchClick: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

const HPOFormControls = ({
  // searchCategory,
  // handleCategoryChange,
  searchQuery,
  handleSearchChange,
  handleSearchClick,
  focusSearchField,
  inputRef,
}: HPOFormControlsProps) => {
  useEffect(() => {
    focusSearchField();
  }, [focusSearchField]);
  return (
    <Box sx={{ padding: '10px 0' }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'flex-end', marginTop: '10px' }}
      >
        {/* <FormControl variant="outlined" fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            value={searchCategory}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value="term">Term</MenuItem>
            <MenuItem value="genes">Genes</MenuItem>
            <MenuItem value="disease">Disease</MenuItem>
          </Select>
        </FormControl> */}

        <DefaultSearchField
          id="search-hpo"
          variant="outlined"
          placeholder="Search"
          onKeyDown={(e) => e.key === 'Enter' && handleSearchClick(e)}
          fullWidth
          value={searchQuery}
          inputRef={inputRef}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <Search
                style={{
                  paddingRight: '0.5rem',
                }}
              />
            ),
          }}
        />
      </Stack>
    </Box>
  );
};

export default HPOFormControls;
