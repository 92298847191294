import { styled, Typography } from '@mui/material';

import AddFiles from '@assets/add-files.svg';

const DropHereContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  '& img': {
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

const DropHere = () => (
  <DropHereContainer>
    <img
      src={AddFiles}
      alt="Drop your files here"
      width="200px"
      height="200px"
    />
    <Typography variant="h5" align="center">
      Go ahead, drop it here!
    </Typography>
  </DropHereContainer>
);

export default DropHere;
