export const getFileBrowserParentPath = (path: string) => {
  let parentPath = `${path}`;

  // check if last char is a slash
  if (parentPath[parentPath.length - 1] === '/') {
    parentPath = parentPath.slice(0, -1);
  }

  const splitPath = parentPath.split('/');

  if (splitPath.length === 2) {
    // example: /testing
    return '/';
  }

  // example: /testing/123
  // example: /testing/123/456
  const parentDir = splitPath.slice(0, -1).join('/');

  return `${parentDir}/`;
};

export const validateDirectoryName = (name: string) => {
  const regex = /^[^*?"<>|:\\/]+$/;

  return regex.test(name);
};
