import { type Control, useWatch } from 'react-hook-form';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { type PatientSchema } from '@/zod_schemas';

interface PatientHeaderProps {
  control: Control<PatientSchema>;
  isSubmitting: boolean;
}

const PatientHeader = ({ control, isSubmitting }: PatientHeaderProps) => {
  const sampleID = useWatch({
    control,
    name: 'name',
  });

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}
        sx={{ display: 'flex' }}
      >
        <Typography variant="h5">{sampleID || 'New Patient'}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{
          xs: 'center',
          sm: 'flex-end',
        }}
        sx={{ display: 'flex' }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="small"
        >
          {isSubmitting ? <CircularProgress size={25} /> : 'Save'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PatientHeader;
