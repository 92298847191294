import { type Control, useWatch } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';

import { type ProjectEditSchema } from '@/zod_schemas';

interface ProjectEditHeaderProps {
  control: Control<ProjectEditSchema>; // Use union type here
}

const ProjectEditHeader = ({ control }: ProjectEditHeaderProps) => {
  const name = useWatch({
    control: control as Control<ProjectEditSchema>, // Use union type here
    name: 'name',
  });

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        sx={{ display: 'flex' }}
      >
        <Typography variant="h5">{name || 'New Project'}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}
        sx={{ display: 'flex' }}
      >
        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="small"
        >
          {isSubmitting ? <CircularProgress size={25} /> : btnName}
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default ProjectEditHeader;
