import {
  Drawer as MUIDrawer,
  Toolbar as MUIToolbar,
  styled,
  TextField,
} from '@mui/material';

const DRAWER_WIDTH = '18rem';

export const Drawer = styled(MUIDrawer)(() => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: DRAWER_WIDTH,
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.75)',
  },
}));

export const Toolbar = styled(MUIToolbar)(() => ({
  height: '10rem',
}));

export const LogoContainer = styled('div')(() => ({
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1rem',
  alignItems: 'center',
  '& h3': {
    background: 'linear-gradient(to right, #009fff, #ec2f4b)',
    letterSpacing: '0.3rem',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& img': {
    width: '6rem',
  },
}));

export const ContentHeaderContainer = styled('div')(({ theme }) => ({
  width: `calc(100% - ${DRAWER_WIDTH})`,
  marginLeft: DRAWER_WIDTH,
  position: 'fixed',
  background: theme.palette.background.dashboardMainContent,
  zIndex: 50,
  padding: '1rem',
}));

export const MainContentContainer = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: 3,
  background: theme.palette.background.dashboardMainContent,
  minHeight: '100vh',
  marginLeft: DRAWER_WIDTH,
  zIndex: 49,
  display: 'flex',
  flexDirection: 'column',
}));

export const SectionListContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const SearchField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.paper,
  marginLeft: '2rem',
  width: '30rem',
  borderRadius: '2rem',
  '& .MuiOutlinedInput-root': {
    borderRadius: '2rem',
  },
}));

export const DefaultSearchField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.paper,
  marginLeft: '2rem',
  width: '30rem',
  borderRadius: '2rem',
  '& .MuiOutlinedInput-root': {
    borderRadius: '2rem',
  },
}));

export const AvatarContainer = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
}));
