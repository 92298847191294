import { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl, OutlinedInput } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import {
  type EmailValidationSchema,
  emailValidationSchema,
} from '@/zod_schemas';
import { useHandleResetPassword } from './hooks';
import HelperTextContainer from '@/components/HelperTextContainer';

interface ResetPasswordProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
}

const ResetPassword = ({ open, handleClose, setOpen }: ResetPasswordProps) => {
  const {
    getValues,
    register,
    reset,
    formState: { errors },
    trigger,
  } = useForm<EmailValidationSchema>({
    resolver: zodResolver(emailValidationSchema),
    mode: 'onChange',
  });

  const handleRequestResetPassword = useHandleResetPassword();

  const handleResetPassword = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      // Trigger email validation before proceeding
      const isEmailValid = await trigger('email');

      if (!isEmailValid) {
        return; // Prevent submission if email is invalid
      }

      const email = getValues('email');

      await handleRequestResetPassword(email);
      setOpen(false);
    },
    [getValues, handleRequestResetPassword, setOpen, trigger],
  );

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleResetPassword,
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: '0 0 15px 0' }}>
          Enter your account&apos;s email address, and we&apos;ll send you a
          link to reset your password.
        </DialogContentText>
        <FormControl fullWidth key="reset-password-fields">
          <OutlinedInput
            autoFocus
            required
            margin="dense"
            id="email"
            label="Email address"
            placeholder="Email address"
            type="email"
            fullWidth
            autoComplete="email" // Add autoComplete attribute for better browser support
            {...register('email', { required: true })}
          />
          <HelperTextContainer>
            <span>{errors.email?.message}</span>
          </HelperTextContainer>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="submit">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPassword;
