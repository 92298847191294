import queryClient from '@api/queryClient';
import {
  createUploadPartMutationFn,
  finishUploadPartMutationFn,
  finishMultipartUploadMutationFn,
  startMultipartUploadMutationFn,
  startBasicUploadMutationFn,
  finishBasicUploadMutationFn,
} from '@api/fileHandling/queryFunctions';
import {
  CreateUploadPartRequest,
  FinishBasicUploadRequest,
  FinishMultipartUploadRequest,
  FinishUploadPartRequest,
  StartBasicUploadRequest,
  StartMultipartUploadRequest,
} from '@api/fileHandling/types';

export const startMultipartUpload = ({
  ...args
}: StartMultipartUploadRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: startMultipartUploadMutationFn,
    })
    .execute({ ...args });

export const createMultipartUploadPart = ({
  ...args
}: CreateUploadPartRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: createUploadPartMutationFn,
    })
    .execute({ ...args });

export const finishMultipartUploadPart = ({
  ...args
}: FinishUploadPartRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: finishUploadPartMutationFn,
    })
    .execute({ ...args });

export const finishMultipartUpload = ({
  ...args
}: FinishMultipartUploadRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: finishMultipartUploadMutationFn,
    })
    .execute({ ...args });

export const startBasicUpload = ({ ...args }: StartBasicUploadRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: startBasicUploadMutationFn,
    })
    .execute({ ...args });

export const finishBasicUpload = ({ ...args }: FinishBasicUploadRequest) =>
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: finishBasicUploadMutationFn,
    })
    .execute({ ...args });
