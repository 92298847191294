import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { getSampleColumns } from './sample/getSampleColumns';
import { SampleSchema } from '@/zod_schemas';
import { useListSamples } from '@/api/sample';
import { usePatientContext } from '../PatientContext';

interface SampleDataListProps {
  setSampleGridData: React.Dispatch<React.SetStateAction<SampleSchema[]>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SampleDataList = ({
  setSampleGridData,
  setDialogOpen,
}: SampleDataListProps) => {
  const { mode, patientId } = usePatientContext(); // Access context values
  const columns = getSampleColumns({
    selectAction: true,
    deleteAction: true,
    setSampleGridData,
    setDialogOpen,
    formMode: mode,
    formPatientId: patientId,
  });
  const { data: sampleListData } = useListSamples();
  const [sampleRows2] = useState<SampleSchema[]>(sampleListData);

  return (
    <Box>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent={{
          xs: 'center',
          sm: 'flex-end',
        }}
        sx={{ display: 'flex', padding: '50px 0 10px 0' }}
      />
      <DataGrid
        rows={sampleRows2.map((row, index) => ({
          id: index.toString(),
          ...row,
        }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{ height: 400, minWidth: 1200 }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default SampleDataList;
