import { Suspense, useCallback } from 'react';

import { PlusIcon } from 'lucide-react';
import { useFieldArray } from 'react-hook-form';
import { Stack } from '@mui/material';

import {
  AddExperimentButton,
  ProjectFormElement,
} from '@pages/common/Project/StyledComponents';
import ProjectForm from '@pages/common/Project/Forms/ProjectForm';
import HelperTextContainer from '@components/HelperTextContainer';
import { defaultExperiment } from '@pages/common/Project/Forms/defaults';
import ProjectHeader from '@pages/common/Project/Components/ProjectHeader';
import {
  useCreateOrUpdateProject,
  useProjectFormHandler,
} from '@pages/common/Project/hooks';
import ExperimentsContainer from '@pages/common/Project/Forms/Components/ExperimentContainer';
import { type ExperimentTypeSchema } from '@/zod_schemas';

const Project = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    mode,
    projectId,
    formState: { errors, isSubmitting },
  } = useProjectFormHandler();

  const onSubmit = useCreateOrUpdateProject(mode, projectId);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'experiments',
  });

  const changeExperimentType = useCallback(
    (type: ExperimentTypeSchema, index: number) => {
      setValue(`experiments.${index}.experiment_type`, type);
    },
    [setValue],
  );

  return (
    <ProjectFormElement onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" value="default" {...register('project_type')} />
      <ProjectHeader control={control} isSubmitting={isSubmitting} />
      <ProjectForm register={register} errors={errors} control={control} />
      <Stack spacing={1} direction="row" alignItems="center">
        <AddExperimentButton
          variant="contained"
          color="primary"
          startIcon={<PlusIcon />}
          size="small"
          onClick={() => append(defaultExperiment)}
        >
          Add Experiment
        </AddExperimentButton>
        <HelperTextContainer>
          <span>
            {errors.experiments?.root && errors.experiments.root.message}
            {errors.experiments && errors.experiments.message}
          </span>
        </HelperTextContainer>
      </Stack>
      <ExperimentsContainer
        register={register}
        control={control}
        setValue={setValue}
        errors={errors}
        changeExperimentType={changeExperimentType}
        removeExperiment={(index: number) => remove(index)}
        fields={fields}
        mode={mode}
      />
    </ProjectFormElement>
  );
};

const ProjectWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Project />
  </Suspense>
);

export default ProjectWithSuspense;
