import { useContext } from 'react';
import { PipelineFormContext } from '@pages/common/Administrator/Pipeline/PipelineFormContext';

// Custom hook to use the PipelineFormContext
export const usePipelineFormContext = () => {
  const context = useContext(PipelineFormContext);
  if (!context) {
    throw new Error(
      'usePipelineFormContext must be used within a PipelineFormProvider',
    );
  }
  return context;
};

export default usePipelineFormContext;
