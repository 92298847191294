import { Suspense, useState } from 'react';
import { Grid } from '@mui/material';
import SampleSearch from './Components/SampleSearch';
import SampleResults from './Components/SampleResults';
import { useGetExperimentsBySample } from '@/api/sample/sample';
import { SampleResultSchema } from '@/zod_schemas';
import { SamplesType } from '@/api/project/types';

const Samples = () => {
  // const {
  //   handleSubmit,
  //   register,
  //   control,
  //   formState: { errors, isSubmitting },
  // } = useSampleFormHandler();

  const [triggerSearch, setTriggerSearch] = useState(false);
  const [resultData, setResultData] = useState<SampleResultSchema[]>([]);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [searchFields, setSearchFields] = useState<SamplesType>({
    id: '',
    name: '',
    date: '',
  });

  // // Pass `triggerSearch` to `enabled`
  const { refetch } = useGetExperimentsBySample(searchFields, triggerSearch);

  const handleSearch = async () => {
    setTriggerSearch(true); // Enables the query on button click
    setSearchCompleted(false); // Reset search completed state on new search

    // Use the result of refetch to ensure data is up-to-date
    const result = await refetch();

    // Update resultData once refetch completes
    setResultData(result.data || []);
    setSearchCompleted(true); // Mark search as completed
    setTriggerSearch(false); // Reset enabled to prevent auto re-triggering
  };

  const clearSearch = () => {
    setResultData([]);
    setSearchFields({ id: '', name: '', date: '' });
    setSearchCompleted(false); // Reset search completed state on clear
  };

  return (
    <Grid container>
      <Grid item md={12} lg={12} xl={12}>
        <Suspense fallback={<div>Loading...</div>}>
          <SampleSearch
            searchFields={searchFields}
            setSearchFields={setSearchFields}
            handleSearch={handleSearch}
            clearSearch={clearSearch}
          />
        </Suspense>
      </Grid>
      <Grid md={12} lg={12} xl={12} item>
        <SampleResults
          resultData={resultData}
          searchCompleted={searchCompleted}
        />
      </Grid>
    </Grid>
  );
};

export default Samples;
