import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import LoginOtherFields from '@pages/common/Login/LoginOtherFields';
import LoginOTPField from '@pages/common/Login/LoginOTPField';
import {
  Form,
  LoginFormBottomContainer,
} from '@pages/common/Login/StyledComponents';
import HelperTextContainer from '@components/HelperTextContainer';
import { useHandleLogin } from '@pages/common/Login/hooks';
import { loginSchema, type LoginSchema } from '@/zod_schemas';
import ResetPassword from './ResetPassword';

const LoginForm = () => {
  const navigate = useNavigate();
  const handleLogin = useHandleLogin();
  const { ...formMethods } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleClickOpen = () => setOpen(true);

  return (
    <FormProvider {...formMethods}>
      <Typography
        variant="overline"
        sx={{ fontSize: 'larger', display: 'flex', justifyContent: 'center' }}
        gutterBottom
      >
        Login to Synapsys
      </Typography>
      <Form onSubmit={formMethods.handleSubmit(handleLogin)}>
        <LoginOtherFields />
        <LoginOTPField />
        <Button type="submit" variant="contained">
          Login
        </Button>
        <LoginFormBottomContainer>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                id="remember-me"
                {...formMethods.register('remember', { required: false })}
                aria-describedby="remember field"
                size="small"
              />
            }
            label="Remember Me"
          />
          <HelperTextContainer id="remember-helper-text">
            {formMethods.formState.errors.remember && (
              <span>{formMethods.formState.errors.remember.message}</span>
            )}
          </HelperTextContainer>
          <Stack direction="column" spacing={1}>
            <Button
              onClick={() => navigate('/register')}
              variant="text"
              sx={{
                textTransform: 'none',
                padding: 0,
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                },
              }}
            >
              Create an account
            </Button>
            <Button
              onClick={handleClickOpen}
              variant="text"
              sx={{
                textTransform: 'none',
                padding: 0,
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                },
              }}
            >
              Forgot password?
            </Button>
          </Stack>
          <ResetPassword
            setOpen={setOpen}
            open={open}
            handleClose={handleClose}
          />
        </LoginFormBottomContainer>
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
