import {
  useFieldArray,
  type Control,
  type FieldErrors,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormWatch,
} from 'react-hook-form';
import Section from '@pages/common/Project/Forms/Components/Section';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useCallback, useEffect, useRef, useState } from 'react';
import ExperimentsContainer from '@pages/common/Project/Forms/Components/ExperimentContainer';
import HelperTextContainer from '@/components/HelperTextContainer';
import { BatchSchema, ExperimentTypeSchema } from '@/zod_schemas';
import { singleExperiment, duoExperiment, trioExperiment } from './defaults';
import FileBrowserTextField from '@/components/FormFields/FileBrowserTextField';

interface BatchConfigFormProps {
  register: UseFormRegister<BatchSchema>;
  errors: FieldErrors<BatchSchema>;
  control: Control<BatchSchema>;
  setValue: UseFormSetValue<BatchSchema>;
  mode: 'create' | 'update';
  watch: UseFormWatch<BatchSchema>;
}

const BatchConfigForm = ({
  register,
  errors,
  control,
  setValue,
  mode,
}: BatchConfigFormProps) => {
  const [expType, setExpType] = useState('single');
  const [sampleCount, setSampleCount] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'experiments',
  });

  const handleAddBulkClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = () => {
    let appendPanel = singleExperiment;

    if (expType === 'duo') {
      appendPanel = duoExperiment;
    } else if (expType === 'trio') {
      appendPanel = trioExperiment;
    }
    for (let i = 0; i < sampleCount; i += 1) {
      append(appendPanel);
    }
    setDialogOpen(false);
  };

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  useEffect(() => {
    if (fields.length > 0) {
      inputRefs.current[fields.length - 1]?.focus();
    }
  }, [fields]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the default form submission
      handleDialogSubmit();
    }
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setExpType(event.target.value); // Update state on change
  };

  const changeExperimentType = useCallback(
    (type: ExperimentTypeSchema, index: number) => {
      setValue(`experiments.${index}.experiment_type`, type);
    },
    [setValue],
  );

  return (
    <Section title="Batch Config">
      <Stack spacing={4}>
        <Stack spacing={4} direction="row">
          <FormControl fullWidth>
            <InputLabel htmlFor="project-batch-name">Batch Name</InputLabel>
            <Input
              id="project-batch-name"
              type="text"
              aria-describedby="project batch name field"
              size="medium"
              fullWidth
              {...register('name')}
            />
            <HelperTextContainer>
              <span>{errors.name?.message}</span>
            </HelperTextContainer>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="batch-description">Description</InputLabel>
            <Input
              id="batch-description"
              type="text"
              aria-describedby="project batch deccription"
              size="medium"
              fullWidth
              {...register('description')}
            />
            <HelperTextContainer>
              <span>{errors.description?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>

        <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="file-bam">BAM File(Optional)</InputLabel>
            <FileBrowserTextField
              inputProps={{
                id: 'file-bam',
                type: 'text',
                size: 'small',
                fullWidth: true,
                'aria-describedby': 'file-bam',
              }}
              controllerProps={{
                control,
                name: 'bam_file',
              }}
            />
            <HelperTextContainer>
              <span>{errors.bam_file?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Stack>
        <Divider sx={{ opacity: '1', borderColor: 'black' }} />
        <HelperTextContainer>
          <span>{errors.experiments?.message}</span>
        </HelperTextContainer>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
        >
          <Button variant="contained" size="small" onClick={handleAddBulkClick}>
            Add Bulk
          </Button>
        </Stack>
        {fields.length !== 0 && (
          <Section title="List of samples">
            <ExperimentsContainer
              expType={expType}
              setValue={setValue}
              project_type="Sample"
              register={register}
              control={control}
              errors={errors}
              changeExperimentType={changeExperimentType}
              removeExperiment={(index: number) => remove(index)}
              fields={fields}
              mode={mode}
            />
          </Section>
        )}

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Add Samples</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <InputLabel id="sample-batch-count" htmlFor="sample-count">
                Sample Count
              </InputLabel>
              <Input
                id="sample-count"
                type="number"
                value={sampleCount}
                onChange={(e) => setSampleCount(Number(e.target.value))}
                inputProps={{ min: 1 }}
                onKeyDown={handleKeyDown}
              />
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel
                  id="experiment-type-label"
                  htmlFor="experiment-type"
                >
                  Type
                </InputLabel>
                <Select
                  labelId="experiment-type-label"
                  id="experiment-type"
                  value={expType} // Use the state value
                  onChange={handleTypeChange} // Handle change to update state
                >
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="duo">Duo</MenuItem>
                  <MenuItem value="trio">Trio</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSubmit}>Add</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Section>
  );
};

export default BatchConfigForm;
