import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { AccountFormElement } from './StyledComponents';
import ProfileForm from './ProfileForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const tabProps = (index: number) => ({
  id: `account-tab-${index}`,
  'aria-controls': `account-tabpanel-${index}`,
});
const AccountPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AccountFormElement>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Profile" {...tabProps(0)} />
          <Tab label="Settings" {...tabProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ProfileForm />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>second page</div>
      </CustomTabPanel>
    </AccountFormElement>
  );
};

export default AccountPage;
