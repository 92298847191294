import { Grid, Stack, Typography } from '@mui/material';
import { SampleResultSchema } from '@/zod_schemas';
import { formatDateResults } from '@/utils/dateUtils';

interface DetailsInformationProps {
  result: SampleResultSchema;
}

const DetailsInformation = ({ result }: DetailsInformationProps) => (
  <Stack
    direction="column"
    spacing={2}
    sx={{
      padding: '1rem',
      backgroundColor: '#f0f0f0',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
    }}
  >
    {/* Title Section */}
    <Grid item xs={12}>
      <Typography variant="h6" fontWeight="bold" align="center">
        {result.pipeline_config.name}
      </Typography>
    </Grid>

    {/* Sample ID Section */}
    {result.samples.map((sample) => (
      <Grid key={sample.id} item xs={12}>
        <Typography variant="subtitle1" textAlign="center" color="primary">
          Sample ID: {sample.sample_id}
        </Typography>
      </Grid>
    ))}

    {/* Date and Registered By Section */}
    <Grid item xs={12}>
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography variant="body2">
          <strong>Created:</strong> {formatDateResults(result.created_at)}
        </Typography>
        <Typography variant="body2">
          <strong>Registered By:</strong> {result.created_by.first_name}{' '}
          {result.created_by.last_name}
        </Typography>
      </Stack>
    </Grid>
  </Stack>
);

export default DetailsInformation;
