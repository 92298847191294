import {
  type Control,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import { FormControl, Input, InputLabel, Stack } from '@mui/material';

import HelperTextContainer from '@components/HelperTextContainer';
import Section from '@pages/common/Project/Forms/Components/Section';
import { type ProjectSchema } from '@/zod_schemas';

interface ProjectFormProps {
  register: UseFormRegister<ProjectSchema>;
  errors: FieldErrors<ProjectSchema>;
  control: Control<ProjectSchema>; // Use union type here
}

const ProjectForm = ({ register, errors }: ProjectFormProps) => (
  <Section title="Name and description">
    <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
      <FormControl fullWidth>
        <InputLabel htmlFor="project-name">Name</InputLabel>
        <Input
          id="project-name"
          type="text"
          aria-describedby="project name field"
          size="medium"
          fullWidth
          {...register('name')}
        />
        <HelperTextContainer>
          <span>{errors.name?.message}</span>
        </HelperTextContainer>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="project-description">Description</InputLabel>
        <Input
          id="project-description"
          type="text"
          aria-describedby="project description field"
          size="medium"
          fullWidth
          {...register('description')}
        />
        <HelperTextContainer>
          {errors?.description && <span>{errors.description.message}</span>}
        </HelperTextContainer>
      </FormControl>
    </Stack>
  </Section>
);

export default ProjectForm;
