import {
  Button,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
import Card from '@mui/material/Card';
import { deepOrange } from '@mui/material/colors';
import { useGetCurrentUser } from '@/api/auth/auth';

const ProfileForm = () => {
  const { data } = useGetCurrentUser();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card variant="default">
            <CardHeader
              title="Profile Picture"
              sx={{ backgroundColor: 'lightgrey' }}
            />
            <CardContent sx={{ textAlign: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: deepOrange[500],
                  width: 100,
                  height: 100,
                  margin: 'auto',
                  marginBottom: 2,
                }}
              >
                {data?.first_name.charAt(0)}
              </Avatar>
              <Typography variant="body2" color="textSecondary">
                JPG or PNG no larger than 5 MB
              </Typography>
              <Button variant="contained" sx={{ marginTop: 2 }}>
                Upload new image
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card variant="default">
            <CardHeader
              title="Account Details"
              sx={{ backgroundColor: 'lightgrey' }}
            />
            <CardContent>
              <form>
                <Stack spacing={3}>
                  <TextField
                    label="Email address"
                    id="inputEmailAddress"
                    type="email"
                    placeholder="Enter your email address"
                    defaultValue={data?.email}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Stack spacing={2} direction="row">
                    <TextField
                      label="First name"
                      id="inputFirstName"
                      placeholder="Enter your first name"
                      defaultValue={data?.first_name}
                      fullWidth
                    />
                    <TextField
                      label="Last name"
                      id="inputLastName"
                      placeholder="Enter your last name"
                      defaultValue={data?.last_name}
                      fullWidth
                    />
                  </Stack>
                  <Stack spacing={2} direction="row" />
                  <Stack spacing={2} direction="row" />
                  <Stack spacing={2} direction="row">
                    <TextField
                      label="Organization name"
                      id="inputOrgName"
                      placeholder="Enter your organization name"
                      defaultValue={data?.organization}
                      fullWidth
                    />
                    <TextField
                      label="Phone number"
                      id="inputPhone"
                      type="tel"
                      placeholder="Enter your phone number"
                      defaultValue={data?.pnumber}
                      fullWidth
                    />
                  </Stack>
                  <Button variant="contained" type="button">
                    Save changes
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileForm;
