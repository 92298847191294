import { type ReactNode } from 'react';

import { Typography } from '@mui/material';

import {
  SectionContainer,
  SectionContent,
  SectionHeader,
} from './StyledComponents';

interface ISectionProps {
  title: string;
  children: ReactNode;
  headerAction?: ReactNode;
}

const Section = ({ children, title, headerAction }: ISectionProps) => (
  <SectionContainer>
    <SectionHeader>
      <Typography variant="body1">{title}</Typography>
      {headerAction && headerAction}
    </SectionHeader>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
);
export default Section;
