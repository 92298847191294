import { Button, styled, Paper } from '@mui/material';

// const DRAWER_WIDTH = '18rem';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '12rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1.25rem',
  '&:hover': {
    transform: 'scale(1.05)',
    cursor: 'pointer',
  },
}));

export const DashboardButton = styled(Button)(() => ({
  width: '100%',
  size: 'small',
  textTransform: 'none',
  pointerEvents: 'none',
  color: '#28282B',
  marginTop: '0.6rem',
}));

export const DisabledButton = styled(DashboardButton)(() => ({
  disabled: 'true',
  background: 'linear-gradient(45deg, #E0E0E0 30%, #E0E0E0 90%)',
  color: 'lightgray',
  padding: 0,
  marginTop: 0,
  height: '100%',
}));

export const DashboardContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const DashboardContent = styled('div')(() => ({
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  padding: '3rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto',
  placeItems: 'center',
}));

export const ImageContainer = styled('div')(() => ({
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1rem',
  alignItems: 'center',
  '& h3': {
    background: 'linear-gradient(to right, #009fff, #ec2f4b)',
    letterSpacing: '0.3rem',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& img': {
    width: '4rem',
  },
}));

export const Disclaimer = styled('footer')(() => ({
  backgroundColor: 'aliceblue',
  bottom: 0,
  left: 0,
  width: '100%',
  right: 0,
  zIndex: 0,
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.1)',
  padding: '1rem',
  paddingRight: '3rem',
  fontSize: '0.8rem',
  textAlign: 'center',

  // backgroundColor: 'aliceblue',
  // padding: '1rem',
  // paddingRight: '3rem',
  // fontSize: '0.8rem',
  // textAlign: 'center',
  // boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.75)',
  // position: 'fixed',
  // bottom: 0,
  // left: 0,
  // right: 0,
  // zIndex: 0,
  // marginLeft: DRAWER_WIDTH,
}));
