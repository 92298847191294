import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import {
  type PermissionSchema,
  permissionSchema,
  PermissionContentTypeSchema,
} from '@/zod_schemas';
import {
  useCreateGroupPermission,
  useGetPermissionByID,
  usePatchPermission,
} from '@/api/permission/permission';

type PermissionPathParams = {
  permissionId: string;
};

interface UsePermissionFormHandlerReturn
  extends ReturnType<typeof useForm<PermissionSchema>> {
  mode: 'create' | 'update';
  permissionId?: string;
  permissionGroups?: PermissionContentTypeSchema[];
}

type UsePermissionFormHandler = () => UsePermissionFormHandlerReturn;

export const usePermissionFormHandler: UsePermissionFormHandler = () => {
  const { permissionId } = useParams<PermissionPathParams>();
  const { data } = useGetPermissionByID(permissionId);
  // const { data: permissionType } = useGetPermissionsType();

  const formMethods = useForm<PermissionSchema>({
    resolver: zodResolver(permissionSchema),
    defaultValues: data || {},
  });

  return {
    ...formMethods,
    mode: permissionId ? 'update' : 'create',
    permissionId,
  };
};

export const processPermissionData = (
  data: PermissionSchema,
  permissionGroups: PermissionContentTypeSchema[],
  permissionId: string | undefined,
) => {
  const selectedPermissions = permissionGroups.flatMap((group) =>
    group.permissions.filter((perm) => perm.allowed).map((perm) => perm.id),
  );
  const processedData = {
    ...(permissionId ? { id: permissionId } : {}),
    name: data.name,
    description: data.description,
    permissions: selectedPermissions,
  };

  return processedData;
};

export const useCreateOrUpdatePermission = (
  mode: 'create' | 'update',
  permissionGroups: PermissionContentTypeSchema[],
  permissionId?: string,
) => {
  const navigate = useNavigate();
  const createPermission = useCreateGroupPermission();
  const updatePermission = usePatchPermission();

  const onSubmit: SubmitHandler<PermissionSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        const processedData = processPermissionData(
          data,
          permissionGroups,
          permissionId,
        );

        console.log('processedData', processedData);

        if (processedData.permissions.length === 0) {
          enqueueSnackbar(
            'Please select atleast one permission for the group',
            {
              variant: 'error',
            },
          );
          reject(new Error('No permissions selected')); // Reject the promise to halt further processing
          return;
        }
        // // Process the permission data before submitting
        if (mode === 'create') {
          createPermission
            .mutateAsync(processedData)
            .then(() => {
              resolve();

              enqueueSnackbar('Group created successfully', {
                variant: 'success',
              });

              navigate('/administrator?tab=permission');
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to create group', {
                variant: 'error',
              });
            });
        } else {
          console.log('asd');
          if (!permissionId) {
            reject(new Error('Group ID is required'));

            enqueueSnackbar('Unknown error occurred', {
              variant: 'error',
            });

            return;
          }

          updatePermission
            .mutateAsync({ payload: processedData, id: permissionId })
            .then(() => {
              resolve();

              enqueueSnackbar('Group updated successfully', {
                variant: 'success',
              });
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to update group', {
                variant: 'error',
              });
            });
        }
      }).catch((error) => {
        // This will catch the error to avoid the uncaught error in the console
        console.error(error); // Optional: Log the error
      }),
    [
      mode,
      permissionGroups,
      createPermission,
      navigate,
      permissionId,
      updatePermission,
    ],
  );

  return onSubmit;
};
