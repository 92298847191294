import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

import { type ExperimentStatusSchema } from '@/zod_schemas';

export const getChipLabel = (status: ExperimentStatusSchema) => {
  if (status === 'pending') {
    return 'Start';
  }

  if (status === 'waiting') {
    return 'Waiting';
  }

  if (status === 'completed') {
    return 'Completed';
  }

  if (status === 'in_progress') {
    return 'In Progress';
  }

  if (status === 'failed') {
    return 'Failed';
  }

  return 'Unknown';
};

export const getChipColor = (status: ExperimentStatusSchema) => {
  if (status === 'pending') {
    return 'info';
  }

  if (status === 'waiting') {
    return 'info';
  }

  if (status === 'completed') {
    return 'success';
  }

  if (status === 'in_progress') {
    return 'warning';
  }

  if (status === 'failed') {
    return 'error';
  }

  return 'default';
};

export const getChipIcon = (status: ExperimentStatusSchema) => {
  if (status === 'pending') {
    return PlayArrowIcon;
  }

  if (status === 'waiting') {
    return HourglassTopIcon;
  }

  if (status === 'completed') {
    return OpenInNewIcon;
  }

  if (status === 'in_progress') {
    return AutorenewIcon;
  }

  if (status === 'failed') {
    return ErrorIcon;
  }

  return HelpIcon;
};
