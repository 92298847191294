import React from 'react';
import ReactDOM from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { Container, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

import { initSentry } from '@utils/sentry';
import queryClient from '@api/queryClient';
import { PipelineFormProvider } from '@pages/common/Administrator/Pipeline/PipelineFormContext';
import router from './router';
import theme from './theme';
import './index.css';

if (process.env.NODE_ENV === 'production') initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PipelineFormProvider>
      <SnackbarProvider />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Container maxWidth={false} disableGutters>
            <RouterProvider router={router} />
          </Container>
        </ThemeProvider>
      </QueryClientProvider>
    </PipelineFormProvider>
  </React.StrictMode>,
);
