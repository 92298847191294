import { ReactNode } from 'react';

import { Navigate } from 'react-router-dom';
import { CircularProgress, styled } from '@mui/material';

import { useGetCurrentUser } from '@api/auth/auth';

const Container = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface IRedirectToLoginProps {
  children: ReactNode;
}
const RedirectToLogin = () => <Navigate to="/login" replace />;

const AuthRequired = ({ children }: IRedirectToLoginProps) => {
  const { data, isLoading, isError } = useGetCurrentUser();

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (isError || !data) {
    return <RedirectToLogin />;
  }

  return children;
};

export default AuthRequired;
