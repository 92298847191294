import { Grid } from '@mui/material';

import LoginForm from '@pages/common/Login/LoginForm';
import backgroundImage from '@assets/register-bg.jpeg';
import {
  LoginBanner,
  LoginContainer,
} from '@pages/common/Login/StyledComponents';

const Login = () => (
  <LoginContainer container>
    <Grid item xs={0} sm={0} md={7}>
      <LoginBanner sx={{ backgroundImage: `url("${backgroundImage}")` }} />
    </Grid>
    <Grid item xs={12} sm={12} md={5} sx={{ padding: '40px' }}>
      <LoginForm />
    </Grid>
  </LoginContainer>
);

export default Login;
