import { createTheme, lighten } from '@mui/material';
import { deepmerge } from '@mui/utils';

const defaultTheme = createTheme();

declare module '@mui/material/styles' {
  interface TypeBackground {
    default: string;
    paper: string;
    dashboardMainContent: string;
  }
}

declare module '@mui/material/Card' {
  interface CardOwnProps {
    variant: 'error' | 'success' | 'default';
  }
}

const baseTheme = createTheme();

const theme = createTheme({
  components: {
    MuiCard: {
      variants: [
        {
          props: { variant: 'error' },
          style: {
            backgroundColor: lighten(baseTheme.palette.error.main, 0.9),
          },
        },
        {
          props: { variant: 'success' },
          style: {
            backgroundColor: lighten(baseTheme.palette.success.main, 0.9),
          },
        },
        {
          props: { variant: 'default' },
          style: {
            backgroundColor: baseTheme.palette.background.paper,
          },
        },
      ],
    },
  },
  palette: {
    background: {
      dashboardMainContent: '#F6F8FB',
    },
  },
});

export default deepmerge(defaultTheme, theme);
