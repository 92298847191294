import { Tooltip } from '@mui/material';
import { XCircle } from 'lucide-react';

import { RemoveExperimentIconButton } from './StyledComponents';

interface IRemoveExperimentButtonProps {
  removeExperiment: () => void;
}

const RemoveExperimentButton = ({
  removeExperiment,
}: IRemoveExperimentButtonProps) => (
  <Tooltip title="Remove experiment">
    <RemoveExperimentIconButton
      aria-label="remove experiment"
      size="small"
      onClick={removeExperiment}
    >
      <XCircle size={16} />
    </RemoveExperimentIconButton>
  </Tooltip>
);

export default RemoveExperimentButton;
