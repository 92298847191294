import { ReactNode } from 'react';

import {
  Box,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BadgeHelp } from 'lucide-react';

import logo from '@assets/logo.svg';
import DashboardShellHeader from '@components/DashboardShell/Components/DashboardShellHeader';
import {
  ContentHeaderContainer,
  Drawer,
  LogoContainer,
  MainContentContainer,
  SectionListContainer,
  Toolbar,
} from './StyledComponents';
import dashboardSections from './dashboardSections';

interface IDashboardShellProps {
  children: ReactNode;
}

const DashboardShell = ({ children }: IDashboardShellProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ContentHeaderContainer>
        <DashboardShellHeader />
      </ContentHeaderContainer>
      <Drawer variant="permanent" anchor="left" classes={{ paper: '' }}>
        <LogoContainer>
          <img src={logo} alt="logo" />
          <h3>SYNAPSYS</h3>
        </LogoContainer>
        <List sx={{ height: '100%' }}>
          <SectionListContainer>
            <div>
              {dashboardSections.map(({ name, path, id, Icon }) => (
                <ListItem key={id}>
                  <ListItemButton onClick={() => navigate(path)}>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
            <div>
              <Divider />
              <ListItem key={99}>
                <ListItemButton onClick={() => navigate('help')}>
                  <ListItemIcon>
                    <BadgeHelp />
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                </ListItemButton>
              </ListItem>
            </div>
          </SectionListContainer>
        </List>
      </Drawer>
      <MainContentContainer>
        <Toolbar />
        {children}
      </MainContentContainer>
    </Box>
  );
};

export default DashboardShell;
