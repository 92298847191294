import { Suspense, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCreateOrUpdatePermission, usePermissionFormHandler } from './hooks';
import { PermissionFormElement } from './Styles/StyledComponents';
import { useGetPermissionContent } from '@/api/permission/permission';
import { PermissionContentTypeSchema } from '@/zod_schemas';

const PermissionsForm = () => {
  const {
    handleSubmit,
    register,
    getValues,
    mode,
    permissionId,
    formState: { errors },
  } = usePermissionFormHandler();

  const { data } = useGetPermissionContent();

  const [permissionGroups, setPermissionGroups] = useState<
    PermissionContentTypeSchema[]
  >([]);

  useEffect(() => {
    if (data) {
      // Initialize `allowed` state for each permission based on getValues().permissions
      const initialPermissions = data.map((group) => ({
        ...group,
        permissions: group.permissions.map((permission) => ({
          ...permission,
          allowed:
            mode !== 'create'
              ? getValues().permissions!.includes(permission.id)
              : false, // Set allowed based on formData
        })),
      }));
      setPermissionGroups(initialPermissions);
    }
  }, [data, getValues, mode]);

  const handleTogglePermission = (
    groupIndex: number,
    permissionIndex: number,
  ) => {
    const updatedGroups = [...permissionGroups];
    const currentPermission =
      updatedGroups[groupIndex].permissions[permissionIndex];
    currentPermission.allowed = !currentPermission.allowed;
    setPermissionGroups(updatedGroups);
  };

  const handleSelectAll = (groupIndex: number) => {
    const updatedGroups = [...permissionGroups];
    const allAllowed = updatedGroups[groupIndex].permissions.every(
      (perm) => perm.allowed,
    );
    updatedGroups[groupIndex].permissions = updatedGroups[
      groupIndex
    ].permissions.map((perm) => ({
      ...perm,
      allowed: !allAllowed,
    }));
    setPermissionGroups(updatedGroups);
  };

  const onSubmit = useCreateOrUpdatePermission(
    mode,
    permissionGroups,
    permissionId,
  );

  return (
    <Paper
      elevation={4}
      sx={{ padding: 4, borderRadius: 3, backgroundColor: grey[50] }}
    >
      <Stack spacing={4}>
        <Typography
          variant="h5"
          color="primary"
          align="center"
          sx={{ fontWeight: 'bold' }}
        >
          Group Permissions Management
        </Typography>
        <PermissionFormElement onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                {...register('name')}
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                {...register('description')}
                error={!!errors?.description}
                helperText={errors?.description?.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {permissionGroups.map((group, groupIndex) => (
              <Grid item xs={12} md={6} key={group.content_type.id}>
                <Box
                  sx={{
                    padding: 2,
                    border: `1px solid ${grey[300]}`,
                    borderRadius: 2,
                    backgroundColor: grey[100],
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      variant="h6"
                      color="secondary"
                      sx={{ fontWeight: 600 }}
                    >
                      {group.content_type.model} Permissions
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={group.permissions.every(
                            (perm) => perm.allowed,
                          )}
                          onChange={() => handleSelectAll(groupIndex)}
                          color="primary"
                        />
                      }
                      label="Select All"
                      labelPlacement="start"
                    />
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                  {group.permissions.map((permission, permissionIndex) => (
                    <Grid
                      container
                      key={permission.id}
                      alignItems="center"
                      sx={{ py: 0.5 }}
                    >
                      <Grid item xs={6}>
                        <Typography>{permission.name}</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Switch
                          size="small"
                          checked={permission.allowed}
                          onChange={() =>
                            handleTogglePermission(groupIndex, permissionIndex)
                          }
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box textAlign="center" sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              sx={{ width: '200px' }}
            >
              Save
            </Button>
          </Box>
        </PermissionFormElement>
      </Stack>
    </Paper>
  );
};

const PermissionWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <PermissionsForm />
  </Suspense>
);

export default PermissionWithSuspense;
