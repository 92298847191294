import RegisterColumnView from '@pages/common/Register/RegisterColumnView';
import RegisterForm from '@pages/common/Register/RegisterForm';

const Register = () => (
  <RegisterColumnView>
    <RegisterForm />
  </RegisterColumnView>
);

export default Register;
