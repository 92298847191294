/* eslint-disable react/jsx-indent */
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useListProjects, ProjectList } from '@api/project';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Warning } from '@mui/icons-material';
import { ProjectCreatedByAvatar } from './StyledComponents';
import { stringToColor } from '@/utils/stringToColor';
import { QUERY_KEYSX, useDeleteProject } from '@/api/project/project';
import CustomDialog from '@/components/Dialog/CustomDialog';

const ProjectsList = () => {
  const { data } = useListProjects();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deleteProject = useDeleteProject();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSubmitDialog = async () => {
    if (deleteId === null) {
      enqueueSnackbar('Failed to delete project', {
        variant: 'error',
      });
    } else {
      try {
        await deleteProject
          .mutateAsync(deleteId)
          .then(() => {
            enqueueSnackbar('Project delete successfully', {
              variant: 'success',
            });
            // Update data fetching
            queryClient.invalidateQueries({
              queryKey: QUERY_KEYSX.PROJECTS,
              exact: true,
            });
          })
          .catch(() => {
            enqueueSnackbar('Failed to delete project', {
              variant: 'error',
            });
          });
      } catch (ex) {
        enqueueSnackbar('Error. Please contact admin', {
          variant: 'error',
        });
      }
    }
    handleDialogClose();
  };

  const columns: GridColDef<ProjectList>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'experiment_group_count',
      headerName: 'No Experiments',
      flex: 1,
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      flex: 1,
      renderCell: (params) => {
        const { first_name: FirstName, last_name: LastName } =
          params.row.created_by || {};
        return (
          <Stack direction="row" spacing={1} marginTop="14px">
            <ProjectCreatedByAvatar
              sx={{
                backgroundColor: stringToColor(`${FirstName}${LastName}`),
              }}
            >
              {FirstName ? FirstName[0] : 'unknown'}
              {LastName ? LastName[0] : 'unknown'}
            </ProjectCreatedByAvatar>
            <Typography variant="caption">
              {FirstName} {LastName}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              const { id } = params.row;
              navigate(`/projects/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={async (event) => {
              event.stopPropagation();
              const { id } = params.row;

              setDialogOpen(true);
              setDeleteId(id);
            }}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>

          <CustomDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Warning color="warning" />
                <span>Delete Project</span>
              </Stack>
            }
            onSubmit={onSubmitDialog}
          >
            <div>Are you sure you want to delete project?</div>
          </CustomDialog>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={data} // This should now be an array of projects
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        onRowClick={(params, event) => {
          event.stopPropagation();
          const { id } = params.row;
          navigate(`/projects/${id}`);
        }}
      />
    </Box>
  );
};

export default ProjectsList;
