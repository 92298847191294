import { Suspense } from 'react';
import ProjectEditForm from './Forms/ProjectEditForm';

const ProjectEdit = () => (
  <div>
    <input type="hidden" value="default" />
    <ProjectEditForm />
    {/* <Stack spacing={1} direction="row" alignItems="center">
          <AddExperimentButton
            variant="contained"
            color="primary"
            startIcon={<PlusIcon />}
            size="small"
            onClick={() => append(defaultExperiment)}
          >
            Add Experiment
          </AddExperimentButton>
          <HelperTextContainer>
            <span>
              {errors.experiments?.root && errors.experiments.root.message}
              {errors.experiments && errors.experiments.message}
            </span>
          </HelperTextContainer>
        </Stack>
        */}
    {/* <ExperimentsContainerUpdated
          removeExperiment={(index: number) => remove(index)}
          fields={fields}
          mode={mode}
        /> */}
  </div>
);

const ProjectEditWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ProjectEdit />
  </Suspense>
);
export default ProjectEditWithSuspense;
