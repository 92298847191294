import { useEffect } from 'react';

import {
  type Control,
  type FieldArrayWithId,
  type Path,
  useWatch,
} from 'react-hook-form';

import {
  type GroupExperimentSchema,
  PatientSchema,
  type ProjectSchema,
  type SampleFileSchema,
  type SampleSchema,
  type TestSamples,
} from '@/zod_schemas';

type UseSampleFilesArgs = {
  control: Control<ProjectSchema>;
  experimentIndex: number;
  sampleIndex: number;
  replace: (s: SampleFileSchema[]) => void;
  fields: FieldArrayWithId<SampleSchema, 'files'>[];
};

type UsePatientSampleFilesArgs = {
  control: Control<PatientSchema>;
  experimentIndex: number;
  sampleIndex: number;
  replace: (s: SampleFileSchema[]) => void;
  fields: FieldArrayWithId<SampleSchema, 'files'>[];
};

const sampleFile: SampleFileSchema = {
  file_path: '/local-file2',
  file_type: 'bam',
};

/*
 * This hook is used to update the sample files when the file type is changed.
 */
export const useSampleFiles = ({
  sampleIndex,
  experimentIndex,
  control,
  replace,
  fields,
}: UseSampleFilesArgs) => {
  const samplePath: Path<ProjectSchema> = `experiments.${experimentIndex}.samples.${sampleIndex}`;
  const sampleFileType = useWatch({
    control,
    name: `${samplePath}.sample_type`,
  });

  // We use the existing fields because if not when loading an existing project the
  // file fields will be emptied doe to sampleFileType changing when loading default values.
  useEffect(() => {
    if (sampleFileType === 'paired_fastq') {
      replace([...fields, sampleFile, sampleFile].slice(0, 2));
      return;
    }

    replace([...fields, sampleFile].slice(0, 1));
    // TODO: This is not a good solution. but adding "fields" to the dependency
    //  array causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleFileType, replace]);
};

/*
 * This hook is used to update the sample files when the file type is changed.
 */
export const useSampleFilesLatest = ({
  sampleIndex,
  control,
  replace,
  fields,
}: UsePatientSampleFilesArgs) => {
  const samplePath: Path<PatientSchema> = `samples.${sampleIndex}`;
  const sampleFileType = useWatch({
    control,
    name: `${samplePath}.sample_type`,
  });

  // We use the existing fields because if not when loading an existing project the
  // file fields will be emptied doe to sampleFileType changing when loading default values.
  useEffect(() => {
    if (sampleFileType === 'paired_fastq') {
      replace([...fields, sampleFile, sampleFile].slice(0, 2));
      return;
    }

    replace([...fields, sampleFile].slice(0, 1));
    // TODO: This is not a good solution. but adding "fields" to the dependency
    //  array causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleFileType, replace]);
};

type UseSampleFilesArgs2 = {
  control: Control<GroupExperimentSchema>;
  experimentIndex: number;
  sampleIndex: number;
  replace: (s: SampleFileSchema[]) => void;
  fields: FieldArrayWithId<TestSamples, 'files'>[];
};
/*
 * This hook is used to update the sample files when the file type is changed.
 */
export const useSampleFiles2 = ({
  sampleIndex,
  control,
  replace,
  fields,
}: UseSampleFilesArgs2) => {
  const samplePath: Path<GroupExperimentSchema> = `samples.${sampleIndex}`;
  const sampleFileType = useWatch({
    control,
    name: `${samplePath}.sample_type`,
  });

  // We use the existing fields because if not when loading an existing project the
  // file fields will be emptied doe to sampleFileType changing when loading default values.
  useEffect(() => {
    if (sampleFileType === 'paired_fastq') {
      replace([...fields, sampleFile, sampleFile].slice(0, 2));
      return;
    }

    replace([...fields, sampleFile].slice(0, 1));
    // TODO: This is not a good solution. but adding "fields" to the dependency
    //  array causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleFileType, replace]);
};
