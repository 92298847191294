import { Outlet } from 'react-router-dom';

import AuthRequired from '@components/AuthRequired';
import DashboardShell from '@components/DashboardShell';

const SkeletonWithAuth = () => (
  <AuthRequired>
    <DashboardShell>
      <Outlet />
    </DashboardShell>
  </AuthRequired>
);

export default SkeletonWithAuth;
