import { Box, Tab, Tabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { PermissionFormElement } from './Permissions/Styles/StyledComponents';
import PipelinesPage from './Pipeline/PipelinesPage';
import PermissionPage from './Permissions/PermissionPage';
import UsersPage from './Users/UsersPage';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const tabProps = (index: number) => ({
  id: `account-tab-${index}`,
  'aria-controls': `account-tabpanel-${index}`,
});

const tabMap: Record<string, number> = {
  pipeline: 0,
  users: 1,
  permission: 2,
};

const AdminPage = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  // Parse the tab index from the URL query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab && tab in tabMap) {
      setValue(tabMap[tab]);
    }
  }, [location.search]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Update the URL based on the selected tab index
    const tabString = Object.keys(tabMap).find(
      (key) => tabMap[key] === newValue,
    );
    if (tabString) {
      navigate(`/administrator?tab=${tabString}`, { replace: true });
    }
  };

  return (
    <PermissionFormElement>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Pipeline" {...tabProps(0)} />
          <Tab label="Users" {...tabProps(1)} />
          <Tab label="Permissions" {...tabProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PipelinesPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UsersPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PermissionPage />
      </CustomTabPanel>
    </PermissionFormElement>
  );
};

export default AdminPage;
