import { Grid, styled } from '@mui/material';

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',
}));

export const LoginBanner = styled('div')(() => ({
  height: '100vh',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundColor: 'cornflowerblue',
  backgroundBlendMode: 'multiply',
}));

export const LoginContainer = styled(Grid)(() => ({
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
}));

export const LoginFormBottomContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
