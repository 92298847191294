import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { post, get, put } from '@api/httpClient';
import {
  type PipelineList,
  type Pipeline,
  PipelineConfig,
} from '@api/pipeline/types';
import { AxiosResponse } from 'axios';
import { APIError, ResourceWithId } from '../types';
import queryClient from '../queryClient';
import { PipelineSchema } from '@/zod_schemas';

const QUERY_KEYS = {
  PIPELINES: ['pipelines'],
  PIPELINE: (pipelineId: string) => ['pipelines', { id: pipelineId }],
};

const transformData = (data: Pipeline): Pipeline => ({
  id: '',
  name: '',
  created_at: '',
  updated_at: '',
  configs: data.configs,
});

export const useGetPipeline = (pipelineId?: string) =>
  useSuspenseQuery<Pipeline | PipelineList>({
    queryKey: QUERY_KEYS.PIPELINE(pipelineId || ''),
    queryFn: async () => {
      if (!pipelineId) {
        const resp = await get('/pipeline_config/');
        return resp.data as PipelineList;
      }

      // Below code is for testing. Create mock data to generate UI
      // Once API ready this section will be remove
      const configs: PipelineConfig[] = [
        {
          name: 'split_fastq',
          type: 'textfield',
          value: 0,
          allowEmpty: false,
          input_type: 'number',
        },
        {
          name: 'split_fastq2',
          type: 'textfield',
          value: '0',
          allowEmpty: true,
          input_type: 'string',
        },
        {
          name: 'analysis_type',
          type: 'textfield',
          value: 'wes',
          allowEmpty: false,
          input_type: 'string',
        },
        {
          name: 'analysis_type2',
          type: 'textfield',
          value: 123,
          allowEmpty: true,
          input_type: 'number',
        },
        {
          name: 'genome',
          type: 'displayfield',
          value: 'GRCh37',
          allowEmpty: true,
          input_type: 'string',
        },
        {
          name: 'Dropdown multiple',
          type: 'dropdown',
          multiple: true,
          allowEmpty: false,
          input_type: 'string',
          value: [
            {
              id: '1',
              value: 'option 1',
            },
            {
              id: '2',
              value: 'option 2',
            },
          ],
          options: [
            {
              id: '1',
              value: 'option 1',
            },
            {
              id: '2',
              value: 'option 2',
            },
            {
              id: '3',
              value: 'option 3',
            },
          ],
        },
        {
          name: 'Dropdown single',
          type: 'dropdown',
          multiple: false,
          allowEmpty: false,
          input_type: 'number',
          value: 1,
          options: [
            {
              id: '1',
              value: 123,
            },
            {
              id: '2',
              value: 2456,
            },
            {
              id: '3',
              value: 345,
            },
          ],
        },
      ];

      // Above code is for testing. Create mock data to generate UI
      // Once API ready, this code section will be remove
      const resp = await get(`/pipeline_config/${pipelineId}/`);
      const pipelineData = transformData(resp.data);

      if (!Array.isArray(pipelineData.configs)) {
        pipelineData.configs = [];
      }
      pipelineData.configs?.push(...configs);

      return pipelineData as Pipeline;
      // return resp.data as Pipeline;
    },
  });

export const useCreatePipeline = () =>
  useMutation<AxiosResponse<PipelineSchema>, APIError, PipelineSchema>({
    mutationFn: (pipeline) =>
      post<PipelineSchema>('/pipelines/', { ...pipeline }),
  });

export const useUpdatePipeline = () =>
  useMutation<
    AxiosResponse<PipelineSchema>,
    APIError,
    ResourceWithId<PipelineSchema>
  >({
    mutationFn: ({ id, payload }) =>
      put<PipelineSchema>(`/pipelines/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PIPELINES,
      });
    },
  });
