import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { sentryCaptureException } from '@/utils/sentry';
import { useLogoutMutation } from '@/api/auth';

export const useHandleLogout = () => {
  const navigate = useNavigate();
  const userLogout = useLogoutMutation();
  return useCallback(async () => {
    try {
      await userLogout.mutateAsync();
      enqueueSnackbar('Logout successful', {
        variant: 'success',
      });
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (e) {
      if (isAxiosError(e)) {
        enqueueSnackbar('Failed to logout', {
          variant: 'error',
        });
        return;
      }

      enqueueSnackbar('Login failed', {
        variant: 'error',
      });
      sentryCaptureException(e as Error);
    }
  }, [userLogout, navigate]);
};
