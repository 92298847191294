import { Dialog, DialogContent, styled } from '@mui/material';

import FileBrowser from './FileBrowser';

interface IFileBrowserDialogProps {
  onClose: () => void;
  onFilesSelected?: (files: string[]) => void;
  selectMultiple?: boolean;
}

const StyledDialog = styled(Dialog)(() => ({}));

const FileBrowserDialog = ({
  onClose,
  onFilesSelected,
  selectMultiple,
}: IFileBrowserDialogProps) => (
  <StyledDialog
    // eslint-disable-next-line react/jsx-boolean-value
    open={true}
    maxWidth="lg"
    onClose={onClose}
    fullWidth
  >
    <DialogContent>
      <FileBrowser
        onSelectFiles={onFilesSelected}
        selectMultiple={selectMultiple}
      />
    </DialogContent>
  </StyledDialog>
);

export default FileBrowserDialog;
