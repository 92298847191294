import { GeneralStyledForm } from '@/components/GeneralForm/GeneralStyledForm';
import ResultDetails from './ResultDetails';
import { SampleResultSchema } from '@/zod_schemas';

interface SampleResultsProps {
  resultData: SampleResultSchema[];
  searchCompleted: boolean;
}
const SampleResults = ({ resultData, searchCompleted }: SampleResultsProps) => (
  <GeneralStyledForm>
    <ResultDetails resultData={resultData} searchCompleted={searchCompleted} />
  </GeneralStyledForm>
);

export default SampleResults;
