import React, { createContext, useContext, useMemo } from 'react';

interface PatientContextType {
  mode: 'create' | 'update';
  patientId?: string;
}

const PatientContext = createContext<PatientContextType | undefined>(undefined);

export const PatientProvider: React.FC<{
  mode: 'create' | 'update';
  patientId?: string;
  children: React.ReactNode;
}> = ({ mode, patientId, children }) => {
  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ mode, patientId }), [mode, patientId]);

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatientContext = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error('usePatientContext must be used within a PatientProvider');
  }
  return context;
};
