import { useAtom } from 'jotai';
import { Paper, Typography } from '@mui/material';

import UploadCard from '@components/DashboardShell/Uploads/UploadCard';
import {
  UploadCardsContainer,
  UploadSidebar,
} from '@components/DashboardShell/Uploads/StyledComponents';
import { uploadsAtom } from '@/atoms/uploadsAtom';

const UploadsSidebar = () => {
  const [uploads] = useAtom(uploadsAtom);
  const uploadCount = uploads.length;

  return (
    <UploadSidebar>
      <Paper sx={{ textAlign: 'center' }} square>
        <Typography variant="h6" gutterBottom>
          Uploads
        </Typography>
      </Paper>
      {uploadCount === 0 && (
        <Typography variant="subtitle1">No uploads</Typography>
      )}
      <UploadCardsContainer>
        {uploads.map((upload) => (
          <UploadCard
            key={upload.id}
            id={upload.id}
            location={upload.location}
          />
        ))}
      </UploadCardsContainer>
    </UploadSidebar>
  );
};

export default UploadsSidebar;
