import { get, post } from '@api/httpClient';
import { APIError } from '@api/types';
import CurrentUser from '@api/auth/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  RegisterSchema,
  LoginSchema,
  RequestOtpSchema,
  EmailValidationSchema,
  ResetPasswordSchema,
} from '@/zod_schemas';

type LoginRequestSchema = Omit<LoginSchema, 'remember'>;

const QUERY_KEYS = {
  CURRENT_USER: ['users', 'current'],
};

export const useRegisterMutation = () =>
  useMutation<RegisterSchema, APIError, RegisterSchema>({
    mutationFn: (data) => post('/users/register', data),
  });

export const useLoginMutation = () =>
  useMutation<LoginRequestSchema, APIError, LoginRequestSchema>({
    mutationFn: (data) => post('/auth/authenticate', data),
  });

export const useLogoutMutation = () =>
  useMutation({
    mutationFn: () => post('/auth/logout'),
  });

export const useRequestOtpMutation = () =>
  useMutation<RequestOtpSchema, APIError, RequestOtpSchema>({
    mutationFn: (data) => post('/auth/request_otp', data),
  });

export const useRequestPasswordMutation = () =>
  useMutation<EmailValidationSchema, APIError, EmailValidationSchema>({
    mutationFn: (data) => post('/password_reset/request', data),
  });

export const useResetPasswordMutation = (uniqId: string) =>
  useMutation<ResetPasswordSchema, APIError, ResetPasswordSchema>({
    mutationFn: (data) => post(`/password_reset/reset/${uniqId}/`, data),
  });

export const useGetCurrentUser = () =>
  useQuery<CurrentUser>({
    queryKey: QUERY_KEYS.CURRENT_USER,
    retry: 1,
    queryFn: async () => {
      const resp = await get('/users/current');
      return resp.data;
    },
  });
