import { Breadcrumbs, Chip } from '@mui/material';

interface IFileBrowserBreadcrumbsProps {
  location: string;
}

const FileBrowserBreadcrumbs = ({ location }: IFileBrowserBreadcrumbsProps) => {
  const sanitizedLocation =
    location === '/'
      ? 'root'
      : location.substring(0, location.length - 1).replace('/', 'root/');

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      {sanitizedLocation.split('/').map((loc) => (
        <Chip
          key={loc}
          label={loc}
          size="small"
          clickable
          variant="filled"
          color="info"
        />
      ))}
    </Breadcrumbs>
  );
};

export default FileBrowserBreadcrumbs;
