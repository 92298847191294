import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';

import { useGetUserLists } from '@/api/users/users';
import UserList from './UserList';

const UserHeader = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingBottom: 2 }}
    >
      <Typography variant="h5">User Lists</Typography>
      <Button
        variant="contained"
        size="small"
        onClick={() => navigate('permission/group/create')}
      >
        Create Group
      </Button>
    </Stack>
  );
};

const UsersPage = () => {
  const { data } = useGetUserLists();

  return (
    <Stack direction="column" sx={{ paddingX: 1 }}>
      <UserHeader />
      <Suspense fallback={<div>Loading...</div>}>
        <UserList data={data} />
      </Suspense>
    </Stack>
  );
};

export default UsersPage;
