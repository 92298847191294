import { Suspense, type DragEvent, useState } from 'react';

import { ErrorBoundary } from '@sentry/react';

import { FileBrowserContainer } from '@components/FileBrowser/StyledComponents';
import DropHere from '@components/FileBrowser/Components/DropHere';
import {
  FileBrowserFileList,
  FileBrowserHeader,
  FileBrowserTableHeader,
} from '@components/FileBrowser/Components';
import {
  useAddFilesToUpload,
  useFileBrowserLocation,
} from '@components/FileBrowser/hooks';

interface IFileBrowserProps {
  onSelectFiles?: (files: string[]) => void;
  selectMultiple?: boolean;
}

const FileBrowser = ({ onSelectFiles, selectMultiple }: IFileBrowserProps) => {
  const { location } = useFileBrowserLocation();
  const [canDrop, setCanDrop] = useState(false);
  const addFilesToUpload = useAddFilesToUpload();

  const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    addFilesToUpload(e.dataTransfer.files, location);
  };

  return (
    <FileBrowserContainer
      onDrop={(e) => {
        handleOnDrop(e);
        setCanDrop(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setCanDrop(true);
      }}
      onDragLeave={() => setCanDrop(false)}
    >
      <FileBrowserHeader location={location} />
      <ErrorBoundary showDialog>
        <div>
          <FileBrowserTableHeader />
          <Suspense fallback={<div>Loading...</div>}>
            {!canDrop && (
              <FileBrowserFileList
                onSelectFiles={onSelectFiles}
                selectMultiple={selectMultiple}
              />
            )}
            {canDrop && <DropHere />}
          </Suspense>
        </div>
      </ErrorBoundary>
    </FileBrowserContainer>
  );
};

export default FileBrowser;
