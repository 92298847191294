import { useState, type MouseEvent } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

interface IAddNewMenuProps {
  onChange: (context: 'file' | 'directory') => void;
}

const AddNewMenu = ({ onChange }: IAddNewMenuProps) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
    setOpen(true);
  };

  return (
    <>
      <Menu
        id="directory-file-add-menu"
        anchorEl={anchor}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'add-new-menu-button',
        }}
      >
        <MenuItem onClick={() => onChange('file')}>File</MenuItem>
        <MenuItem onClick={() => onChange('directory')}>Directory</MenuItem>
      </Menu>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        id="add-new-menu-button"
      >
        Add New
      </Button>
    </>
  );
};

export default AddNewMenu;
