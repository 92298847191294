import { useCallback } from 'react';

import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type Path,
  type UseFormRegister,
  useFieldArray,
} from 'react-hook-form';
import { XCircle } from 'lucide-react';

import HelperTextContainer from '@components/HelperTextContainer';
import FormSelect from '@components/FormFields/FormSelect';
import FilesContainer2 from '@pages/common/Project/Forms/Components/FilesContainer2';
import { useSampleFiles2 } from '@pages/common/Project/Forms/hooks';
import { SampleContainer } from '@pages/common/Project/Forms/StyledComponents';
import {
  type GroupExperimentSchema,
  type SampleFileSchema,
} from '@/zod_schemas';

interface ISampleForm2Props {
  register: UseFormRegister<GroupExperimentSchema>;
  remove: () => void;
  index: number;
  experimentIndex: number;
  errors: FieldErrors<GroupExperimentSchema>;
  control: Control<GroupExperimentSchema>;
  isSubmitting: boolean;
}

const SampleForm2 = ({
  register,
  remove,
  index,
  experimentIndex,
  errors,
  control,
  isSubmitting,
}: ISampleForm2Props) => {
  const sampleErrors = errors.samples?.[index];
  const samplePath: Path<GroupExperimentSchema> = `samples.${index}`;

  const { fields, replace } = useFieldArray({
    control,
    name: `${samplePath}.files`,
  });

  // useCallback is used to prevent infinite loop
  const replaceFiles = useCallback(
    (s: SampleFileSchema[]) => replace(s),
    [replace],
  );

  useSampleFiles2({
    control,
    sampleIndex: index,
    experimentIndex,
    replace: replaceFiles,
    fields,
  });

  return (
    <SampleContainer>
      <div>
        <Tooltip title="Remove sample">
          <IconButton aria-label="remove sample" size="small" onClick={remove}>
            <XCircle color="red" size={16} />
          </IconButton>
        </Tooltip>
      </div>
      <Divider orientation="vertical" variant="fullWidth" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sample-id">
              Sample ID - Experiment:{experimentIndex} - Sample:{index}
            </InputLabel>
            <Input
              id="sample-id"
              type="text"
              aria-describedby="sample id field"
              size="small"
              fullWidth
              {...register(`${samplePath}.sample_id`)}
            />
            <HelperTextContainer>
              <span>{sampleErrors?.sample_id?.message}</span>
            </HelperTextContainer>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="sample-file-type-label" htmlFor="sample-file-type">
              Type
            </InputLabel>
            <FormSelect
              selectProps={{
                labelId: 'sample-file-type-label',
                id: 'sample-file-type',
                'aria-describedby': 'sample file type field',
              }}
              controllerProps={{
                control,
                name: `${samplePath}.sample_type`,
              }}
            >
              <MenuItem value="unpaired_fastq">Unpaired FASTQ</MenuItem>
              <MenuItem value="paired_fastq">Paired FASTQ</MenuItem>
              <MenuItem value="bam">BAM</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              {sampleErrors?.sample_type?.message}
            </HelperTextContainer>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="sample-gender-label" htmlFor="sample-gender">
              Gender
            </InputLabel>
            <FormSelect
              controllerProps={{
                control,
                name: `${samplePath}.gender`,
              }}
              selectProps={{
                labelId: 'sample-gender-label',
                id: 'sample-gender',
                'aria-describedby': 'sample gender field',
              }}
            >
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="unknown">Unknown</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              {sampleErrors?.gender && (
                <span>{sampleErrors.gender.message}</span>
              )}
            </HelperTextContainer>
          </FormControl>
        </Grid>
        {fields.length > 0 && (
          <Grid item xs={12}>
            <FilesContainer2
              control={control}
              errors={errors}
              sampleIndex={index}
              fields={fields}
              isSubmitting={isSubmitting}
            />
          </Grid>
        )}
      </Grid>
    </SampleContainer>
  );
};

export default SampleForm2;
