import { Description } from '@mui/icons-material';
import {
  PieChart,
  Stethoscope,
  Workflow,
  Files,
  GanttChartSquare,
} from 'lucide-react';

// This will most likely depend on the DX or RX so later will reside in a hook
const dashboardSections = [
  {
    id: 1,
    name: 'Dashboard',
    Icon: PieChart,
    path: '/',
  },
  {
    id: 2,
    name: 'Projects',
    Icon: GanttChartSquare,
    path: 'projects',
  },
  {
    id: 3,
    name: 'File Manager',
    Icon: Files,
    path: 'files',
  },
  {
    id: 4,
    name: 'Patients',
    Icon: Stethoscope,
    path: 'patients',
  },
  {
    id: 5,
    name: 'Samples',
    Icon: Description,
    path: 'samples',
  },
  {
    id: 6,
    name: 'Analysis & Workbench',
    Icon: Workflow,
    path: 'workbench',
  },
];

export default dashboardSections;
