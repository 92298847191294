import { createBrowserRouter } from 'react-router-dom';

import MainDashboard from '@pages/common/MainDashboard';
import Register from '@pages/common/Register';
import Login from '@pages/common/Login/Login';
import SkeletonWithAuth from '@components/SkeletonWithAuth';
import FileBrowser from '@pages/common/FileBrowser';
import { Project, Projects } from '@pages/common/Project';
import { Patients, Patient } from '@pages/common/Patient';
import AccountPage from '@pages/common/Account/AccountPage';
import BatchConfig from '@pages/common/Project/BatchConfig';
import ViewPipeline from '@pages/common/Administrator/Pipeline/components/ViewPipeline';
import PipelinesPage from '@pages/common/Administrator/Pipeline/PipelinesPage';
import ProjectEdit from '@pages/common/Project/ProjectEdit';
import ResetPasswordPage from './pages/common/Login/ResetPasswordPage';
import Samples from './pages/common/Sample/Samples';
import AdminPage from './pages/common/Administrator/AdminPage';
import PermissionsForm from './pages/common/Administrator/Permissions/PermissionForm';
import UserPage from './pages/common/Administrator/Users/UserPage';
import AssignPermission from './pages/common/Administrator/Permissions/AssignPermission';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/password_reset/reset/:uniqId',
    element: <ResetPasswordPage />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <SkeletonWithAuth />,
    children: [
      {
        index: true,
        element: <MainDashboard />,
      },
      {
        path: 'patients',
        children: [
          {
            index: true,
            element: <Patients />,
          },
          {
            path: 'create',
            element: <Patient />,
          },
          {
            path: ':patientId',
            element: <Patient />,
          },
        ],
      },
      {
        path: 'samples',
        children: [
          {
            index: true,
            element: <Samples />,
          },
        ],
      },
      {
        path: 'files',
        element: <FileBrowser />,
      },
      {
        path: 'projects',
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: 'create',
            element: <Project />,
          },
          {
            // Original
            path: ':projectId',
            element: <ProjectEdit />,
          },
        ],
      },
      {
        path: 'batch',
        children: [
          {
            index: true,
            element: <BatchConfig />,
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            index: true,
            element: <AccountPage />,
          },
        ],
      },
      {
        path: 'administrator',
        children: [
          {
            index: true,
            element: <AdminPage />,
          },
          {
            path: 'pipeline',
            element: <PipelinesPage />,
          },
          {
            path: 'pipeline/create',
            element: <ViewPipeline />,
          },
          {
            path: 'pipeline/:pipelineId',
            element: <ViewPipeline />,
          },
          {
            path: 'permission/group/create',
            element: <PermissionsForm />,
          },
          {
            path: 'permission/group/edit/:permissionId',
            element: <PermissionsForm />,
          },
          {
            path: 'permission/group/assign/:permissionId',
            element: <AssignPermission />,
          },
          {
            path: 'user/edit/:userId',
            element: <UserPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
