// CustomNoRowsOverlay.tsx
import { Box, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

interface CustomNoRowsOverlayProps {
  message?: string;
}
const CustomNoRowsOverlay = ({ message }: CustomNoRowsOverlayProps) => (
  <GridOverlay>
    <Box sx={{ mt: 1 }}>
      <Typography variant="subtitle1" color="textSecondary">
        {message || 'No data available'}
      </Typography>
    </Box>
  </GridOverlay>
);

export default CustomNoRowsOverlay;
