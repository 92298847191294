import { useState } from 'react';
import { Button } from '@mui/material';
import CustomDialog from '@/components/Dialog/CustomDialog';
import HPODataList from './HPODataList';
import { HPOSchema } from '@/zod_schemas';

interface BtnMenuHPOProps {
  handleAddHPOClick: () => void;
  handleConfirmClick?: (newData: HPOSchema, type: string) => void;
}

const BtnMenuHPO = ({
  handleAddHPOClick,
  handleConfirmClick,
}: BtnMenuHPOProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [, setDialogType] = useState<'create' | 'select'>('create');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleSelect = () => {
  //   setDialogType('select');
  //   setDialogOpen(true);
  //   setAnchorEl(null);
  // };

  const handleCreate = () => {
    setAnchorEl(null);
    handleAddHPOClick();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        id="fade-button"
        aria-controls={menuOpen ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleCreate}
      >
        Add HPO
      </Button>
      {/* <Button
        variant="contained"
        id="fade-button"
        aria-controls={menuOpen ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        Add HPO
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSelect}>Select HPO</MenuItem>
        <MenuItem onClick={handleCreate}>HPO API</MenuItem>
      </Menu> */}
      <CustomDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        // onSubmit={handleDialogSubmit}
        title="HPO"
      >
        <HPODataList
          handleConfirmClick={handleConfirmClick}
          setDialogOpen={setDialogOpen}
        />
      </CustomDialog>
    </div>
  );
};

export default BtnMenuHPO;
