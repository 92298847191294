import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { get, post } from '@api/httpClient';
import { UserListsSchema } from '@/zod_schemas';
import { APIError } from '../types';

export const QUERY_KEYS_USER_LISTS = {
  USER_LISTS: ['user_lists'],
};

export const useGetUserLists = () =>
  useSuspenseQuery<UserListsSchema[]>({
    queryKey: QUERY_KEYS_USER_LISTS.USER_LISTS,
    queryFn: async () => {
      const resp = await get('/users/');
      return resp.data;
    },
  });

export const QUERY_KEYS_PERMISSION_GROUP_LISTS = {
  USER_GROUP_LISTS: ['permission_group_lists'],
};

export const useGetPermissionGroupLists = (id: string) =>
  useSuspenseQuery<UserListsSchema[]>({
    queryKey: QUERY_KEYS_PERMISSION_GROUP_LISTS.USER_GROUP_LISTS,
    queryFn: async () => {
      const resp = await get(`/permission_group/${id}/users/`);
      return resp.data;
    },
  });

// export const useAssignUserPermissionGroup = (id: string) =>
//   useMutation<AxiosResponse<string>, APIError, string>({
//     mutationFn: (project) =>
//       post<string>(`/user/user_permission/${id}/`, { ...project }),
//   });

export const useAssignUserPermissionGroup = (permissionId: string) =>
  useMutation<AxiosResponse<string>, APIError, string>({
    mutationFn: (userid) =>
      post<string>(`/user_permission/${userid}/group/assign/`, {
        group_permission_id: permissionId,
      }),
  });

export const useRemoveUserPermissionGroup = (permissionId: string) =>
  useMutation<AxiosResponse<string>, APIError, string>({
    mutationFn: (userid) =>
      post<string>(`/user_permission/${userid}/group/remove/`, {
        group_permission_id: permissionId,
      }),
  });

export const useRemoveMultipleUserPermissionGroup = (permissionId: string) =>
  useMutation<
    AxiosResponse<(string | number)[]>,
    APIError,
    (string | number)[]
  >({
    mutationFn: (userid) =>
      post<(string | number)[]>(
        `/permission_group/${permissionId}/remove_multiple/`,
        {
          user_ids: userid,
        },
      ),
  });

export const useAssignMultipleUserPermissionGroup = (permissionId: string) =>
  useMutation<
    AxiosResponse<(string | number)[]>,
    APIError,
    (string | number)[]
  >({
    mutationFn: (userid) =>
      post<(string | number)[]>(
        `/permission_group/${permissionId}/assign_multiple/`,
        {
          user_ids: userid,
        },
      ),
  });
