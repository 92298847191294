export const formatDate = (isoString: Date) => {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export function formatDateResults(dateString: string) {
  const date = new Date(dateString);

  // Extract day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determine AM or PM
  const period = hours >= 12 ? 'p.m' : 'a.m';
  hours = hours % 12 || 12; // Convert to 12-hour format and adjust 0 to 12

  return `${day}-${month}-${year} @ ${hours}.${minutes} ${period}`;
}
