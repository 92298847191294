import { Paper, styled } from '@mui/material';

export const GeneralStyledForm = styled('form')(({ theme }) => ({
  rowGap: theme.spacing(3),
  padding: '55px',
}));

export const ResultDisplaysPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));
