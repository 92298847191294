import { Stack, Typography } from '@mui/material';
import { Suspense } from 'react';
import PipelineListData from '@/pages/common/Administrator/Pipeline/components/PipelineListData';

const PipelinesHeader = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ paddingBottom: 2 }}
  >
    <Typography variant="h5">Pipeline List</Typography>
  </Stack>
);

const PipelinesPage = () => (
  <Stack direction="column" sx={{ paddingX: 1 }}>
    <PipelinesHeader />
    <Suspense fallback={<div>Loading...</div>}>
      <PipelineListData />
    </Suspense>
  </Stack>
);

export default PipelinesPage;
