import { styled } from '@mui/material';

export const UploadSidebar = styled('div')(({ theme }) => ({
  width: theme.spacing(50),
}));

export const UploadCardsContainer = styled('div')(() => ({
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
}));
