import { atom } from 'jotai';

export type Upload = {
  id: string;
  location: string;
  file: File;
  directory: string;
};

export type UploadList = Upload[];
export const uploadsAtom = atom<UploadList>([]);
