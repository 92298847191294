import { Box, FormControl, Stack, TextField, Typography } from '@mui/material';
import {
  Controller,
  type Control,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';
import HelperTextContainer from '@/components/HelperTextContainer';
import { PipelineSchema } from '@/zod_schemas';
import { boxSx } from '@/styles/globalStyles';

interface PipelineDetailsFormProps {
  register: UseFormRegister<PipelineSchema>;
  control: Control<PipelineSchema>;
  errors: FieldErrors<PipelineSchema>;
  mode: string;
}

const PipelineDetailsForm = ({
  register,
  control,
  errors,
  mode,
}: PipelineDetailsFormProps) => (
  <Box sx={boxSx}>
    <Stack direction="column" spacing={3}>
      <Typography variant="h6" gutterBottom>
        Details
      </Typography>
      <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
        <FormControl fullWidth margin="normal">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                fullWidth
                {...register('name')}
                sx={{
                  backgroundColor: '#fff',
                }}
              />
            )}
          />
          <HelperTextContainer>
            <span>{errors.name?.message}</span>
          </HelperTextContainer>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                variant="outlined"
                fullWidth
                {...register('description')}
                sx={{
                  backgroundColor: '#fff',
                }}
              />
            )}
          />
          <HelperTextContainer>
            <span>{errors.description?.message}</span>
          </HelperTextContainer>
        </FormControl>
      </Stack>
      {mode !== 'create' && (
        <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
          <FormControl fullWidth margin="normal">
            <Controller
              name="created_at"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    backgroundColor: '#f5f5f5', // Light grey background to indicate read-only
                    color: '#000', // Optional: Ensure text color is still readable
                    '& .MuiInputBase-input': {
                      cursor: 'not-allowed',
                    },
                  }}
                  {...field}
                  variant="outlined"
                  label="Created At"
                  fullWidth
                  {...register('created_at')}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Controller
              name="updated_at"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    backgroundColor: '#f5f5f5', // Light grey background to indicate read-only
                    color: '#000', // Optional: Ensure text color is still readable
                    '& .MuiInputBase-input': {
                      cursor: 'not-allowed',
                    },
                  }}
                  {...field}
                  variant="outlined"
                  fullWidth
                  label="Updated At"
                  {...register('updated_at')}
                />
              )}
            />
          </FormControl>
        </Stack>
      )}
    </Stack>
  </Box>
);

export default PipelineDetailsForm;
