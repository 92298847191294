import {
  Box,
  Button,
  Grid,
  Stack,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import DetailsInformation from './DetailsInformation';
import ProgressDetails from './ProgressDetails';
import { SampleResultSchema } from '@/zod_schemas';

interface ResultDetailsProps {
  resultData: SampleResultSchema[];
  searchCompleted: boolean;
}

const ResultDisplaysPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
}));

const ResultDetails = ({ resultData, searchCompleted }: ResultDetailsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const viewResults = (_sampleId: string) => {
    // console.log('view result:', sampleId);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const moreActions = (_sampleId: string) => {
    // console.log('more action:', sampleId);
  };

  return (
    <Stack direction="column" spacing={3}>
      {searchCompleted && resultData.length === 0 ? (
        <ResultDisplaysPaper>
          <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            sx={{ mt: 4 }}
          >
            No sample found
          </Typography>
        </ResultDisplaysPaper>
      ) : (
        resultData?.map((result) => (
          <Box key={result.id}>
            <ResultDisplaysPaper>
              <Grid container spacing={3} alignItems="center">
                {/* Information Section */}
                <Grid item xs={12} md={3}>
                  <DetailsInformation result={result} />
                </Grid>

                {/* Progress Section */}
                <Grid item xs={12} md={6}>
                  <ProgressDetails result={result} />
                </Grid>

                {/* Buttons Section */}
                <Grid item xs={12} md={3}>
                  <Stack
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    sx={{
                      backgroundColor: '#f0f0f0',
                      padding: '1rem',
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
                    }}
                  >
                    <Button
                      onClick={() => viewResults('1')}
                      size="medium"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      View Results
                    </Button>
                    <Button
                      onClick={() => moreActions('sample_id')}
                      size="medium"
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      More Actions
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </ResultDisplaysPaper>
          </Box>
        ))
      )}
    </Stack>
  );
};

export default ResultDetails;
