import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import PermissionGroupList from './PermissionGroupList';

const PermissionHeader = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingBottom: 2 }}
    >
      <Typography variant="h5">Group Lists</Typography>
      <Button
        variant="contained"
        size="small"
        onClick={() => navigate('permission/group/create')}
      >
        Create Group
      </Button>
    </Stack>
  );
};

const PermissionPage = () => (
  <Stack direction="column" sx={{ paddingX: 1 }}>
    <PermissionHeader />
    <Suspense fallback={<div>Loading...</div>}>
      <PermissionGroupList />
    </Suspense>
  </Stack>
);

export default PermissionPage;
