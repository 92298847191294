import {
  Checkbox,
  Divider,
  Grid,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';

import {
  FileIcon,
  FileNameAndIconContainer,
  FolderIcon,
} from '@components/FileBrowser/StyledComponents';
import RowButtonWithContextMenu from '@components/FileBrowser/Components/FileBrowserRowContextMenu';

interface IFileBrowserRowProps {
  userFileUrl: string;
  createdAt: string;
  isDirectory: boolean;
  fileSize: number | null;
  handleClick: (isDirectory: boolean, userFileUrl: string) => void;
  handleDownload: () => void;
  selected?: boolean;
}

const getHumanReadableFileSize = (fileSize: number) => {
  if (fileSize < 1000) {
    return `${fileSize}B`;
  }

  if (fileSize < 1000000) {
    return `${Math.round(fileSize / 1000)}KB`;
  }

  if (fileSize < 1000000000) {
    return `${Math.round(fileSize / 1000000)}MB`;
  }

  return `${Math.round(fileSize / 1000000000)}GB`;
};

export const FileBrowserRow = ({
  userFileUrl: propUserFileUrl,
  isDirectory: propIsDirectory,
  fileSize,
  createdAt,
  handleClick,
  selected,
  handleDownload,
}: IFileBrowserRowProps) => (
  <>
    <RowButtonWithContextMenu
      handleDownload={handleDownload}
      isDirectory={propIsDirectory}
    >
      <Grid container key={propUserFileUrl}>
        <ListItemButton
          disableGutters
          onClick={() => handleClick(propIsDirectory, propUserFileUrl)}
          sx={{ paddingLeft: 1 }}
          selected={selected}
        >
          <Grid item xs={12} sm={4} display="flex">
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <Checkbox
                edge={false}
                checked={selected}
                tabIndex={-1}
                disableRipple
                disabled={propIsDirectory}
                size="small"
                sx={{ padding: 0 }}
              />
            </ListItemIcon>
            <FileNameAndIconContainer>
              {propIsDirectory ? <FolderIcon /> : <FileIcon />}
              <div style={{ display: 'grid' }}>
                <Typography variant="caption" noWrap>
                  {propUserFileUrl}
                </Typography>
              </div>
            </FileNameAndIconContainer>
          </Grid>
          <Grid item sm={4} display={{ xs: 'none', sm: 'block' }}>
            <Typography variant="caption" display={{ xs: 'none', md: 'block' }}>
              {new Date(createdAt).toString()}
            </Typography>
            <Typography variant="caption" display={{ xs: 'block', md: 'none' }}>
              {new Date(createdAt).toDateString()}
            </Typography>
          </Grid>
          <Grid item sm={4} display={{ xs: 'none', sm: 'block' }}>
            <Typography variant="caption">
              {fileSize ? getHumanReadableFileSize(fileSize) : ''}
            </Typography>
          </Grid>
        </ListItemButton>
      </Grid>
    </RowButtonWithContextMenu>
    <Divider />
  </>
);
