// PermissionGroupList.tsx
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CustomNoRowsOverlay from '@/components/CustomNoRowsOverlay';
import { useGetPermissions } from '@/api/permission/permission';
import { PermissionSchema } from '@/zod_schemas';

const customMessage = 'No permission groups available'; // Example custom message

// Define a functional component to use as the overlay
const NoRowsOverlay = () => <CustomNoRowsOverlay message={customMessage} />;

const PermissionGroupList = () => {
  const navigate = useNavigate();
  const { data } = useGetPermissions();
  const columns: GridColDef<PermissionSchema>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              const { id } = params.row;
              navigate(`permission/group/edit/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={(event) => {
              event.stopPropagation();
              const { id } = params.row;
              navigate(`permission/group/assign/${id}`);
            }}
          >
            Assign Users
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        sx={{
          '& .MuiDataGrid-main': {
            minHeight: '300px', // Ensures minimum height when no rows
          },
        }}
      />
    </Box>
  );
};

export default PermissionGroupList;
