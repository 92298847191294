import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { APIError } from '@api/types';
import { get, post } from '@api/httpClient';
import queryClient from '@api/queryClient';

type UseRunExperimentParams = {
  id: string;
};

type Status = 'pending' | 'completed' | 'in_progress' | 'failed';

type UseRunExperimentResponse = {
  pk: string;
  status: Status;
};

type UseExperimentStatusResponse = {
  status: Status;
};

const QUERY_KEYS = {
  EXPERIMENT_STATUS: (experimentId: string) => [
    'experiments',
    'status',
    { id: experimentId },
  ],
};

export const useRunExperiment = () =>
  useMutation<
    AxiosResponse<UseRunExperimentResponse>,
    APIError,
    UseRunExperimentParams
  >({
    mutationFn: ({ id }) =>
      post<UseRunExperimentResponse>(`experiments/${id}/run`),
    onSuccess: ({ data: { pk } }) =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.EXPERIMENT_STATUS(pk),
      }),
  });

export const useExperimentStatus = ({ id }: { id?: string }) =>
  useSuspenseQuery<UseExperimentStatusResponse | null>({
    queryKey: QUERY_KEYS.EXPERIMENT_STATUS(id || ''),
    queryFn: async () => {
      if (!id) return null;

      const resp = await get<UseExperimentStatusResponse>(
        `experiments/${id}/status`,
      );
      return resp.data;
    },
  });
