import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { Search } from 'lucide-react';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import {
  AvatarContainer,
  SearchField,
} from '@components/DashboardShell/StyledComponents';
import { useGetCurrentUser } from '@api/auth/auth';
import UploadsSidebarButton from '@components/DashboardShell/Components/UploadSidebarButton';
import { useCallback, useState } from 'react';
import { Logout, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useHandleLogout } from '@/pages/common/Logout/hooks';

const DashboardShellHeader = () => {
  const navigate = useNavigate();
  const userLogout = useHandleLogout();
  const { data } = useGetCurrentUser();

  const handleLogout = useCallback(async () => {
    await userLogout();
  }, [userLogout]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAccount = () => {
    setAnchorEl(null);
    navigate('/account');
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <SearchField
        id="search"
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <Search
              style={{
                paddingRight: '0.5rem',
              }}
            />
          ),
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 10,
        }}
      >
        <UploadsSidebarButton />
        {data && (
          <>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                  ml: 2,
                }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <AvatarContainer>
                  <Avatar
                    sx={{
                      bgcolor: deepOrange[500],
                    }}
                  >
                    {data?.first_name.charAt(0)}
                  </Avatar>
                </AvatarContainer>
              </IconButton>
            </Tooltip>
            <Typography
              variant="overline"
              sx={{
                margin: '10px 10px 0 0',
              }}
            >
              Welcome, {data?.first_name}!
            </Typography>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
            >
              <MenuItem onClick={handleClickAccount}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => navigate('administrator')}>
                <ListItemIcon>
                  <AdminPanelSettingsOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Administrator
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

            {/* <AvatarContainer>
              <Avatar sx={{ bgcolor: deepOrange[500] }}>
                {data?.first_name.charAt(0)}
              </Avatar>
              <Typography variant="overline">
                Welcome, {data?.first_name}!
              </Typography>
            </AvatarContainer> */}
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardShellHeader;
