import axios from 'axios';

import { CreateUploadPartRequest } from '@api/fileHandling/types';
import {
  createMultipartUploadPart,
  finishMultipartUploadPart,
} from './mutations';

interface UploadPartParams extends CreateUploadPartRequest {
  chunk: Blob;
}

export const uploadPart = async ({
  partNumber,
  uploadId,
  chunk,
}: UploadPartParams) => {
  const {
    status: createPartStatus,
    data: { upload_url: uploadUrl },
  } = await createMultipartUploadPart({
    uploadId,
    partNumber,
  });

  if (createPartStatus !== 201) {
    throw new Error('Error creating multipart upload part');
  }

  const { status: uploadStatus, headers } = await axios.put(uploadUrl, chunk, {
    headers: {
      'Content-Type': false,
    },
  });

  if (uploadStatus !== 200) {
    throw new Error('Error uploading part');
  }

  const { status: finishUploadStatus } = await finishMultipartUploadPart({
    uploadId,
    partNumber,
    etag: headers.etag.replace(/"/g, ''), // Remove quotes from etag
  });

  if (finishUploadStatus !== 200) {
    throw new Error('Error finishing multipart upload');
  }
};
