import { useCallback, useMemo, useState } from 'react';

import {
  Autocomplete,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  createFilterOptions,
  debounce,
} from '@mui/material';
import {
  type Control,
  type FieldErrors,
  type Path,
  type UseFormRegister,
  type UseFormSetValue,
  useFieldArray,
  Controller,
} from 'react-hook-form';
import { XCircle } from 'lucide-react';

import HelperTextContainer from '@components/HelperTextContainer';
import FormSelect from '@components/FormFields/FormSelect';
import FilesContainer from '@pages/common/Project/Forms/Components/FilesContainer';
import { useSampleFiles } from '@pages/common/Project/Forms/hooks';
import { SampleContainer } from '@pages/common/Project/Forms/StyledComponents';
import {
  type SampleSchema,
  type ProjectSchema,
  type SampleFileSchema,
} from '@/zod_schemas';
import { useSearchSampleID } from '@/api/sample/sample';

interface ISampleFormProps {
  register: UseFormRegister<ProjectSchema>;
  remove: () => void;
  index: number;
  experimentIndex: number;
  errors: FieldErrors<ProjectSchema>;
  control: Control<ProjectSchema>;
  setValue: UseFormSetValue<ProjectSchema>;
}

const filterOptions = createFilterOptions<SampleSchema>();

const SampleForm = ({
  remove,
  register,
  index,
  experimentIndex,
  errors,
  setValue,
  control,
}: ISampleFormProps) => {
  const sampleErrors = errors.experiments?.[experimentIndex]?.samples?.[index];
  const samplePath: Path<ProjectSchema> = `experiments.${experimentIndex}.samples.${index}`;
  const { fields, replace } = useFieldArray({
    control,
    name: `${samplePath}.files`,
  });

  // useCallback is used to prevent infinite loop
  const replaceFiles = useCallback(
    (s: SampleFileSchema[]) => replace(s),
    [replace],
  );

  useSampleFiles({
    control,
    sampleIndex: index,
    experimentIndex,
    replace: replaceFiles,
    fields,
  });

  const [searchInput, setSearchInput] = useState('');

  // Debounce the input change handler to reduce unnecessary API calls
  const handleInputChange = useMemo(
    () =>
      debounce((_event, newInputValue) => {
        setSearchInput(newInputValue);
      }, 300),
    [],
  );

  // Conditionally fetch the data only if inputValue has more than 3 characters
  const { data: listSampleID = [], isFetching } = useSearchSampleID(
    searchInput.length > 2 ? searchInput : undefined,
  );

  // console.log(listSampleID);

  const loadSampleData = (sample: SampleSchema | string) => {
    if (typeof sample === 'object') {
      console.log('Loading data for sample', sample);

      // Assuming 'setValue' is available in your component via 'useForm' or 'control'
      // Set the form values for sample_id, gender, sample_type, and files
      setValue(`${samplePath}.sample_id`, sample.sample_id || '');
      setValue(`${samplePath}.gender`, sample.gender || 'unknown');
      setValue(`${samplePath}.sample_type`, sample.sample_type || 'bam');
      setValue(`${samplePath}.files`, sample.files || []);
      setValue(`${samplePath}.id`, sample.id || '');
    } else {
      console.log('Handling case where sample is a string');
      // Handle any specific cases where the sample is a string (if needed)
    }
  };

  return (
    <SampleContainer>
      <div>
        <Tooltip title="Remove sample">
          <IconButton aria-label="remove sample" size="small" onClick={remove}>
            <XCircle color="red" size={16} />
          </IconButton>
        </Tooltip>
      </div>
      <Divider orientation="vertical" variant="fullWidth" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small" variant="standard">
            <Controller
              name={`${samplePath}.sample_id`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  onInputChange={(event, newInputValue) => {
                    // Handle API call and auto-suggestions here
                    handleInputChange(event, newInputValue);
                  }}
                  onChange={(_event, newValue) => {
                    // Only handle objects here (ignore strings)
                    if (newValue && typeof newValue === 'object') {
                      field.onChange(newValue.sample_id || '');
                      loadSampleData(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filterOptions(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.sample_id,
                    );

                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        isNew: true,
                        inputValue,
                        sample_id: `Add "${inputValue}"`,
                        gender: 'male', // Default value
                        sample_type: 'bam', // Default value
                        files: [
                          {
                            file_path: 'string',
                            file_type: 'bam',
                          },
                        ],
                      });
                    }

                    return filtered;
                  }}
                  renderOption={(props, options) => (
                    <li {...props}>{options.sample_id}</li>
                  )}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={listSampleID}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.sample_id;
                  }}
                  freeSolo
                  loading={isFetching}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sample ID"
                      variant="standard"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          const inputValue = params.inputProps.value;

                          // Find if the input matches an existing suggestion
                          const selectedOption = listSampleID.find(
                            (option) => option.sample_id === inputValue,
                          );

                          if (selectedOption) {
                            // If there's a match, load data for the selected option
                            loadSampleData(selectedOption);
                          } else {
                            // If no match, load data for the input value (no suggestion case)
                            loadSampleData({
                              isNew: true,
                              sample_id: `Add "${inputValue}"`,
                              gender: 'male',
                              sample_type: 'bam',
                              files: [
                                {
                                  file_path: 'string',
                                  file_type: 'bam',
                                },
                              ],
                            });
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        },
                        endAdornment: (
                          <>
                            {isFetching ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            <HelperTextContainer>
              <span>{sampleErrors?.sample_id?.message}</span>
            </HelperTextContainer>
          </FormControl>

          <FormControl
            fullWidth
            size="small"
            variant="standard"
            sx={{ display: 'none' }}
          >
            <InputLabel htmlFor="sample-id">ID</InputLabel>
            <Input
              id="sample-id"
              type="text"
              aria-describedby="sample id field"
              size="medium"
              fullWidth
              {...register(`${samplePath}.id`)}
            />
            <HelperTextContainer>
              {sampleErrors?.id?.message}
            </HelperTextContainer>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="sample-file-type-label" htmlFor="sample-file-type">
              Type
            </InputLabel>
            <FormSelect
              selectProps={{
                labelId: 'sample-file-type-label',
                id: 'sample-file-type',
                'aria-describedby': 'sample file type field',
              }}
              controllerProps={{
                control,
                name: `${samplePath}.sample_type`,
              }}
            >
              <MenuItem value="unpaired_fastq">Unpaired FASTQ</MenuItem>
              <MenuItem value="paired_fastq">Paired FASTQ</MenuItem>
              <MenuItem value="bam">BAM</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              {sampleErrors?.sample_type?.message}
            </HelperTextContainer>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="sample-gender-label" htmlFor="sample-gender">
              Gender
            </InputLabel>
            <FormSelect
              controllerProps={{
                control,
                name: `${samplePath}.gender`,
              }}
              selectProps={{
                labelId: 'sample-gender-label',
                id: 'sample-gender',
                'aria-describedby': 'sample gender field',
              }}
            >
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="unknown">Unknown</MenuItem>
            </FormSelect>
            <HelperTextContainer>
              {sampleErrors?.gender && (
                <span>{sampleErrors.gender.message}</span>
              )}
            </HelperTextContainer>
          </FormControl>
        </Grid>
        {fields.length > 0 && (
          <Grid item xs={12}>
            <FilesContainer
              control={control}
              errors={errors}
              experimentIndex={experimentIndex}
              sampleIndex={index}
              fields={fields}
            />
          </Grid>
        )}
      </Grid>
    </SampleContainer>
  );
};

export default SampleForm;
