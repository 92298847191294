import PubSub from 'pubsub-js';

import queryClient from '@api/queryClient';
import { QUERY_KEYS } from '@api/fileHandling/fileHandling';
import { FileUploadPubSubPayload } from './types';

export const finishUpload = (id: string, location: string) => {
  PubSub.publish(`upload-${id}`, {
    type: 'complete',
  } as FileUploadPubSubPayload);

  queryClient.invalidateQueries({
    queryKey: [...QUERY_KEYS.USER_FILES, location],
  });
};
