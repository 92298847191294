import { ReactNode } from 'react';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  RegisterBanner,
  RegisterContainer,
} from '@pages/common/Register/StyledComponents';

import backgroundImage from '@assets/register-bg.jpeg';
import synapseLogo from '@assets/synapse-logo.png';

interface IRegisterContainerProps {
  children: ReactNode;
}
const RegisterColumnView = ({ children }: IRegisterContainerProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <RegisterContainer>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          {children}
        </Grid>
        {!isSmall && (
          <Grid item md={7} xs={0}>
            <RegisterBanner
              sx={{ backgroundImage: `url("${backgroundImage}")` }}
            >
              <img src={synapseLogo} className="logo" alt="synapse logo" />
            </RegisterBanner>
          </Grid>
        )}
      </Grid>
    </RegisterContainer>
  );
};

export default RegisterColumnView;
