import { Suspense, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCreateOrUpdatePermission, usePermissionFormHandler } from './hooks';
import { PermissionFormElement } from './Styles/StyledComponents';
import { useGetPermissionContent } from '@/api/permission/permission';
import { PermissionContentTypeSchema } from '@/zod_schemas';
import AssignGroupPermission from './AssignGroupPermission';

const AssignPermission = () => {
  const {
    handleSubmit,
    register,
    getValues,
    mode,
    permissionId,
    formState: { errors },
  } = usePermissionFormHandler();

  const { data, isLoading, error } = useGetPermissionContent();

  const [permissionGroups, setPermissionGroups] = useState<
    PermissionContentTypeSchema[]
  >([]);

  useEffect(() => {
    if (data && getValues) {
      const initialPermissions = data.map((group) => ({
        ...group,
        permissions: group.permissions.map((permission) => ({
          ...permission,
          allowed:
            mode !== 'create' &&
            getValues().permissions?.includes(permission.id),
        })),
      }));
      setPermissionGroups(initialPermissions);
    }
  }, [data, getValues, mode]);

  const onSubmit = useCreateOrUpdatePermission(
    mode,
    permissionGroups,
    permissionId,
  );

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Failed to load permissions.</Typography>;
  }

  return (
    <Paper
      elevation={4}
      sx={{ padding: 4, borderRadius: 3, backgroundColor: grey[50] }}
    >
      <Stack spacing={4}>
        <Typography
          variant="h5"
          color="primary"
          align="center"
          sx={{ fontWeight: 'bold' }}
        >
          Group Permissions Management
        </Typography>
        <PermissionFormElement onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                {...register('name')}
                error={!!errors?.name}
                helperText={errors?.name?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                disabled
                {...register('description')}
                error={!!errors?.description}
                helperText={errors?.description?.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {permissionGroups.map((group) => {
              // Filter checked permissions
              const checkedPermissions = group.permissions.filter(
                (perm) => perm.allowed,
              );

              if (checkedPermissions.length === 0) {
                return null; // Skip rendering this group if no checked permissions
              }

              return (
                <Grid item xs={12} md={6} key={group.content_type.id}>
                  <Box
                    sx={{
                      padding: 2,
                      border: `1px solid ${grey[300]}`,
                      borderRadius: 2,
                      backgroundColor: grey[100],
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 1 }}
                    >
                      <Typography
                        variant="h6"
                        color="secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        {group.content_type.model} Permissions
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              checkedPermissions.length ===
                              group.permissions.length
                            }
                            color="primary"
                            disabled
                          />
                        }
                        label="Select All"
                        labelPlacement="start"
                      />
                    </Stack>
                    <Divider sx={{ mb: 1 }} />
                    {checkedPermissions.map((permission) => (
                      <Grid
                        container
                        key={permission.id}
                        alignItems="center"
                        sx={{ py: 0.5 }}
                      >
                        <Grid item xs={6}>
                          <Typography>{permission.name}</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Switch
                            size="small"
                            checked={permission.allowed}
                            color="primary"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <AssignGroupPermission permissionId={permissionId!} />
        </PermissionFormElement>
      </Stack>
    </Paper>
  );
};

const AssignPermissionWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <AssignPermission />
  </Suspense>
);

export default AssignPermissionWithSuspense;
