import { useCallback } from 'react';

import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  useCreatePipeline,
  useGetPipeline,
  useUpdatePipeline,
} from '@api/pipeline/pipeline';
import { pipelineSchema, type PipelineSchema } from '@/zod_schemas';

type PipelinePathParams = {
  pipelineId: string;
};

type MainPipelineID = {
  mainPipelineID?: string;
};

interface UsePipelineFormHandlerReturn
  extends ReturnType<typeof useForm<PipelineSchema>> {
  mode: 'create' | 'update';
  pipelineId?: string;
}

type UsePipelineFormHandler = (
  options: MainPipelineID,
) => UsePipelineFormHandlerReturn;

export const usePipelineFormHandler: UsePipelineFormHandler = ({
  mainPipelineID,
}) => {
  const { pipelineId } = useParams<PipelinePathParams>();

  // pipelineId refer to main pipeline, mainPipelineID new created pipeline based on main pipeline
  const pipelineID = pipelineId || mainPipelineID;

  // Detect the mode for pipelineId /mainPipelineID
  const modePipeline = mainPipelineID ? 'create' : 'update';

  const { data } = useGetPipeline(pipelineID);

  const pipelineData = data as PipelineSchema;

  const formMethods = useForm<PipelineSchema>({
    resolver: zodResolver(pipelineSchema),
    defaultValues: pipelineData || {},
  });

  return {
    ...formMethods,
    mode: modePipeline,
    pipelineId,
  };
};

export const useCreateOrUpdatePipeline = (
  mode: 'create' | 'update',
  pipelineId?: string,
) => {
  const navigate = useNavigate();
  const createPipeline = useCreatePipeline();
  const updatePipeline = useUpdatePipeline();

  const onSubmit: SubmitHandler<PipelineSchema> = useCallback(
    (data) =>
      new Promise<void>((resolve, reject) => {
        if (mode === 'create') {
          createPipeline
            .mutateAsync(data)
            .then(({ data: { id } }) => {
              resolve();

              enqueueSnackbar('Pipeline created successfully', {
                variant: 'success',
              });

              navigate(`/administrator/pipeline/${id}`);
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to create pipeline', {
                variant: 'error',
              });
            });
        } else if (mode === 'update' && pipelineId) {
          updatePipeline
            .mutateAsync({ payload: data, id: pipelineId })
            .then(() => {
              resolve();

              enqueueSnackbar('Project updated successfully', {
                variant: 'success',
              });

              navigate(`/administrator/pipeline/${data.id}`);
            })
            .catch((ex) => {
              reject(ex);

              enqueueSnackbar('Failed to update project', {
                variant: 'error',
              });
            });
        } else {
          reject(new Error('Pipeline ID is required for mode'));

          enqueueSnackbar('Pipeline ID is required for mode', {
            variant: 'error',
          });
        }
      }),
    [createPipeline, mode, navigate, pipelineId, updatePipeline],
  );

  return onSubmit;
};
