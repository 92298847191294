import { createContext, useState, ReactNode, useMemo, useEffect } from 'react';
import { type MainPipelineID } from '@api/pipeline';

// Define the type for the context value
export interface PipelineFormContextType {
  dataPipeline: MainPipelineID;
  setDataPipeline: (data: MainPipelineID) => void;
}

// Create a Context with a default value
export const PipelineFormContext = createContext<
  PipelineFormContextType | undefined
>(undefined);

// Define the type for the provider props
export interface PipelineFormProviderProps {
  children: ReactNode;
}

// Create a Provider component
export const PipelineFormProvider = ({
  children,
}: PipelineFormProviderProps) => {
  const [dataPipeline, setDataPipeline] = useState<MainPipelineID>(() => {
    // Retrieve data from localStorage on initial load
    const storedData = localStorage.getItem('dataPipeline');
    return storedData ? JSON.parse(storedData) : {};
  });

  // Update localStorage whenever dataPipeline changes
  useEffect(() => {
    localStorage.setItem('dataPipeline', JSON.stringify(dataPipeline));
  }, [dataPipeline]);

  const value = useMemo(
    () => ({ dataPipeline, setDataPipeline }),
    [dataPipeline, setDataPipeline],
  );

  return (
    <PipelineFormContext.Provider value={value}>
      {children}
    </PipelineFormContext.Provider>
  );
};
