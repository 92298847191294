import pLimit from 'p-limit';
import PubSub from 'pubsub-js';

import { uploadPart } from './uploadPart';
import { FileUploadPubSubPayload } from './types';
import { finishMultipartUpload, startMultipartUpload } from './mutations';

const MAX_CHUNK_NUM = 200; // 1000 parts is the max because that's the S3 limit for parts
const MIN_CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const CONCURRENCY_LIMIT = 5;

const calculateChunkSize = (fileSize: number) => {
  const chunkSize = Math.ceil(fileSize / MAX_CHUNK_NUM);
  return chunkSize < MIN_CHUNK_SIZE ? MIN_CHUNK_SIZE : chunkSize;
};

const uploadMultipartFile = async (id: string, file: File, fileUrl: string) => {
  const chunkSize = calculateChunkSize(file.size);
  const numChunks = Math.ceil(file.size / chunkSize);

  // Create multipart upload
  const {
    status: startStatus,
    data: { upload_id: uploadId },
  } = await startMultipartUpload({
    fileUrl,
    numParts: numChunks,
    fileSize: file.size,
  });

  if (startStatus !== 201) {
    throw new Error('Error creating multipart upload');
  }

  const limit = pLimit(CONCURRENCY_LIMIT);
  let completedParts = 0;

  const uploadPartsPromises = [...Array(numChunks)].map(async (_, i) => {
    const chunkStartIndex = i * chunkSize;
    const chunkEndIndex = chunkStartIndex + chunkSize;
    const partNumber = i + 1;

    const chunk = file.slice(chunkStartIndex, chunkEndIndex);

    return limit(async () => {
      await uploadPart({ partNumber, uploadId, chunk });

      completedParts += 1;

      PubSub.publish(`upload-${id}`, {
        type: 'progress',
        body: (completedParts / numChunks) * 100,
      } as FileUploadPubSubPayload);
    });
  });

  await Promise.all(uploadPartsPromises);

  const { status: finishStatus } = await finishMultipartUpload({ uploadId });

  if (finishStatus !== 200) {
    throw new Error('Error finishing multipart upload');
  }
};

export default uploadMultipartFile;
