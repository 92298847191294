import { Suspense, useState } from 'react';
import {
  useCreateOrUpdatePatient,
  usePatientFormHandler,
} from '@pages/common/Patient/hooks';
import PatientHeader from '@pages/common/Patient/components/PatientHeader';
import Section from '@pages/common/Patient/Forms/components/Section';
import { Stack } from '@mui/material';
import PatientInformationForm from '@pages/common/Patient/components/PatientInformationForm';
import PatientDataForm from '@pages/common/Patient/components/PatientDataForm';
import { PatientFormElement } from './StyledComponents';
import { HPOSchema, SampleSchema } from '@/zod_schemas';
import { PatientProvider } from './PatientContext'; // Import the context provider

const Patient = () => {
  const {
    handleSubmit,
    register,
    control,
    mode,
    patientId,
    defaultHPOs = [],
    defaultSamples = [],
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = usePatientFormHandler();

  const [hpoGridData, setHpoGridData] = useState<HPOSchema[]>(defaultHPOs);
  const [sampleGridData, setSampleGridData] =
    useState<SampleSchema[]>(defaultSamples);
  const onSubmit = useCreateOrUpdatePatient(
    sampleGridData,
    hpoGridData,
    mode,
    patientId,
  );
  // const onSubmit = () => {
  //   console.log('aaa');
  //   console.log('sampleGridData', sampleGridData);
  //   console.log('hpoGridData', hpoGridData);
  //   console.log('mode', mode);
  //   console.log('patientId', patientId);
  // };
  return (
    <PatientProvider mode={mode} patientId={patientId}>
      <PatientFormElement id="patient-form" onSubmit={handleSubmit(onSubmit)}>
        <PatientHeader control={control} isSubmitting={isSubmitting} />
        <Section title="Patient Management">
          <Stack direction="column" spacing={3}>
            <PatientInformationForm
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <PatientDataForm
              control={control}
              register={register}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              setSampleGridData={setSampleGridData}
              sampleGridData={sampleGridData}
              setHpoGridData={setHpoGridData}
              hpoGridData={hpoGridData}
              getValues={getValues}
              handleSubmit={handleSubmit}
            />
          </Stack>
        </Section>
      </PatientFormElement>
    </PatientProvider>
  );
};

const PatientWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Patient />
  </Suspense>
);

export default PatientWithSuspense;
