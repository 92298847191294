import * as Sentry from '@sentry/react';

const initSentry = () => {
  Sentry.init({
    dsn: 'https://435a843313c5df850c26fb7e6e6cec83@o4505990601637888.ingest.sentry.io/4505990629031936',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 0.6,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

const sentryCaptureException = (error: Error) => {
  Sentry.captureException(error);
};

export { initSentry, sentryCaptureException };
