import {
  type UseFormSetValue,
  type Control,
  type FieldArrayWithId,
  type FieldErrors,
  type UseFormRegister,
} from 'react-hook-form';

import Section from '@pages/common/Project/Forms/Components/Section';
import ExperimentForm from '@pages/common/Project/Forms/ExperimentForm';
import HeaderAction from './HeaderAction';
import { ExperimentTypeSchema, ProjectSchema } from '@/zod_schemas';

interface IExperimentsContainerProps {
  setValue: UseFormSetValue<ProjectSchema>;
  control: Control<ProjectSchema>;
  register: UseFormRegister<ProjectSchema>;
  errors: FieldErrors<ProjectSchema>;
  changeExperimentType: (type: ExperimentTypeSchema, index: number) => void;
  fields: FieldArrayWithId<ProjectSchema, 'experiments'>[];
  removeExperiment: (index: number) => void;
  mode: 'create' | 'update';
  expType?: string;
  project_type?: string;
}

const ExperimentsContainer = ({
  control,
  register,
  setValue,
  errors,
  changeExperimentType,
  fields,
  removeExperiment,
  project_type,
  mode,
}: IExperimentsContainerProps) =>
  fields.map(({ id, experiment_id }, index) => (
    <Section
      key={id}
      title={
        project_type
          ? `${project_type} ${index + 1}`
          : `Experiment ${index + 1}`
      }
      headerAction={
        <HeaderAction
          removeExperiment={() => removeExperiment(index)}
          mode={mode}
          experimentId={experiment_id}
        />
      }
    >
      <ExperimentForm
        index={index}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        changeExperimentType={(type: ExperimentTypeSchema) =>
          changeExperimentType(type, index)
        }
      />
    </Section>
  ));

export default ExperimentsContainer;
